import { SVGIcon } from "../../assets";
import { useChatGPT } from "../../pages/ChatGPT/providers";
import { useActiveTabs } from "../../providers";

export const SideBarHistoryTabs = ({
  isSidebarOpen,
}: {
  isSidebarOpen: Boolean;
}) => {
  const { tabState, setActiveTab, closeTab } = useActiveTabs();
  const {setHomeStatus} = useChatGPT()
  const classic =
    "flex items-center px-2 py-1.5 rounded-md";
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-center";
  const hoverOpt =
    "hover:bg-secondary-light hover:text-text-secondary-light hover:opacity-100";
  const tabsBtnClassName = `${classic} ${sidebarOpt} ${hoverOpt} opacity-75 text-text-primary-light`;
  const tabsActiveBtnClassName = `${classic} ${sidebarOpt} ${hoverOpt} text-text-secondary-light`
  const { gptConfig } = useChatGPT();

  const entries = Object.entries(gptConfig.securedGPTHistory);
  entries.sort(([, a], [, b]) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  const limitedEntries = entries.slice(0, 6);

  return (
    <div className={`${isSidebarOpen && "px-4"}`}>

      {limitedEntries.map(([key, sessionData], i) => {
        const session = gptConfig.securedGPTHistory[key];
        return (
          <div key={i} className={``}>
            <div key={key} className={`flex ${sidebarOpt}`}>
              <button
                className={`${gptConfig.selectedChatId === key ? `${tabsActiveBtnClassName} bg-secondary-light` : tabsBtnClassName}`}
                onClick={() => {gptConfig.setSelectedChatId(key);setHomeStatus(false)}}
              >
                {" "}
                {isSidebarOpen && <span className="text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                  {session.title}
                </span>}

              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
