import { SVGIcon } from "../../assets";

export const CloseSidebarButton = (props: {
  isSidebarOpen: boolean;
  toggleSidebar: (newValue?: boolean | undefined) => void;
}) => {
  const { isSidebarOpen, toggleSidebar } = props;

  return (
    <div
      className={`absolute z-50 -right-2.5 top-7 py-0.5 bg-primary-light rounded-md border`}
    >
      <button
        className="flex"
        onClick={() => {
          toggleSidebar();
        }}
      >
        {isSidebarOpen ? (
          <SVGIcon icon="LEFT_CHEVRON_ICON" size="20px" />
        ) : (
          <SVGIcon icon="RIGHT_CHEVRON_ICON" size="20px" />
        )}
      </button>
    </div>
  );
};
