import { createContext, useContext, useEffect, useState } from "react";
import { useDataQuality } from "../hooks/useDataQuality";
import { useAuth } from "../../../providers";
import { useDataIngestion } from "../hooks/useDataIngestion";
import { FileStatusProps } from "../components/FileStatus";
import { IngestionData } from "../hooks/useDataIngestion";

interface FileData {
    file_type: string;
    is_version: number;
    num_version: number;
}

interface AssetData {
    [key: string]: FileData[];
}

interface FakeDataProps {
    fileType: string;
    status: FileStatusProps['status']
}

interface DataGouvContextType {
    buSelected: string | null;
    setBuSelected: React.Dispatch<React.SetStateAction<string | null>>;
    dateSelected: string | null;
    setDateSelected: React.Dispatch<React.SetStateAction<string | null>>;
    setMonthYearSelected: React.Dispatch<React.SetStateAction<string | null>>;
    dataQuality: any;
    buOptions: string[];
    dateOptions: string[];
    getQualityDetailForBu: (asset: string, use_case: string, year: number) => Promise<any>;
    lateFilesNumber: number;
    ingestionData: IngestionData | null;
    uploadFileFromDropzone: (file_asset: string, file_type: string, file: File,version?: string) => Promise<any>;
    rejectKPIs: (file_asset: string, file_type: string,version?: string) => void;
    setUseCase: React.Dispatch<React.SetStateAction<string | null>>;
    kpis: any
    app: string;
    setApp: React.Dispatch<React.SetStateAction<string>>;
}

const DataGouvContext = createContext<DataGouvContextType | undefined>(undefined);

export const DataGouvProvider = ({children}: React.PropsWithChildren) => {
    const [buSelected, setBuSelected] = useState<string | null>(null);
    const [dateSelected, setDateSelected] = useState<string | null>(null);
    const [monthYearSelected, setMonthYearSelected] = useState<string | null>(null)
    const [buOptions, setBuOptions] = useState<string[]>([]);
    const [dateOptions, setDateOptions] = useState<string[]>([]);
    const [lateFilesNumber, setLateFilesNumber] = useState<number>(0);
    const { token } = useAuth();
    const { dataQuality, getQualityDetailForBu } = useDataQuality(token);
    const [useCase, setUseCase] = useState<string | null>(null)
    const [kpis, setKpis] = useState<any | null>({})
    const [app,setApp] = useState('Quality')

    const { uploadFile, getIngestionData, ingestionData, setIngestionData } = useDataIngestion(token);
    
    useEffect(() => {
        if (buSelected && monthYearSelected && useCase) {
            getIngestionData('FINANCE',buSelected, monthYearSelected!);
        }
    }, [buSelected,monthYearSelected, useCase]);


    const rejectKPIs = (file_asset: string, file_type: string, version?: string) => {
        setIngestionData(prevData => {
            if (!prevData) return null;
    
            return {
                ...prevData,
                [buSelected as string]: prevData[buSelected as string].map(row => {
                    const fileTypeMatch = row.file_type === file_type;
                    const versionMatch = !row.version || row.version === version;
    
                    if (fileTypeMatch && versionMatch) {
                        // Reset all statuses to 'pending'
                        return {
                            ...row,
                            status: {
                                loaded: 'pending',
                                checkfile: 'pending',
                                checkKpis: 'pending',
                                processed: 'pending'
                            },
                            error: undefined
                        };
                    }
                    return row;
                })
            };
        });
    };

    const uploadFileFromDropzone = async (file_asset: string, file_type: string, file: File, version?: string) => {
        if (ingestionData) {
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
                        
                        if (fileTypeMatch && versionMatch) {
                            const statusKeys: (keyof FileStatusProps['status'])[] = ['loaded', 'checkfile', 'checkKpis', 'processed'];
                            const updatedStatus = { ...row.status };
                            statusKeys.forEach(key => {
                                if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished') {
                                    updatedStatus[key] = 'pending';
                                }
                            });
                            return {
                                ...row,
                                status: { ...updatedStatus, loaded: 'running' },
                                error: undefined,
                            };
                        }
                        return row;
                    })
                };
            });
        }
    
        try {

            const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];
            const data = await uploadFile(file_asset, file_type, file, version, month, year);
            setKpis(data.kpis)

    
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
    
                        if (fileTypeMatch && versionMatch) {
                            return {
                                ...row,
                                status: {
                                    loaded: data.status.loaded,
                                    checkfile: data.status.checkfile,
                                    checkKpis: data.status.checkKpis,
                                    processed: data.status.processed,
                                },
                                error: data.error ? data.error : undefined
                            };
                        }
                        return row;
                    })
                };
            });
        } catch (error) {
            console.error("File upload failed:", error);
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
    
                        if (fileTypeMatch && versionMatch) {
                            return { 
                                ...row, 
                                status: { 
                                    ...row.status, 
                                    loaded: 'error' 
                                } 
                            };
                        }
                        return row;
                    })
                };
            });
        }
    };    

    useEffect(() => {
        if (dataQuality?.fields?.assets) {
            const dates = Object.keys(dataQuality.fields.assets);
            setDateOptions(dates);

            if (dateSelected && dataQuality.fields.assets[dateSelected]) {
                setBuOptions(dataQuality.fields.assets[dateSelected]);
            }
        }
    }, [dataQuality, dateSelected]);

    // useEffect(() => {
    //     if (dataQuality?.data) {
    //         let totalLateFiles = 0;

    //         Object.keys(dataQuality.data).forEach(year => {
    //             const assets = dataQuality.data[year];
    //             Object.keys(assets).forEach(asset => {
    //                 const assetData = assets[asset];
    //                 Object.keys(assetData).forEach(useCase => {
    //                     const useCaseData = assetData[useCase];
    //                     const lateFiles = useCaseData?.late ?? 0;

    //                     totalLateFiles += lateFiles;
    //                 });
    //             });
    //         });
    //         setLateFilesNumber(totalLateFiles);
    //     }
    // }, [dataQuality]);

    useEffect(() => {
        if (dateOptions.length > 0 && !dateSelected) {
            setDateSelected(dateOptions[0]);
        }
    }, [dateOptions]);

    useEffect(() => {
        if (buOptions.length > 0 && !buSelected) {
            setBuSelected(buOptions[0]);
        }
    }, [buOptions]);

    return (
        <DataGouvContext.Provider value={{
            buSelected,
            setBuSelected,
            dateSelected,
            setDateSelected,
            dataQuality,
            buOptions,
            dateOptions,
            getQualityDetailForBu,
            lateFilesNumber,
            ingestionData,
            uploadFileFromDropzone,
            setUseCase,
            setMonthYearSelected,
            kpis,
            rejectKPIs,
            app,setApp
        }}>
            {children}
        </DataGouvContext.Provider>
    );
};

export const useDataGouv = () => {
    const context = useContext(DataGouvContext);
    if (!context) {
        throw new Error('useDataGouv must be used within a DataGouvProvider');
    }
    return context;
};