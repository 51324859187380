import { useEffect, useState, useRef } from "react";
import { languageOptions } from "../../pages/Newsletter/Newsletter";

type ChatApp = 'SDH' | 'GPT'

type ChatAppData = {
    id: ChatApp;
    title: string;
    bgColor: string;
}

const chatOpt: ChatAppData[] = [
    {
        id: 'SDH',
        title: "Secured SDH",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    }
]

interface SelectedChatProps {
    app: ChatApp
    setApp: (value: "SDH") => void;
    language: string;
    handleChangeLanguage: (e: React.ChangeEvent<HTMLSelectElement>) => void;

}

export const SelectedChat: React.FC<SelectedChatProps> = ({ app, setApp, language, handleChangeLanguage }) => {

    return (
        <div className="flex flex-row w-fit justify-center items-center">
            <div className="flex w-full justify-center my-4 relative" onClick={() => { setApp("SDH")  }}>
                <div className="flex items-center border rounded-2xl w-fit cursor-pointer">
                    {
                        chatOpt.map((e, i) => {
                            const isCurrentApp = e.id === app;
                            const bgColorClass = isCurrentApp ? `${e.bgColor} text-white` : 'text-[#2E2E2E] opacity-50';
                            const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                            return (
                                <div key={i} className={`text-center px-4 py-1 ${bgColorClass} ${transitionClass} rounded-xl`}>
                                    {/* <h4 className="font-medium text-sm">Chat</h4> */}
                                    <h3 className="font-medium">{e.title}</h3>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <select className="ml-2 p-1.5 text-[#2E2E2E] opacity-70 bg-transparent rounded-2xl shadow border border-gray-200 focus:outline-none cursor-pointer" value={language} onChange={handleChangeLanguage}>
                {languageOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

    );
};


type ToolOption = {
    id: string;
    name: string;
}

interface ToolSelectProps {
    availableTools: ToolOption[];
    plugin?: string
    setActivePlugin: React.Dispatch<React.SetStateAction<string>>
    loadingTMM: boolean;
    loadingTMMConfig: boolean;
}


export const SelectedChatOptionTMM: React.FC<ToolSelectProps> = ({ availableTools, plugin, setActivePlugin, loadingTMM, loadingTMMConfig }) => {
    const [selectedOptionId, setSelectedOptionId] = useState<string>(plugin! || 'get_news')

    useEffect(() => {
        if (plugin) setSelectedOptionId(plugin!)
    }, [plugin])

    useEffect(() => {
        setActivePlugin(selectedOptionId)
    }, [selectedOptionId])

    return (
        <div className="flex w-full justify-center relative">
            <div className="flex items-center border p-1.5 rounded-2xl w-fit cursor-pointer">
                {
                    availableTools.map((e, i) => {
                        const isCurrentApp = e.id === selectedOptionId;
                        const bgColorClass = isCurrentApp ? `bg-gradient-to-br from-[#3399B1] to-[#116B81] text-white` : 'text-[#2E2E2E] opacity-50';
                        const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                        return (
                            <div key={i}
                                onClick={() => {
                                    if (loadingTMM || loadingTMMConfig) return;
                                    setSelectedOptionId(e.id);
                                }}
                                className={`text-center px-4 py-1 ${bgColorClass} ${transitionClass} rounded-xl`}>
                                <h4 className="font-medium text-sm">{e.name}</h4>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

interface ModelSelectProps {
    availableTools: string[];
    setActivePlugin: React.Dispatch<React.SetStateAction<string>>
    loadingGPT: boolean
}

export const SelectedModelOptionGPT: React.FC<ModelSelectProps> = ({ availableTools, setActivePlugin, loadingGPT }) => {
    const [selectedOptionId, setSelectedOptionId] = useState<string>('gpt')

    useEffect(() => {
        setActivePlugin(selectedOptionId)
    }, [selectedOptionId])

    return (
        <div className="flex w-full justify-center relative">
            <div className="flex items-center border p-1.5 rounded-2xl w-fit cursor-pointer">
                {
                    availableTools.map((e, i) => {
                        const isCurrentApp = e === selectedOptionId;
                        const bgColorClass = isCurrentApp ? `bg-gradient-to-br from-[#3399B1] to-[#116B81] text-white` : 'text-[#2E2E2E] opacity-50';
                        const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                        return (
                            <div key={i}
                                onClick={() => {
                                    if (loadingGPT) return;
                                    setSelectedOptionId(e);
                                }}
                                className={`text-center px-4 py-1 ${bgColorClass} ${transitionClass} rounded-xl`}>
                                <h4 className="font-medium text-sm">{e === "gpt" ? "GPT 3.5" : (e === "gpt4" ? "GPT 4" : e.toUpperCase())}</h4>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};