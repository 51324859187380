type RadioButtonsProps = {
  values: string[];
  selectedValue: string;
  setSelectedValue: (value: string) => void;
};

export const CFRRadioButtons: React.FC<RadioButtonsProps> = ({ values, selectedValue, setSelectedValue }) => {

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <div className="flex flex-row justify-between">
      {values.map((value) => (
        <label key={value} className="flex items-center space-x-2">
          <input
            type="radio"
            value={value}
            checked={selectedValue === value}
            onChange={() => handleRadioChange(value)}
            className="form-radio h-4 w-4"
            style={{ accentColor: '#0D3D62' }}
          />
          <span>{value}</span>
        </label>
      ))}
    </div>
  );
};
