import { SVGIcon } from "../../assets";
import { Tabs } from "../../assets";
import { useActiveTabs } from "../../providers";

export type ButtonRedirectSDHProps = {
    id: number;
};

export const ButtonRedirectSDH:React.FC<ButtonRedirectSDHProps> = ({id}) =>{
    const {openTab, tabState, returnFav} = useActiveTabs()
    const tab : Tabs[] = returnFav([id])
    return(
        <div className="cursor-pointer flex flex-row gap-3 text-sm text-[#0D3D62] p-2 border border-[#CFCFCF80] rounded-lg justify-center items-center hover:shadow-xl"
            onClick={()=> openTab(tab[0])}
        >
            {tab[0].icon}
            <p>{tab[0].title}</p>
            <SVGIcon size="20px" icon="ARROW_SMALL_ICON"/>
        </div>
    )
}

