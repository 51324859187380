import React, { useState, useRef, useEffect} from "react";
import { useSecureGPTConfig, useSessionId} from "../../hooks";
import { useAuth } from "../../providers";

interface SpecifyChatDropdownProps {
    isOpen: boolean;
    toggleOpen: () => void;
    context: string;
    setContext: (context: string) => Promise<null | undefined>; 

}
export const SpecifyChatDropdown: React.FC<SpecifyChatDropdownProps> = ({isOpen,toggleOpen,context,setContext}) =>{
    // const { token } = useAuth()
    // const sessionId = useSessionId(`/api/securegpt/clear_session`);
    // const {setContext,contextGPTLoader} = useSecureGPTConfig(token, sessionId)
    const [contextGPTLoader, setContextGPTLoader] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState('You are a VINCI assistant who helps members of the company with their professional tasks.');
    const textareaRef = useRef(null);

    useEffect(()=>{
        setInputValue(context)
    },[context])

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async() => {
        setContextGPTLoader(true)
        await setContext(inputValue)
        setContextGPTLoader(false)
        toggleOpen()
    };

    const toggleMainDropdown = () => {
        toggleOpen();
    }


    return(
        <div className="w-full max-w-xl whitespace-nowrap rounded-xl px-4 text-left text-token-text-primary md:whitespace-normal">
            <button onClick={toggleMainDropdown} className="hover:text-emerald-500 text-gray-700 flex justify-between items-center w-full px-4 py-2 text-left bg-white rounded-lg shadow border border-gray-300 focus:outline-none">
                <span className="w-full">Specify Chat GPT context &nbsp;</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                </svg>
            </button>
            <div  className={` w-90 bg-white relative transition-all ease-in-out duration-500 overflow-hidden ${isOpen ? 'border border-gray-300 rounded-lg' : ''} ${isOpen ? 'max-h-96' : 'max-h-0'}`}>
                <div className="w-full h-13">
                    <p className="mx-4 my-2 p-2 pt-3 pb-3 text-black">
                        The 'system' context of an LLM (Large Language Model) refers to the initial instructions that guide the model during text generation. Modifying this context can be advantageous to influence the tone, style, and content of the generated text, enabling customization of responses or adaptation of the model for specific tasks.
                    </p>
                    <div className="w-full p-4 h-13">
                        <textarea
                                className=" mb-2 p-2 rounded-md shadow-lg bg-gray-200 w-full focus:outline-none"
                                value={inputValue}
                                onChange={handleInputChange}
                                style={{ height: '100px', minHeight: '100px', overflowY: 'auto'}}
                            />
                    </div>
                    <button onClick={handleSubmit} className="mx-4 mb-4 px-4 py-2 bg-white text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{contextGPTLoader ? "Setting up context..." : "Submit"}</button>   
                </div>
            </div>    
        </div>
    )
}