import React from "react";
import { useState, useEffect, useRef } from "react";
import {TypeToIcon } from "../../components";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { SVGIcon } from "../../assets";
import "./LargeDoc.css"
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";


interface AskMultiDocProps {
    text: string;
}

interface AnswerMultiDoc {
    text: string;
}


export const AskMultiDoc: React.FC<AskMultiDocProps> = ({text}) =>{
    const icon = TypeToIcon['IA_MULTIDOCS']
    return(
        <div className="flex items-center mb-4 pt-1 justify-start">
            <ChatLogo icon={icon} />
            <p className="flex text-sm pl-3 text-[#4A4A4A]">{text.length > 0 ? text : "Here is the title and summary I built for your documents :"}</p>
        </div> 
    )

}

export const AnswerMultiDoc: React.FC<AnswerMultiDoc> = ({text}) =>{
    const [isCopied, setIsCopied] = useState<boolean>(false);
    
    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(text);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          setIsCopied(false);
        }
    };
    return(
        <div className="relative mb-3 flex flex-col p-4 rounded-xl bg-white">
            <div className="markdown text-sm"> 
               {text.length > 0 ? <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown> : "Use the check box in the top right corner to generate a summary."} 
            </div>
            <div className="text-sm absolute flex flex-row gap-1 items-center bottom-0 right-0 p-3.5">
            {isCopied ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                        <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                    </svg>
                ) : (
                    <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                    </svg>
            )}
            </div> 
        </div>
    )
}

interface AskQuestionIProps {
    fileName?: string;
    token: string;
    session_id: string
    messages : AskConversationProps[]
    setMessages : React.Dispatch<React.SetStateAction<AskConversationProps[]>>
}

export interface AskConversationProps {
    type: "Ask" | "Answer";
    text: string
}

export const AskQuestion: React.FC<AskQuestionIProps> = ({fileName,token,session_id, messages, setMessages }) => {
    const [question, setQuestion] = useState('')
    const [resToQuestion, setResToQuestion] = useState(null)
    const [loaderAsk, setLoaderAsk] = useState(false)
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          handleSubmit()
        }
    };

    
    const handleSubmit = async() =>{
        if(loaderAsk) return;
        setLoaderAsk(true)
        setMessages(prevMessages => [...prevMessages,{
            type:'Ask',
            text : question
        }])
        setQuestion('')
        try{
            const response = await fetch(
                `/api/multi_doc/ask_question`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: session_id,
                        question,
                        ...(fileName && { specific_doc: fileName }),
                    })
                }
            );

            const data = await response.json()
            setResToQuestion(data.answer.answer)            
            setLoaderAsk(false)
            setMessages(prevMessages => [...prevMessages,{
                type:'Answer',
                text : data.answer.answer
            }])

        }catch(error){
            console.log(error)
            setLoaderAsk(false)
            setQuestion('')
        }
        return;
    }
    return (
        <div className="flex flex-col w-full mt-5">
            <div className='w-90 bg-white relative overflow-hidden rounded-xl shadow-lg'>
                <div className="flex flex-col justify-center">
                <div className="relative text-white p-2 items-center">
                    <textarea
                        ref={textAreaRef}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="form-control  placeholder-[#B6B6B6] h-10 block w-full p-2.5 pr-24 text-sm text-black bg-white bg-clip-padding rounded-lg transition ease-in-out m-0 focus:outline-none overflow-hidden resize-none"
                        placeholder="Ask something about the document.."
                        rows={3}
                        style={{ maxHeight: "5rem", overflowY: "auto" }}
                    />
                    <span className="absolute top-5 right-2 text-sm text-gray-500">
                        <button type="submit" disabled={(question.length === 0)} onClick={handleSubmit} className="send-button flex items-center">
                            <SVGIcon size="20px" icon="PAPER_PLANE_ICON" />
                        </button> 
                    </span> 
                </div>        
                </div>
            </div>
        </div>
    )
}
