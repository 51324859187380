import { useActiveTabs } from "../../../providers";
import { useChatGPT } from "../providers";
import { BookIcon, ParamsIcon } from "./Icon";

export const ChatGPTModalDocument = ({ }: {}) => {
    const {
        setModalStatus
    } = useChatGPT();

    const {returnFav} = useActiveTabs()

    const fav = returnFav([3, 7])

    const appModalDocument = [
        { title: "Translator", description: "Translate pdf/pptx/docx documents", icon: <ParamsIcon />, action: () => { setModalStatus("TRANSLATOR") }, id: 3 },
        { title: "Multi Docs", description: "Load documents and start asking questions about them", icon: <BookIcon />, id: 7 },
    ]

    return (
        <div className="">
            {
                
                fav.map((e, i) => {
                    const app = appModalDocument.find(item => item.id === e.id);
                    if (!app)
                        return <></>

                    return (
                        <div className="flex items-center w-full text-sm cursor-pointer focus-visible:outline-0 hover:bg-gray-50 focus-visible:bg-[#f5f5f5] rounded-md gap-2.5 py-3 pr-3" onClick={app.action} >
                            <div className="flex grow items-center justify-between gap-2">
                                <div>
                                    <div className="flex items-center gap-3">
                                        <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center text-[#7d7d7d]">
                                            {app.icon}
                                        </span>
                                        <div className="text-sm">
                                            {app.title}
                                            <div className="text-[#7d7d7d] text-xs">{app.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }

        </div>
    )
}