import { useState, useEffect } from "react";
import { ChangeEvent } from "react";
import { useAuth } from "../../../../providers";
import { DropzoneExcel } from "../../../SentimentAnalysis/DropzoneExcel";

export const ProcessFile = () => {
    const { token } = useAuth()
    const [excelUploadedSucces, setExcelUploadedSucces] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [excelData, setExcelData] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<string>('0')
    const [totaColumns, setTotalColumns] = useState<string>('')
    const [maxTotalRows, setMaxTotalRows] = useState<number>(0)
    const [questionTemplate, setQuestionTemplate] = useState<string>("Analyze the sentiment of the statement {cell_content} within the specific context of the {column_name} theme, related to our business of selling products online'")
    const [context, setContext] = useState<string>("You are an expert in sentiment analysis scoring.\n- Assign a sentiment score from -1 (negative) to 1 (positive), ensuring that the score reflects the statement's direct relevance and impact on the theme.\n- If the statement is positive or negative but not related to the theme, assign a 0 score to indicate neutrality.\n- Focus on the thematic connection to ensure the score accurately represents sentiment concerning the specific theme, among all of these themes: {list_of_themes}.\n- ONLY answer with the score and nothing else.")
    const [loaderSubmit, setLoaderSubmit] = useState<boolean>(false)
    const [tooMuchRowsError, setTooMuchRowsError] = useState<boolean>(false)
    const [totalCells, setTotalCells] = useState<number>(0)

    useEffect(() => {
        if (excelData.length > 0) {
            let totalCol = excelData[0].length - 1
            setTotalColumns(totalCol.toString())
        }
    }, [excelData])

    useEffect(() => {
        setTotalCells(parseInt(totalRows) * parseInt(totaColumns))
    }, [totalRows, totaColumns])

    useEffect(() => {
        if (totalCells > 300) setTooMuchRowsError(true)
        else setTooMuchRowsError(false)
    }, [totalCells])

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const generateSentiment = async () => {
        if (parseInt(totalRows) > maxTotalRows) return
        setLoaderSubmit(true)
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("question_template", questionTemplate);
            formData.append("context", context);
            formData.append("limit", totalRows);

            try {
                const response = await fetch(`/api/sentiment/document`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Erreur de telechargement.");
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `output.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);

                //onSuccess();
            } catch (error) {
                //onError(error);
                console.log(error);
            }
        }
        setLoaderSubmit(false)
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2 pt-2">
                {
                    !excelUploadedSucces &&
                    <div className="flex flex-col">
                        <p>Please Upload an Excel File</p>
                    </div>
                }
                <div className="flex flex-col">
                    <DropzoneExcel onExcelParsed={setExcelData} setFile={setFile} setFileName={setFileName} fileName={fileName} setExcelUploadedSucces={setExcelUploadedSucces} setTotalRows={setTotalRows} setMaxTotalRows={setMaxTotalRows} />
                </div>
            </div>
            {excelUploadedSucces &&
                <div className="flex flex-col gap-2">
                    <div className={`w-90 bg-transparent relative transition-all ease-in-out duration-500 max-h-150`}>
                        <div className="w-full flex flex-col gap-4">
                            <div className="flex flex-col">
                                <label className="text-md p-1">Edit the question template:</label>
                                <textarea value={questionTemplate} onChange={(event) => setQuestionTemplate(event.target.value)} className="w-full h-20 p-2 bg-gray-50 border-gray-50 rounded-lg focus:outline-none" onInput={handleInput} />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-md p-1">Edit the general instruction for the AI:</label>
                                <textarea value={context} onChange={(event) => setContext(event.target.value)} className="w-full h-48 p-2 bg-gray-50 border-gray-50 rounded-lg focus:outline-none" onInput={handleInput} />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-md p-1">Choosen Excel File: {fileName}</label>
                                <div className="overflow-auto max-h-96 border border-secondary-light p-2 rounded">
                                    <table className="min-w-full">
                                        <tbody className="border">
                                            {excelData.map((row, rowIndex) => (
                                                <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                                    {Object.values(row).map((value, valueIndex) => (
                                                        <td key={valueIndex} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-b">
                                                            {valueIndex === 0 && rowIndex !== 0 ? String(value).substring(0, 50) + "..." : String(value)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-col w-2/5">
                                <label className="text-md p-1">Number of rows:</label>
                                <input className="w-full p-2 bg-gray-50 border-gray-50 rounded-lg focus:outline-none resize-none"
                                    onChange={(e) => setTotalRows(e.target.value)}
                                    value={totalRows}
                                    max={maxTotalRows}
                                    min={1}
                                    type="number"
                                />
                            </div>
                            <label className="text-md p-1">Number of columns: {totaColumns}</label>
                            <label className="text-md p-1">Number of requests (max: 300) : {totalCells ? totalCells : 0} </label>
                            <div className="flex flex-col">
                                <button onClick={generateSentiment} disabled={loaderSubmit || tooMuchRowsError || totalRows.length === 0} className="cursor-pointer w-full py-2 bg-transparent text-black rounded-lg border border-secondary-light hover:bg-secondary-light">{loaderSubmit ? 'Generating file...' : (tooMuchRowsError ? `Too many requests` : 'Submit')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}