import { useCFRConfig } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import { CFRSlider } from "./CFRSlider";
import { CFRSelect } from "./CFRSelect";
import { CFRRadioButtons } from "./CFRRadioButtons";
import { SetupPanelProps } from "../../types";

function datesToObject(datesArray: any[]) {
    // Créer un tableau des noms des mois
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Créer un objet pour stocker les résultats
    const formattedDates: {
        [key: string]: string;
    } = {};

    // Remplir l'objet avec les mois formatés comme clés et les dates originales comme valeurs
    datesArray.forEach(date => {
        const dateObj = new Date(date);
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        const formattedMonth = `${month} ${year}`;
        formattedDates[formattedMonth] = date;
    });

    return formattedDates;
}



export const SetupPanel = ({ news, setupData, onSetupPanelChange, fetchDataForSubmit, fetchDataForUpdate, fetchDataForLoadParagraph, fetchCorrectParagraph, fetchDownload, config, setLoader, setConfig, setNews }: SetupPanelProps) => {
    const [airport, setAirport] = useState<string | null>(null);
    const [section, setSection] = useState<string | null>("general");
    const [firstLevel, setFirstLevel] = useState<string | null>("level0");
    const [secondLevel, setSecondLevel] = useState<string | null>("level1");
    const [budget, setBudget] = useState<string>('B0');
    const [reportType, setReportType] = useState<string>("Year to Date")
    const [language, setLanguage] = useState<string | null>("EN");
    const [dates, setDates] = useState<string | null>(null)
    const [displayLevels, setDisplayLevels] = useState(false)

    const updateSetupData = () => {
        const newStateValues = {
            ...setupData,
            airport,
            firstLevel,
            secondLevel,
            budget,
            language,
            dates,
            reportType
        };
        if (JSON.stringify(newStateValues) !== JSON.stringify(setupData)) {
            onSetupPanelChange(newStateValues);
        }
        return newStateValues
    }

    const startNewsletter = async () => {
        if (setupData && airport && dates) {
            const newStateValues = updateSetupData()
            if (!displayLevels) {
                setDisplayLevels(true)
            }
            await fetchDataForSubmit(newStateValues)
        }
    }

    useEffect(() => {
            const newStateValues = updateSetupData()
            if (displayLevels && news) {
                fetchDataForUpdate(newStateValues)
            }
    }, [firstLevel, secondLevel, budget, language]);

    const [input, setInput] = useState("");

    useEffect(() => {
        setDisplayLevels(false)
        setNews(null)
    }, [airport, dates, reportType])

    return (
        <div className="lg:w-3/12 w-4/12 border-l p-4 shadow-lg flex h-full flex-col">
            <h1 className=" text-text-primary-light font-medium text-center underline md:text-1xl text-lg">Finance Report</h1>
            {config && (
                <>
                    <CFRSelect entries={Object.keys(config.id)} defaultValue={"Airport"} firstIndex={1} secondIndex={1} value={airport} setValue={setAirport} />
                    {
                        airport &&
                        <>
                            <CFRSelect entries={datesToObject(config.id[airport])} defaultValue={"Month"} firstIndex={1} secondIndex={0} value={dates} setValue={setDates} />
                            <CFRRadioButtons key="1" values={["Year to Date", "Monthly"]} selectedValue={reportType} setSelectedValue={setReportType} />
                        </>
                    }
                    <button onClick={startNewsletter} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                        Generate Title
                    </button>
                    {(displayLevels && news) &&
                        <>
                            <hr className="h-px mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
                            <CFRSelect entries={config.first_level} defaultValue={"First Level"} firstIndex={1} secondIndex={1} value={firstLevel} setValue={setFirstLevel} />
                            <CFRSelect entries={(firstLevel && firstLevel in config.second_level) ? config.second_level[firstLevel] : []} defaultValue={"Second Level"} firstIndex={1} secondIndex={1} value={secondLevel} setValue={setSecondLevel} />
                            <h4 className="py-4">Budget comparison</h4>
                            <CFRRadioButtons key="2" values={news.budget} selectedValue={budget} setSelectedValue={setBudget} />
                            <CFRSelect entries={config.languages} defaultValue={"Languages"} firstIndex={0} secondIndex={1} value={language} setValue={setLanguage} />
                            <button onClick={() => { setupData && fetchDataForLoadParagraph(setupData); setLoader(true) }} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                                Generate Newsletter {news?.credit && `- Cost: ~${Math.round(news.credit)} credit`}
                            </button>
                            {
                                news &&
                                <>
                                    <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                                    <CFRSelect entries={news.ids} defaultValue={"Sections"} firstIndex={1} secondIndex={1} value={section} setValue={setSection} />
                                    <textarea
                                        value={input}
                                        onChange={(e) => setInput(e.target.value)}

                                        className="form-control min-h-20 block w-full pl-3 pr-24 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none overflow-hidden resize-none"
                                        placeholder="Correct..."
                                        rows={3}
                                        style={{ maxHeight: "5rem", overflowY: "auto" }}
                                    ></textarea>
                                    <button onClick={() => section && fetchCorrectParagraph(input, section)} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                                        Correct
                                    </button>

                                    <button onClick={fetchDownload} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                                        Download
                                    </button>

                                </>

                            }

                        </>
                    }

                </>
            )}
        </div>
    )
}