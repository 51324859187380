import React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import { Ask, HTMLAnswer, TextAnswer } from '../components';
import { TypeToIcon } from '../components';
import { useCredit } from '../providers';
import { templateTools } from '../utils/sdhDataTemplate';

interface Tool {
    name: string;
    id: string;
}

export type messageTMM = {
    type: string;
    props: {
        type: keyof typeof TypeToIcon;
        text: string;
        typed: boolean;
        graph?: string | null;
        table?: string | null;
    };
}

export const useTMMConfig = (token: string | null, sessionId: string) => {
    const [config, setConfig] = useState<any>({
        "suggested_prompts": [],
        "available_tools": {},
        "bu_traffic_report": {}
    });
    const [answer, setAnswer] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [messages, setMessages] = useState<messageTMM[]>([]);
    const [loadingTMM, setLoadingTMM] = useState<boolean>(false)
    const [loadingTMMConfig, setLoadingTMMConfig] = useState<boolean>(false)
    const [tool, setTool] = useState<string>("get_news")
    const [availableTools, setAvailableTools] = useState<Tool[]>(templateTools);
    const [buTrafficTeport, setBuTrafficTeport] = useState<{}>({});
    const [errorRequestTMM, setErrorRequestTMM] = useState<boolean>(false)
    const { remainingCredit, setRemainingCredit } = useCredit()
    const [languageTMM, setLanguageTMM] = useState('EN')

    const fetchConfig = async (url: string) => {
        setLoadingTMMConfig(true)
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                setLoadingTMMConfig(false)
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            if (config && config.suggested_prompts && config.suggested_prompts.length != 0) {
                const tmp = { ...config, "suggested_prompts": data }
                console.log(JSON.stringify(config),  JSON.stringify(tmp))
                setConfig(tmp)
                if (data.available_tools) {
                    const toolsArray: Tool[] = Object.entries(data.available_tools).map(([name, id]) => ({
                        name,
                        id: id as string
                    }));
                    setAvailableTools(toolsArray);
                }
            } else {
                setConfig(data);
                if (data.available_tools) {
                    const toolsArray: Tool[] = Object.entries(data.available_tools).map(([name, id]) => ({
                        name,
                        id: id as string
                    }));
                    setAvailableTools(toolsArray);
                }
            }
            setLoadingTMMConfig(false)
        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };

    const ask = async (text: string) => {
        setLoadingTMM(true)
        setMessages(prevMessages => [...prevMessages,
        {
            type: 'Ask',
            props: {
                type: 'IA',
                text: text,
                typed: false,
            }
        }
        ]);
        const response = await fetch(`/api/tell_me_more/ask`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id: sessionId,
                message: text,
                tool: tool
            }),
        });
        if (response.ok) {
            const responseData = await response.json();
            if (!responseData) {
                setErrorRequestTMM(true)
                setMessages(prevMessages => [...prevMessages, {
                    type: 'TMMError',
                    props: {
                        type: 'IA',
                        text: 'An Error has occured',
                        typed: false,
                    }
                }])
                setLoadingTMM(false)
                return;
            }
            let newMessage: messageTMM | null = null;
            if (remainingCredit && responseData.credit) {
                const newCredit = remainingCredit - responseData.credit;
                setRemainingCredit(newCredit);
            }

            if (responseData.func == "get_traffic_report" || responseData.func == "get_finance_report") {
                newMessage = {
                    type: 'HTMLAnswer',
                    props: {
                        type: 'IA',
                        text: responseData.content,
                        typed: false,
                    }
                };
            } else {
                newMessage = {
                    type: 'TextAnswer',
                    props: {
                        type: 'IA',
                        text: responseData.content,
                        typed: false,
                        graph: responseData.graph || null,
                        table: responseData.table || null
                    }
                };
            }
            setMessages(prevMessages => [...prevMessages, newMessage!]);
        }
        else {
            setErrorRequestTMM(true)
            setMessages(prevMessages => [...prevMessages, {
                type: 'TMMError',
                props: {
                    type: 'IA',
                    text: 'An Error has occured',
                    typed: false,
                }
            }])
        }
        setLoadingTMM(false)
    };

    useEffect(() => {
        if (token === null || sessionId === "") {
            setError(null);
            return;
        }

        fetchConfig(`/api/tell_me_more/config/${sessionId}`);
    }, [token, sessionId]);

    const reloadPrompt = async () => {
        if (sessionId)
            await fetchConfig(`/api/tell_me_more/prompts/${sessionId}/${tool}`);
    }

    useEffect(() => {
        reloadPrompt()
    }, [tool])

    return { config, reloadPrompt, messages, setMessages, ask, error, loadingTMM, setTool, tool, availableTools, loadingTMMConfig, buTrafficTeport };
};

