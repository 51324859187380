import React, { useState, useEffect } from "react";
import { useAuth, useCredit } from "../../providers";
import { LoaderComponent } from "../../components";
import "./newsletter.css"
import { useNewsletterConfig } from "../../hooks/newsletter";
import { useSessionId } from "../../hooks";
import { DropdownNewsletter } from "./Dropdown";

export const languageOptions = [
    { label: "English", value: "EN" },
    { label: "Spanish", value: "ES" },
    { label: "French", value: "FR" },
    { label: "Portuguese", value: "PT" },
    { label: "Japanese", value: "JA" }
];

export const detailOption = [
    {label: "Brief", value: 'Brief'},
    {label: "Detailed", value: 'Detailed'}
]

export const writingStyleOption = [
    {label: 'Written', value: "Written"},
    {label: "Bullet Points", value: "Bullet Points"}
]

export const themeIndustryOption = [
  {label: "Traffic", value: 'Traffic'},
  {label: "Airlines", value: 'Airlines'},
  {label: "Hydrogen", value: 'Hydrogen'},
  {label: "Finance", value: 'Finance'},
]


export const Newsletter = () => {
    const {token, tokenInfo } = useAuth()
    const {checkUserInfo, subscribe, deleteSubscription,userInfo,loadingInfo, subscribeLoader, userDeleteLoader, userDeletedSuccess,setUserDeletedSuccess,userSubscribeSuccess } = useNewsletterConfig(token)
    const email = tokenInfo?.email || ''
    const [emailError, setEmailError] = useState<boolean>(false)
    const [emailVerified,setEmailVerified] = useState<boolean>(false)
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);

    const toggleDropdown = (dropdownType: string) => {
      if (openDropdown === dropdownType) {
        setOpenDropdown(null);
      } else {
        setOpenDropdown(dropdownType); 
      }
    };

    useEffect(()=>{
      if(userDeletedSuccess) setEmailVerified(false)
      setUserDeletedSuccess(false)
    },[userDeletedSuccess])

    // useEffect(()=>{
    //   if(userSubscribeSuccess) checkUserInfo(email)
    // },[userSubscribeSuccess])


   useEffect(()=>{
    if(userInfo && Object.entries(userInfo).length > 0)  setEmailVerified(true)
   },[userInfo])


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (e.key === 'Enter') {
            if (email.length > 0 && emailRegex.test(email)) {
                checkUserInfo(email)
            } else {
                setEmailError(true);
            }
        }
    };
    
    useEffect(()=>{
        if(emailError){
            setTimeout(()=>{
                setEmailError(false)
            },2000)
        }
    },[emailError])

    return(
        <div className="flex flex-col h-screen items-center">
             <div className="flex flex-col text-md text-[#0D3D62] m-0 p-7">
          <div className="w-full flex" >
            <div className="flex flex-col w-full gap-4 rounded-xl bg-white p-5">
              <div className="flex flex-row gap-4">
                <p className="text-xl text-[#0D3D62] font-trendaSemiBold">Welcome <span className="font-sans">!</span>{" "}</p>
              </div>
              <div className="bg-[#F6F6F6] p-4 w-full rounded-lg justify-center items-center">
                <p className="text-sm text-[#0D3D62]">
                  Sign up now to receive our regular updates about VINCI Airports and Market trend directly to your inbox.
                </p>
                <br/>
                <p className="text-sm text-[#0D3D62] mb-1">
                  The newsletters cover :
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Daily Media Monitoring :</span> Last update in the press about VINCI Airports and industry market.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Hydrogen :</span> Explore the latest innovations and developments in this sustainable energy source.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Airlines :</span> Provide updates and insights on airline industry trends and advancements.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Traffic :</span> Investigate and report on traffic information, about market industry.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Finance :</span> Delve into market trends, fiscal policies, and financial planning strategies.
                </p>
                <br />
                <p className="text-sm text-[#0D3D62] mb-1">
                  The parameters below allow you to choose the language, frequency, detailed level and writing style.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  Thank you for your continued support and understanding. Feel free to reach out if you have any questions or feedback at <span className="font-bold">smartdatahub@vinci-concessions.com</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
            <div className="flex flex-col gap-4 w-[60%] border border-gray-300 p-4 rounded-lg shadow-lg max-w-3xl">
                <div>
                    <label className="text-md p-1">{email.length === 0 || emailVerified ? "Email Adress" : "Press Enter to apply"}</label>
                    <input style={{animation: emailError ? 'shake 0.6s cubic-bezier(.36,.07,.19,.97) both' : 'none'}} className={`w-full  p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring ${emailError ? "focus:ring-red-500" : "focus:ring-emerald-500"}`} value={email} onKeyDown={handleKeyDown}></input>
                    {loadingInfo && <p className="p-1">Loading info..</p>}
                </div>
                {userInfo && Object.entries(userInfo).map(([key, value]) => {
                  const type = key as 'access' | 'industry';
                  const userInfoObj: any = {
                    userName: value.userName,
                    userEmail: value.userEmail,
                    language: value.language,
                    newsletterFrequency: value.newsletterFrequency,
                    format: value.format,
                    writtingStyle: value.writtingStyle,
                  };
                  if(type === 'industry'){
                    userInfoObj.themes = value.themes
                  }

                  const isDropdownOpen = openDropdown === key
            
                  return (
                    <DropdownNewsletter
                      key={key}
                      userInfo={userInfoObj}
                      email={email}
                      subscribe={subscribe}
                      deleteSubscription={deleteSubscription}
                      type={type} // 'access' or 'industry'
                      isOpen={isDropdownOpen}
                      onToggle={() => toggleDropdown(key)}
                      subscribeLoader={subscribeLoader}
                      deleteLoader={userDeleteLoader}
                      emailVerified={emailVerified}
                    />
                  );
                })}   
            </div>
        </div>    
    )
}