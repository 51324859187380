import React, { useEffect, useState } from 'react';
import { useAuth } from '../../providers';

export const StreamlitApp: React.FC = () => {
  const [streamlitUrl, setStreamlitUrl] = useState<string | null>(null);
  const [inputUrl, setInputUrl] = useState<string>('');
  const { token } = useAuth()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputUrl(event.target.value);
  };

  const handleButtonClick = async () => {
    try {
      const pingResponse = await fetch(`/api/streamlit/ping/single`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: inputUrl + "/",
        }),
      });

      if (pingResponse.ok) {
        const response = await fetch(`/api/streamlit/url?url=${encodeURIComponent(inputUrl)}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStreamlitUrl(data.url);
      } else {
        console.error(`Le ping a échoué avec le statut ${pingResponse.status}`);
      }
    } catch (error) {
      console.error('Error fetching the Streamlit URL:', error);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Streamlit</h1>
      <div className="mb-4">
        <input
          type="text"
          value={inputUrl}
          onChange={handleInputChange}
          placeholder="Enter URL"
          className="p-2 border border-gray-300 rounded-md w-full"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={handleButtonClick}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Load Streamlit App
        </button>
      </div>
      {streamlitUrl ? (
        <iframe
          src={streamlitUrl}
          width="800"
          height="600"
          title="Streamlit App"
          className="border border-gray-300 rounded-md"
        />
      ) : (
        <p className="text-gray-600">Please enter a URL and click the button to load the Streamlit app.</p>
      )}
    </div>
  );
};

export default StreamlitApp;