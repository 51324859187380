import React, { useEffect, useState } from 'react';
import { SelectorFileType, SelectorFileVersion } from './Selector';
import { useDataGouv } from '../providers/DataGouvProvider';
import { ButtonIngestion, ButtonIngestionProps, CustomButton } from './ButtonIngestion';
import { FileStatusProps } from './FileStatus';
import { SVGIcon } from '../../../assets';
import { FileData } from '../hooks/useDataIngestion';
import { match } from 'assert';

interface FileLoaderTable {
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;

}

export const FileTableReferentiel: React.FC<FileLoaderTable> = ({files, setFiles}) => {
  const {app} = useDataGouv()
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-100" style={{ backgroundColor: '#EEEEEE' }}>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File name</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File type</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Uploaded by</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <FileRow key={index} index={index} fileName={file.name} setFiles={setFiles} files={files} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileName: string;
  setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;
  files: File[];
  index: number
}

const FileRow: React.FC<FileRowProps> = ({ fileName, setFiles, files, index }) => {
    const {buSelected, dateSelected, uploadFileFromDropzone, ingestionData} = useDataGouv()
    const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
    const [selectedVersion, setSelectedVersion] = useState('B0')
    const [isVersioned, setIsVersioned] = useState<boolean>(false);

    
    
    
    const handleFileTypeChange = (fileType: string) => {
        setSelectedFileType(fileType);
        setIsVersioned(fileType === 'BUDGET' || fileType === 'CPXFORECAST' || fileType === 'CAPEX_FORECAST');
    };

    const handleVersionChange = (fileVersion: string) => {
      const versionWithoutYear = fileVersion.split(dateSelected!)[0]; 
      setSelectedVersion(versionWithoutYear);
    };

    const handleRemoveFile = () => {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    };    

    const handleConfirmClick = () => {
      if (!buSelected || !selectedFileType) return
    
      const linkedFiles = ingestionData?.[buSelected]?.filter((fileData) => fileData.linked === selectedFileType);
    
      const linkedToFile = ingestionData?.[buSelected].find((elem) => elem.file_type === selectedFileType);
    
      let filesToSend: FileData[] | undefined = [];
    
      if (linkedFiles && linkedFiles.length > 0 && linkedToFile) {
        filesToSend = [...linkedFiles, linkedToFile];
      } else {
        filesToSend = ingestionData?.[buSelected]?.filter((fileData) => {
          const isNotLinked = fileData.linked === undefined;
          const isMatchingFileType = fileData.file_type === selectedFileType;
          return isNotLinked && isMatchingFileType;
        });
      }
    
      filesToSend?.forEach((file) => {
        if (isVersioned) {
          uploadFileFromDropzone(buSelected, file.file_type, files[index], selectedVersion);
        } else {
          uploadFileFromDropzone(buSelected, file.file_type, files[index]);
        }
      });

      handleRemoveFile()
    };     

  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="px-4 py-1.5 text-sm text-gray-800">{fileName}</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <SelectorFileType options={ingestionData!} buName={buSelected!}   
            onChange={(e) => handleFileTypeChange(e.target.value)}
            setSelectedFileType={setSelectedFileType}
            value={selectedFileType}
        />
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <CustomButton text='Confirm file info' onClick={handleConfirmClick}/>
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800 cursor-pointer" onClick={handleRemoveFile}>
        <SVGIcon icon='TRASH_ICON'/>
      </td>
    </tr>
  );
};