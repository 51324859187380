import { useState } from "react";
import { useActiveTabs } from "../../../providers";
import { ChatModel } from "../hooks";
import { useChatGPT } from "../providers";
import { TypeToIcon } from "./Answer";
import { ArrowUpIcon, GoogleTranslatorIcon, PaperclipIcon, StreamIcon } from "./Icon";
import { defaultStyles, FileIcon } from "react-file-icon";
import { useMultiDocs } from "../providers/MultiDocsProvider";

export interface UploadFile {
    id: number;
    filename: string;
    type: "txt" | "pdf" | "pptx" | "docx" | "html";
    ocrUsed: boolean
    selected: boolean
}

export const ChatGPTBottom = () => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig,
        gptInput,
        gptModel,
        chatGPTAsk,
        gptGesture,
        homeStatus,
        setHomeStatus,
        chatStatus
    } = useChatGPT();

    const {
        config
    } = useMultiDocs()

    const selectAll = () => {
        const updatedFiles = config.uploadFiles.map(file => ({
            ...file,
            selected: true
        }));
        config.setUploadFiles(updatedFiles);
    };

    const selectOne = (name: string) => {
        const updatedFiles = config.uploadFiles.map(file => ({
            ...file,
            selected: file.file.name === name
        }));
        config.setUploadFiles(updatedFiles);
    };

    const handleSubmit = () => {
        if (chatStatus == "ASSISTANT_OPEN") {
            gptInput.askNavChat(
                chatGPTAsk.setErrorRequestChpt,
                gptConfig.selectedChatId,
                gptInput.text,
                gptModel.selectedChatModel,
                chatGPTAsk.setLoadingGPT,
                gptConfig
            )
        }
        else if (gptConfig.mDModeActive) {
            config.askDocuments(gptInput.text)
        } else {
            gptInput.askGPTStream(
                chatGPTAsk.setErrorRequestChpt,
                gptConfig.selectedChatId,
                gptInput.text,
                gptModel.selectedChatModel,
                chatGPTAsk.setLoadingGPT,
                gptConfig
            )
        }
        gptInput.setText("")
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey && gptInput.text.length > 0) {
            event.preventDefault();
            if (homeStatus) {
                setHomeStatus(false)
            }
            handleSubmit()
        }
    };

    const btn = (
        <button onClick={gptGesture.scrollToEndOfMessages} className="cursor-pointer absolute z-20 rounded-full border right-1/2 translate-x-1/2 bg-white"
            style={{ bottom: `calc(${Math.max(gptInput.chatGPTInputPosition.height, 80)}px + 10px)` }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md m-1 text-token-text-primary">
                <path fill="currentColor" fillRule="evenodd" d="M12 21a1 1 0 0 1-.707-.293l-7-7a1 1 0 1 1 1.414-1.414L11 17.586V4a1 1 0 1 1 2 0v13.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-7 7A1 1 0 0 1 12 21" clipRule="evenodd"></path>
            </svg>
        </button>
    );

    return (
        <div className={`md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent w-full ${chatStatus == "ASSISTANT_OPEN" && "sticky bottom-0 px-4"}`}>
            {!gptGesture.isAtBottom && !homeStatus && btn}
            <div ref={gptInput.inputRef} className="mx-auto flex flex-col flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <div className="flex flex-col items-center">
                    <div className="flex flex-col w-full p-2 bg-gray-100 rounded-[26px] gap-2">
                        {
                            gptConfig.mDModeActive && config.uploadFiles.filter(file => file.processed).length > 0 &&
                            <div>
                                <div className="flex gap-2 overflow-auto p-2">
                                    {
                                        config.uploadFiles.map((file, index) => {
                                            if (!file.processed)
                                                return <></>
                                            return (
                                                <div className="relative" onClick={() => selectOne(file.file.name)}>
                                                    <div className={`overflow-hidden rounded-xl border ${file.selected ? "border-green-500" : "border-gray-300"} flex flex-col justify-between min-h-20 w-fit items-center p-2`}>
                                                        <div className="flex flex-row gap-3 p-2">
                                                            <div className="relative w-10 shrink-0 overflow-hidden rounded-md items-center justify-center content-center">
                                                                <FileIcon
                                                                    extension={file.type.toUpperCase()}
                                                                    type="document"
                                                                    {...defaultStyles[file.type]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div className="truncate font-trendaSemiBold text-xs">{file.file.name.length > 40 ? file.file.name.substring(0, 37) + '...' : file.file.name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2">
                                                        <div className={`flex items-center justify-center h-4 w-4 rounded-full ${file.selected ? "bg-green-500" : "bg-gray-300"} text-white text-xxs`}>
                                                            <svg className="w-3 h-3 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                                <div className="flex justify-end pt-2">
                                    <div className="px-2 border mx-2 w-fit rounded hover:shadow-lg border-text-primary-light text-text-primary-light" onClick={selectAll}>
                                        <span className="text-xs cursor-pointer font-trendaSemiBold px-2">{"Select All".toUpperCase()}</span>
                                    </div>
                                    <div className="relative px-2 border w-fit rounded hover:shadow-lg border-text-primary-light text-text-primary-light" onClick={() => { setModalStatus("MULTI_DOCS") }}>
                                        <span className="text-xs cursor-pointer font-trendaSemiBold px-2">{"Manage files".toUpperCase()}</span>
                                        {1 > 0 && (
                                            <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                                                <div className="flex items-center justify-center h-3 w-3 rounded-full bg-red-500 text-white text-xxs">
                                                    {config.uploadFiles.filter(file => file.processed).length}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="flex">
                            <textarea
                                ref={gptInput.textareaRef}
                                placeholder={gptConfig.mDModeActive ? "Select one or all file then ask question" : "Message SecuredGPT"}
                                className={`flex-1 px-2 bg-transparent border-none outline-none max-h-52 overflow-y-auto resize-none line ${chatStatus != "ASSISTANT_OPEN" ? "leading-8" : "text-sm"}`}
                                value={gptInput.text}
                                onChange={(e) => gptInput.setText(e.target.value)}
                                onKeyDown={handleKeyDown}
                                rows={1}
                            />
                            <div className="flex items-end">
                                {
                                    chatGPTAsk.loadingGPT ?
                                        <button className={`flex items-center justify-center w-8 h-8 bg-black text-[#F4F4F4] rounded-full`}>
                                            <StreamIcon />
                                        </button> :
                                        <button onClick={handleSubmit} className={`flex items-center justify-center ${chatStatus != "ASSISTANT_OPEN" ? "w-8 h-8" : "w-6 h-6"} ${gptInput.text.length == 0 ? "bg-gray-300 text-[#F4F4F4]" : "bg-black text-[#F4F4F4]"} rounded-full`}>
                                            <ArrowUpIcon />
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        chatStatus != "ASSISTANT_OPEN" &&
                        <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
                            <span>SecuredGPT may make mistakes. Consider checking important information.</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}


