import React, { SetStateAction, useState } from 'react';
import { SelectOptions, SelectOptionsDataGouv } from '../DocumentTranslator/SelectedOptions';
import { useDataGouv } from './providers/DataGouvProvider';
import { useAuth } from '../../providers';
import { LoaderCircle } from '../../components';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

const filterOptions = ['pending', 'failed', 'success', 'running']

interface DataTableProps {
  data: {
    file_type: string;
    file_name_sftp: string;
    reception_time: Date;
    status: string;
    reception_frequency: number;
    cause?: string;
    detailed_cause?: string;
    url: string;
  }[];
  loader: boolean;
  filterStatus: string | null;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DataTableQuality: React.FC<DataTableProps> = ({ data, loader, filterStatus, setFilterStatus }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCause, setSelectedCause] = useState<string | null>(null);
  const [selectedDetailedCause, setSelectedDetailedCause] = useState<string | null>(null);
  const [sortedByDate, setSortedByDate] = useState(false);
  const {buSelected, setBuSelected, dateSelected, setDateSelected, buOptions, dateOptions, getQualityDetailForBu} = useDataGouv()
  const {token} = useAuth()
  const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});



  const handleDownload = async(url: string) => {
    setLoadingDownloads((prev) => ({ ...prev, [url]: true }));
    try{
      const response = await fetch(`/api/gouvernance/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({gs_url : url})
      });
      if(!response.ok) return
      const signedUrl = JSON.parse(await response.text());
      const a = document.createElement('a');
      a.href = signedUrl;
      a.download = url.split('/').pop() || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    catch(error){
      console.log(error)
      return null
    }
    finally{
      setLoadingDownloads((prev) => ({ ...prev, [url]: false }));
    }
  };

  const sortByDate = (data: any) => {
    return [...data].sort((a, b) => new Date(a.reception_time).getTime() - new Date(b.reception_time).getTime());
  };

  const handleDateHeaderClick = () => {
    setSortedByDate(!sortedByDate);
  };

  const displayedData = sortedByDate ? sortByDate(data) : data;

  const getStatusColor = (status: string) => {
    if (status === 'failed') return 'bg-red-500';
    if (status === 'pending' || status === 'running') return 'bg-yellow-400';
    if (status === 'success') return 'bg-green-500';
    return '';
  };

  const openModal = (cause: string, detailed_cause: string) => {
    setSelectedCause(cause);
    setSelectedDetailedCause(detailed_cause);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedCause(null);
    setSelectedDetailedCause(null);
  };
  return (
    <div className="overflow-x-auto w-full">
        <div className='w-full bg-white p-2 rounded-xl '>
          <div className="flex w-full">
              <div className="flex flex-row gap-3 w-full items-center justify-start p-1 mb-3">
                  {/* <h1 className="text-black font-trenda">BU Selection:</h1>
                  <SelectOptionsDataGouv
                  options={buOptions}
                  defaultValue="BU Selection"
                  renderOption={(method) => ({ value: method, label: method })}
                  selectedValue={buSelected!}
                  onChange={(e) =>{setBuSelected(e.target.value)} }
                  /> */}
                  <h1 className="text-black font-trenda">Year to Date:</h1>
                  <SelectOptionsDataGouv
                      options={dateOptions}
                      defaultValue="Year to Date"
                      renderOption={(method) => ({ value: method, label: method })}
                      selectedValue={dateSelected!}
                      onChange={(e) => {setDateSelected(e.target.value)}}
                  />
              </div>
              <div className="flex flex-row gap-3 w-full items-center justify-end p-1 mb-3">
                  <h1 className="text-black font-trenda">Filter by status: </h1>
                  <SelectOptions
                      options={filterOptions}
                      defaultValue="All"
                      renderOption={(method) => ({ value: method, label: method })}
                      selectedValue={filterStatus!}
                      onChange={(e) => {setFilterStatus(e.target.value)}}
                  />
            </div>
          </div>
          <div className='w-full border border-gray-200 rounded-md overflow-x-scroll no-scrollbar'>
          {loader ? (
            <div className="text-center py-4">
              <p className='text-md'>Loading data...</p>
            </div>
          ) : data.length === 0 ? (
            <div className="text-center py-4 text-gray-600">
              <p>No data available</p>
            </div>
          ) : 
          (
          <table className="text-sm w-full">
            <thead className='bg-[#EEEEEE]'>
              <tr className="">
                <th className="px-3 py-2 text-left font-medium text-gray-600">
                  Status
                </th> 
                <th className="px-3 py-2 text-left font-medium text-gray-600">
                  File Type
                </th>
                <th className="px-3 py-2 text-left font-medium text-gray-600">
                  File Name
                </th>
                <th className="px-3 py-2 text-left font-medium text-gray-600 cursor-pointer" onClick={handleDateHeaderClick}>
                  <div className='flex flex-row justify-between '>
                      <p>Uploaded Date</p>
                      {sortedByDate ? 
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className=""
                          width="18"
                          height="18"
                          fill={'#EEEEEE'}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill='black'
                            fillRule="evenodd"
                            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                            clipRule="evenodd"
                          ></path>
                        </svg> 
                        : 
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="-rotate-90"
                          width="18"
                          height="18"
                          fill='black'
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill='black'
                            fillRule="evenodd"
                            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      }
                  </div>
                </th>
                <th className="px-3 py-2 font-medium text-left text-gray-600">
                  Reception Frequency
                </th>
                <th className="px-3 py-2 font-medium text-left text-gray-600">
                  Cause
                </th>
                <th className="px-3 py-2 font-medium text-left text-gray-600">
                  Download
                </th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {buOptions.length > 0 && dateOptions.length > 0 &&  displayedData.length > 0 && displayedData.map((item, index) => (
                <tr
                  key={index}
                  className={`border border-gray-200`}
                >
                  <td
                    className={`${getStatusColor(item.status)} ${item.status === 'failed' ? 'cursor-pointer text-white' : ''} px-4`}
                    onClick={() =>
                      item.status === 'failed' &&
                      item.cause &&
                      item.detailed_cause &&
                      openModal(item.cause!, item.detailed_cause!)
                    }
                  >
                    {item.status}
                  </td>
                  <td
                    className={`px-4 ${item.status === 'failed' ? 'cursor-pointer' : ''}`}
                    onClick={() =>
                      item.status === 'failed' &&
                      item.cause &&
                      item.detailed_cause &&
                      openModal(item.cause!, item.detailed_cause!)
                    }
                  >
                    {item.file_type}
                  </td>
                  <td
                    className={`px-4 ${item.status === 'failed' ? 'cursor-pointer' : ''}`}
                    onClick={() =>
                      item.status === 'failed' &&
                      item.cause &&
                      item.detailed_cause &&
                      openModal(item.cause!, item.detailed_cause!)
                    }
                  >
                    {item.file_name_sftp}
                  </td>
                  <td
                    className={`px-4 ${item.status === 'failed' ? 'cursor-pointer' : ''}`}
                    onClick={() =>
                      item.status === 'failed' &&
                      item.cause &&
                      item.detailed_cause &&
                      openModal(item.cause!, item.detailed_cause!)
                    }
                  >
                    {new Date(item.reception_time).toUTCString()}
                  </td>
                  <td
                    className={`px-4 ${item.status === 'failed' ? 'cursor-pointer' : ''}`}
                    onClick={() =>
                      item.status === 'failed' &&
                      item.cause &&
                      item.detailed_cause &&
                      openModal(item.cause!, item.detailed_cause!)
                    }
                  >
                    {item.reception_frequency}
                  </td>
                  <td className="px-4">{item.cause}</td>
                  <td className="px-4 text-center">
                    <button
                      onClick={() => handleDownload(item.url)}
                      className="p-2 hover:bg-gray-200 rounded-md flex items-center justify-center"
                    >
                      {loadingDownloads[item.url] ? (
                          <svg className={`animate-spin h-5 w-5`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M7 10l5 5m0 0l5-5m-5 5V4" />
                          </svg>
                      )} 
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
          </div>
        </div>
      {/* )} */}
      {showModal && selectedCause && selectedDetailedCause && (
        <div className="absolute inset-0 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-md w-[50%] overflow-scroll">
            <h2 className="text-xl font-bold mb-4">Ingestion Error Details</h2>
            <p><strong>Cause:</strong> {selectedCause}</p>
            <p>
              <strong>Detailed Cause:</strong> 
              <div
                dangerouslySetInnerHTML={{ __html: selectedDetailedCause }}
                className="text-justify text-[17px] pl-5 pt-2"
              />
            </p>
            <button onClick={closeModal} className="mt-4 p-2 bg-red-500 text-white rounded-md">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
