import { useChatGPT } from "../providers";
import { GoogleTranslatorIcon } from "./Icon";
import { ChatGPTTranslator } from "./ChatGPTTranslator";


export const ChatGPTModalTranslator = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig
    } = useChatGPT();

    return (
        <div className="w-[600px]">
            <div className="px-2 pb-2 pt-2 sm:p-4 flex items-center justify-between border-b border-black/10 dark:border-white/10">
                <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-8 h-8 ">
                        <GoogleTranslatorIcon />
                    </div>
                    <h2 className="text-lg font-medium leading-6 text-token-text-primary">Document translator</h2>
                </div>
                <button className="flex h-8 w-8 items-center justify-center" onClick={() => { setModalStatus("CLOSE") }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                        <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div className="flex">
                <div className="flex flex-col m-2 p-2 gap-2 content-center">
                    <div>
                        <div className="text-black text-md pb-2">
                            <span>Simply upload your files, and our automated system will handle the translation process seamlessly.<br />Please be aware that the current document size limit is set to 20 pages.</span>
                        </div>
                        <ChatGPTTranslator />
                    </div>
                </div>
            </div>
        </div>
    )
}