import { ReactNode, useEffect, useState } from 'react';
import { SetupPanelState, useCTRConfig, useSessionId } from '../../hooks';
import { SetupPanel } from './SetupPanel';
import { CTRNews } from './CTRNews';
import { useAuth } from '../../providers';
import { SVGIcon } from '../../assets';
import { LoaderComponent } from '../../components';

export const CustomTrafficReport = () => {
    const sessionId = useSessionId(`/api/custom_traffic_report/clear_session`);
    const [setupData, setSetupData] = useState<SetupPanelState | null>(null)

    const { token } = useAuth()
    //const { news, loading, fetchDataForSubmit, fetchDataForUpdate, fetchDataForLoadParagraph, fetchCorrectParagraph, fetchDownload } = useCFRTitle({ sessionId });
    const { config, loadingConfig, fetchDataForSubmit, news, loader,setLoader, setNews } = useCTRConfig(sessionId, token);

    return (
        <div className="flex-1 flex flex-row h-full max-w-full items-center justify-center">
            <LoaderComponent loadingConfig={loadingConfig}>
                <CTRNews loader={loader} data={news} loading={loadingConfig} />
                <SetupPanel setLoader={setLoader} setNews={setNews} data={news} config={config} fetchDataForSubmit={fetchDataForSubmit} loading={loadingConfig} />
            </LoaderComponent>
        </div>
    );
}
