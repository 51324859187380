
import { useState, useEffect } from 'react';
import { CFRConfig, News, SetupPanelState } from '../types';
import { useAuth, useCredit } from '../providers';

export const useCFRConfig = (sessionId: string, token: string | null) => {
  const [config, setConfig] = useState<CFRConfig | null>(null);
  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);

  useEffect(() => {
    if (token === null || sessionId === "") {
      setConfig(null);
      return;
    }
    fetch(`/api/custom_finance_report/config/${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        return response.json()
      })
      .then(data => { setConfig(data); setLoadingConfig(false) })
      .catch(error => { console.error('Erreur lors de la récupération de la configuration', error); });
  }, [sessionId, token]);

  return { config, loadingConfig, setConfig };
};

export const useCFRTitle = ({ sessionId }: { sessionId: string }) => {
  const [news, setNews] = useState<News | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAuth()
  const { remainingCredit, setRemainingCredit } = useCredit()

  // Fonction de base
  const performFetch = async (url: string, setupData: SetupPanelState) => {
    if (setupData && setupData.airport && !loading) {
      setLoading(true);
      const payload = JSON.stringify({
        month_date: setupData.dates,
        report_type: setupData.reportType,
        ingestionId: setupData.airport,
        id: sessionId,
        first_level: setupData.firstLevel,
        second_level: setupData.secondLevel,
        budget_id: setupData.budget,
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: payload
      })
        .then(response => response.json())
        .then(data => {
          setNews(data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de la configuration', error);
          setLoading(false);
        });
    }
  };

  // Fonctions spécifiques
  const fetchDataForSubmit = async (setupData: SetupPanelState) => {
    const url = `/api/custom_finance_report/submit`;
    await performFetch(url, setupData);
  };

  const fetchDataForUpdate = async (setupData: SetupPanelState) => {
    const url = `/api/custom_finance_report/update_section_title`;
    if (setupData.firstLevel && setupData.secondLevel && setupData.budget) {
      await performFetch(url, setupData);
    }
  };

  const fetchDownload = async () => {
    fetch(`/api/custom_finance_report/download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ id: sessionId })
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `cfr_newsletter_${sessionId}.docx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch(error => console.error('Erreur lors de la récupération de la configuration', error));

  };

  const fetchDataForLoadParagraph = async (setupData: SetupPanelState) => {
    if (!news || news.ids.length === 0 || loading) {
      return;
    }

    try {
      setLoading(true);

      const payload = JSON.stringify({
        language: setupData.language,
        section_ids: news.ids,
        id: sessionId
      });

      const response = await fetch(`/api/custom_finance_report/load_paragraph`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: payload
      });

      if (!response.ok) {
        throw new Error('Erreur lors du chargement des paragraphes');
      }

      const resInfo = await fetch(`/api/custom_finance_report/infos/${sessionId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!resInfo.ok) {
        throw new Error('Erreur lors de la récupération des infos');
      }

      const data = await resInfo.json();
      setNews(data);

      if (remainingCredit && data.credit) {
        const newCredit = remainingCredit - data.credit;
        setRemainingCredit(newCredit);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const fetchCorrectParagraph = async (comment: string, sectionId: string) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/custom_finance_report/correct_paragraph`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          correction: comment,
          section_id: sectionId,
          id: sessionId
        }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();
      setNews(data);
      if (remainingCredit && data.credit) {
        const new_credit = remainingCredit - data.credit
        setRemainingCredit(new_credit)
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    } finally {
      setLoading(false);
    }
  };


  return { news, loading, setLoading, fetchDataForSubmit, fetchDataForUpdate, fetchDataForLoadParagraph, fetchCorrectParagraph, fetchDownload, setNews };
};