// TermsAndConditionsContent.ts
export const termsAndConditions: string = `
Corporate Usage Policy

__VINCI Airports new “Smart Data Hub Portal” including generative AI functionalities\.__

Effective Date: \[Insert Date\]

__Definition __

“__SDH Project__” Smart Data Hub project 

“__SDH Project Founders__” provider of the solution led by VINCI Concessions SAS and VINCI Airports SAS \(the\)

“__SDH Portal__” Smart Data Hub Portal

__"Affiliated Companies of VINCI Airports"__ refers to all companies that control or are controlled or are under common control at VINCI Airports\. 

__“Generative Artificial intelligence”__ Generative Artificial Intelligence refers to algorithms and Machine Learning techniques that utilize existing content for their learning processes to generate new content\. This can involve the generation of text, sounds, or images

1. __Purpose__

This policy outlines the appropriate use of SDH portal within VINCI Airports\. It aims to ensure that all employees understand the capabilities and limitations of SDH portal and use the tool in a manner that is consistent with company values, ethical standards, and legal requirements\.

1. __Scope__

This policy applies to all employees within VINCI Airports headquarters, and the Affiliated Companies of VINCI Airports who have access to and use SDH Portal for business\-related purposes\.

1. __Policy__
	1. __Authorized Use__

SDH Portal is a proprietary version exclusive to VINCI Airports\. All interactions and data shared with this version remain within the company and are not disclosed externally\.

SDH Portal should be used for business\-related purposes only, unless otherwise specified\.

Users must have appropriate permissions to access and use SDH Portal validated by SDH Project Founders

- 
	1. __Data Privacy and Confidentiality__

For the purposes hereof, “__Confidential__ __Information__” shall mean:

1. any information of whatever type and nature \(including but not limited to technical, commercial, financial, traffic, fiscal, accounting, legal, human resources and administrative information, and including all documents, data, personal data, concepts, analyses, reports, elements of know\-how or strategic, marketing, communication, sales and operations and business approaches\), particularly any information regarding any VINCI Concessions or VINCI Airports entity, in relation to the __SDH Project__ and to which I will have access in the context of my duties within the __SDH Project__, whether or not identified as proprietary, privileged or confidential, whether communicated or disclosed orally, in written or electronic form or by any other media, at any time whether before, after or on the date of signature of this engagement;
2. all such analysis, compilations, studies, governance documents and other documents or records which may contain, reflect, refer to or simply derive from, the information mentioned in subparagraph a\) above; 
3. the existence of the __SDH Project__ and the existence and content of this engagement\. 

I, hereby, undertake:

1. to protect and safeguard the confidentiality of Confidential Information;
2. to use Confidential Information for the strict and exclusive purpose of the Smart Data Hub Project and for the exercise of my duties within the Company; 
3. not to disclose to any third party, whether directly or indirectly and by any means all or part of Confidential Information, without the express prior authorization of one of the Smart Data Hub Project Founders, provided however that Confidential Information may be disclosed to directors, officers or employees of the Company or of the Smart Data Hub Project Founders on a strict need\-to\-know basis;
4. to take all measures within the framework of my duties and in accordance with state of the art practices and with my Company guidelines and procedures in order to avoid the misuse or fraudulent use of Confidential Information;
5. in the event of termination of my duties, not to keep any Confidential Information and promptly return or destroy, at the Company’s choice, such Confidential Information to the Company and /or to the SDH Project Founders; and
6. to inform the Company and the __SDH Project__ Founders of any breach of the obligations contained in this engagement\.

While interactions with our proprietary version of Secure Chatbot using OpenAI services from VINCI Concessions Azure tenant or Gemini from VINCI Concessions Google Cloud tenant are kept within the company, users should still exercise caution and avoid sharing excessively sensitive or confidential information\. Do not share personal, private or sensitive information and data with SDH Portal\. This includes nominative HR related information\.

Always assume that interactions with SDH portal, even if internal, are not private and should be treated with a degree of confidentiality\.

- 
	1. __Ethical Use__

Do not use SDH portal to generate or promote content that is illegal, unethical, or against company values\.

Respect copyright and intellectual property rights\. Do not use SDH portal to reproduce copyrighted materials without proper authorization from Smart Data Hub Project Founders

When interacting with the SDH Portal, make sure to:

1. promote respect, safety, and legality,
2.  respect intellectual property rights, avoiding the creation, distribution, or promotion of content that infringes on the copyrights, trademarks, or other legal rights of others\. This includes refraining from generating content that replicates or closely mimics the proprietary work of creators without proper authorization or attribution,
3. respect the privacy and rights of individuals, avoiding the creation of content that could be considered defamatory, invasive of privacy, or harmful in any way to individuals or groups\.

VINCI Airports promotes a positive and inclusive environment\. You are urged to avoid creating or spreading content that promotes hate speech, discrimination, or violence against individuals or groups based on race, ethnicity, religion, gender, sexual orientation, disability, or any other characteristic\.

VINCI Airports reserves the right to monitor and review content generated through SDH Portal services to ensure compliance with these ethical standards, and to take appropriate action, including content removal and account suspension, against users who violate these principles\.

- 
	1. __Accuracy and Reliability__

While SDH portal is a powerful tool, it is not infallible\. Always verify critical information obtained from SDH portal through other reliable sources\.

Do not solely rely on SDH portal for decision\-making\. It should be used as a supplementary tool\.

- 
	1. __Training and Awareness__

Users should undergo basic training on how to use SDH portal effectively and ethically\.

Users should be made aware of this policy and its implications\.

- 
	1. __Limitations__

SDH portal should not be used for tasks that require human judgment, empathy, or nuanced understanding\.

It should not be used as the sole tool for tasks that have legal, financial, or safety implications\.

- 
	1. __Monitoring and Compliance:__

VINCI Airports reserves the right to monitor the use of SDH portal to ensure compliance with this policy\.

any violation of this engagement will expose me to disciplinary sanctions in accordance with applicable legislation and regulation\.__ __

To ensure adherence to this Corporate Usage Policy, monitoring and compliance mechanisms are implemented\. These systems are designed to detect and address violations of our ethical use guidelines, including the misuse of AI technology and the creation or dissemination of inappropriate content\.

This includes automated tools that flag potential policy breaches, supplemented by human oversight to ensure accurate evaluation and fair decision\-making\. When violations are identified, appropriate action is taken, which may range from content removal and warnings to users, up to account suspension or termination for severe or repeated offenses\.

1. __Reporting__

If you come across any misuse of SDH portal or have concerns about the content generated, report it to the appropriate department or person, e\.g\. local ambassador, or Smart Data Hub Project Founders\.

If you come across any misuse of SDH Portal or have concerns about content that violates our ethical use policy, including content that infringes on intellectual property, invades privacy, or promotes hate speech, discrimination, or violence, you should report it to the SDH Projects Founders through the reporting feature available on the SDH Portal\.

Upon receiving a report, SDH Projects Founders will promptly review the content in question and take appropriate measures to address the issue, which may include removing the content, suspending the account or the service responsible, or taking other actions as necessary to prevent further harm\. 

1. __Review and Updates__

This policy will be reviewed annually or as deemed necessary\. Updates will be communicated to all relevant stakeholders though the portal\.

The dynamic nature of technology and society necessitates that the SDH Portal's user policy, including the ethical use and content guidelines, is regularly reviewed, and updated to reflect the latest legal, ethical, and social standards\. Comprehensive reviews of our policies will be conducted at least annually, or more frequently as needed, to adapt to new usages, regulatory changes, and advancements in AI technology\.

SDH Portal’s users will be notified of updates to ensure transparency and to provide guidance on how changes may affect their use of the portal\.

1. __Acknowledgment__

All users of SDH portal within VINCI Airports are required to acknowledge that they have read, understood, and agreed to adhere to this policy\.

`;
