import { Link } from "react-router-dom";
import { useTheme } from "../../providers";
import { SVGIcon } from "../../assets";

export const SideBarLogo = (props: { isSidebarOpen: Boolean }) => {
  const { isSidebarOpen } = props;
  const { appTheme } = useTheme();
  const src = isSidebarOpen ? (
    appTheme === "dark" ? (
      <SVGIcon size={"96px"} icon="BIG_VINCI_ICON" />
    ) : (
      <SVGIcon size={"96px"} icon="BIG_VINCI_ICON" />
    )
  ) : (
    <SVGIcon icon="SMALL_VINCI_ICON" />
  );

  return (
    <div className="flex justify-center pt-6">
      <Link to="/">{src}</Link>
    </div>
  );
};
