import { useState, useEffect } from "react";
import { ThemePrompts, useSecureGPTConfig, useSessionId} from "../../hooks";
import { useAuth } from "../../providers";



interface DropdownProps {
    isOpen: boolean;
    toggleOpen: () => void;
    suggestedPrompts: ThemePrompts
}



  
   

export const Dropdown: React.FC<DropdownProps> = ({isOpen,toggleOpen,suggestedPrompts}) =>{
    //const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false);
    // const { token } = useAuth()
    // const sessionId = useSessionId(`/api/securegpt/clear_session`);
    // const {suggestedPrompts} = useSecureGPTConfig(token, sessionId)

    const [isNestedDropdownOneOpen, setIsNestedDropdownOneOpen] = useState(false);
    const [isNestedDropdownTwoOpen, setIsNestedDropdownTwoOpen] = useState(false);

    const [isCopied, setIsCopied] = useState<boolean>(false);

    const [selectedTheme, setSelectedTheme] = useState<string>('Tech');
    const [selectedEntry, setSelectedEntry] = useState<string>('');
    const [optionsDropdown1, setOptionsDropdown1] = useState<string[]>([]);
    const [optionsDropdown2, setOptionsDropdown2] = useState<string[]>([]);
    const [selectedDefinition, setSelectedDefinition] = useState<string>("");
    const [promptTemplate, setPromptTemplate] = useState<string>("");


    useEffect(() => {
      const themes = Object.keys(suggestedPrompts);
      setOptionsDropdown1(themes);
  
      if (themes.length > 0) {
        const defaultTheme = themes[0];
        setSelectedTheme(defaultTheme);
        const selectedThemeObject = suggestedPrompts[defaultTheme];
        if (selectedThemeObject) {
          const keys = Object.keys(selectedThemeObject);
          setOptionsDropdown2(keys);
          if (keys.length > 0) {
            const defaultKey = keys[0];
            setSelectedEntry(defaultKey);
            const defaultPrompt = selectedThemeObject[defaultKey];
            if (defaultPrompt) {
              setSelectedDefinition(defaultPrompt.definition);
              setPromptTemplate(defaultPrompt.prompt_template);
            }
          }
        }
      }
    }, [suggestedPrompts]);

    useEffect(() => {
        if (optionsDropdown2.length > 0) {
            const defaultDesc = optionsDropdown2[0];
            handleDescriptionSelection(defaultDesc);
        }
    }, [optionsDropdown2]);

   
    const handleThemeSelection = (theme: string) => {
        setSelectedTheme(theme);
        const selectedThemeObject = suggestedPrompts[theme]
        if (selectedThemeObject) {
          const keys = Object.keys(selectedThemeObject);
          setOptionsDropdown2(keys);
          setSelectedEntry('');
      }
    };

    const handleDescriptionSelection = (key: string) => {
      setSelectedEntry(key);
      const selectedThemeObject = suggestedPrompts[selectedTheme];
      if (selectedThemeObject) {
        const selectedPrompt = selectedThemeObject[key];
        if (selectedPrompt) {
          setSelectedDefinition(selectedPrompt.definition);
          setPromptTemplate(selectedPrompt.prompt_template);
        }
      }
    };
  
    const toggleMainDropdown = () => {
        toggleOpen()
        if(isNestedDropdownOneOpen) setIsNestedDropdownOneOpen(false)
        if(isNestedDropdownTwoOpen) setIsNestedDropdownTwoOpen(false)
    }
    const toggleNestedDropdownOne = () => {
        setIsNestedDropdownOneOpen(!isNestedDropdownOneOpen);
        if(isNestedDropdownTwoOpen) setIsNestedDropdownTwoOpen(false)

    }
    const toggleNestedDropdownTwo = () => {
        setIsNestedDropdownTwoOpen(!isNestedDropdownTwoOpen);
        if(isNestedDropdownOneOpen) setIsNestedDropdownOneOpen(false)
    }

    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(promptTemplate);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          setIsCopied(false);
        }
    };
    return(
        <div className="w-full max-w-xl whitespace-nowrap rounded-xl px-4 text-left text-token-text-primary md:whitespace-normal">
            <button onClick={toggleMainDropdown} className="hover:text-emerald-500 text-gray-700 flex justify-between items-center w-full px-4 py-2 text-left bg-white rounded-lg shadow border border-gray-300 focus:outline-none">
                <span className="w-full">Expand it to see some optimized prompt &nbsp;</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                </svg>
            </button>
            <div  className={`w-90 bg-white relative transition-all ease-in-out duration-500 ${isOpen ? 'border border-gray-300 rounded-lg' : ''} ${isOpen ? 'max-h-fit' : 'max-h-0'}`}>
                {/* Nested Dropdowns */}
                <div className={`flex p-4 gap-5 absolute z-20 w-full h-fit transition-all ease-in-out duration-500 ${isOpen ? 'opacity-1' : 'opacity-0'}`}>
                    {/* Nested Dropdown One */}
                    <div className="flex flex-col w-2/5 "> 
                        <button onClick={toggleNestedDropdownOne} className="text-sm flex justify-between items-center px-4 py-2 text-left bg-gray-300 font-semibold rounded-lg shadow border border-gray-300 focus:outline-none">
                            <span className="text-gray-700 ">{selectedTheme}&nbsp;</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNestedDropdownOneOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                            </svg>
                        </button>
                        {isNestedDropdownOneOpen && (
                        <div className="w-full overflow-y-auto rounded-md shadow-lg bg-white z-999">
                            <ul className="text-gray-700">
                            {optionsDropdown1.map((option, index) => (
                                <li key={index} className="hover:bg-gray-200 cursor-pointer px-4 py-2 border-b border-gray-200 text-sm" onClick={() => {
                                    handleThemeSelection(option);
                                    toggleNestedDropdownOne()
                                    }}
                                >
                                {option}
                                </li>
                            ))}
                            </ul>
                        </div>
                        )}
                    </div>
                    {/* Nested Dropdown Two */}
                    <div className="relative w-full flex-1">
                        <button onClick={toggleNestedDropdownTwo} className="text-sm flex justify-between items-center w-full px-4 py-2 text-left bg-gray-300 font-semibold rounded-lg shadow border border-gray-300 focus:outline-none">
                        <span className="text-gray-700 ">{selectedEntry}&nbsp;</span>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNestedDropdownTwoOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                        </svg>
                        </button>
                        {isNestedDropdownTwoOpen && (
                        <div className="absolute w-full mt-1 rounded-md shadow-lg bg-white z-999">
                            <ul className="text-gray-700">
                            {optionsDropdown2.map((option, index) => (
                                <li key={index} className="hover:bg-gray-200 cursor-pointer px-4 py-2 border-b border-gray-200 text-sm" onClick={() => {
                                    handleDescriptionSelection(option);
                                    toggleNestedDropdownTwo()
                                    }}
                                >
                                {option}
                                </li>
                            ))}
                            </ul>
                        </div>
                        )}
                    </div>
                </div>
                {
                    isOpen &&
                    <>
                        <div className="w-full h-fit">
                            <p className="mx-4 my-2 p-2 pt-4 pb-4 mt-20 text-black">
                                {selectedDefinition}
                            </p>
                        </div>

                        {/* Grey rectangle with text */}
                        <div className="mx-4 my-2 p-4 bg-gray-200 mb-2 rounded relative mt-5">
                            {isCopied ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                                    <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                                </svg>
                            ) : (
                                <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="absolute top-0 right-0 m-2 cursor-pointer">
                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                                </svg>
                            )}
                            <p className="text-black p-2">
                                {promptTemplate}
                            </p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}