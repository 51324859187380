import { useEffect, useRef, useState } from "react";
import { useAuth, useCredit } from "../../../providers";
import { TypeToIcon } from "../../../components";
import { ConversationMessage, DataHistory, ModelGPT, SecuredGPTConfig } from "./useChatGPTConfig";
import { ChatModel } from "./chatGPTModel";
import { useChatGPT } from "../providers";
import { UploadFileType } from "./useMultiDocsConfig";

interface ChatGPTInputPosition {
  x: number;
  y: number;
  height: number;
}

export interface ChatGPTInputHook {
  text: string;
  textareaHeight: number;
  setText: (text: string) => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  inputRef: React.RefObject<HTMLDivElement>;
  updatePosition: () => void;
  chatGPTInputPosition: {
    x: number;
    y: number;
    height: number;
  };
  setChatGPTInputPosition: React.Dispatch<React.SetStateAction<{
    x: number;
    y: number;
    height: number;
  }>>;
  askGPTStream: (
    setErrorRequestChpt: (arg0: boolean) => void,
    sessionId: any,
    text: string,
    model: ChatModel,
    setLoadingGPT: (arg0: boolean) => void,
    gptConfig: SecuredGPTConfig
  ) => any;
  setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
  updateConversation: (chatId: string, message: ConversationMessage | string, action: "add" | "update", setSecuredGPTHistory: React.Dispatch<React.SetStateAction<DataHistory>>, loading?: boolean) => void
  addInfo: (sessionId: string, type: "text_info" | "text_switch", text: string, gptConfig: SecuredGPTConfig) => Promise<void>
  askNavChat: (
    setErrorRequestChpt: (arg0: boolean) => void,
    sessionId: any,
    text: string,
    model: ChatModel,
    setLoadingGPT: (arg0: boolean) => void,
    gptConfig: SecuredGPTConfig
  ) => any;
}


export type messageGPT = {
  type: string;
  props: {
    type: keyof typeof TypeToIcon;
    text: string;
    typed: boolean;
    loading?: boolean;
    upload?: { filename: string; type: UploadFileType; }[]
    data_type?: "text" | "file_ask" | "file_upload" | "text_info" | "text_switch" | "text_warning"
  };
}

export const modelName: any = {
  'gpt': 'GPT',
  'gpt4': 'GPT4',
  'mistral': 'MISTRAL',
  'gemini': 'GEMINI'
}

export function useChatGPTInput(): ChatGPTInputHook {
  const [chatGPTInputPosition, setChatGPTInputPosition] = useState({ x: 0, y: 0, height: 0 });
  const [isMounted, setIsMounted] = useState(false);
  const [text, setText] = useState<string>('');
  const [textareaHeight, setTextareaHeight] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const { remainingCredit, setRemainingCredit } = useCredit()
  const { token } = useAuth()

  const updatePosition = () => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setChatGPTInputPosition({
        x: Math.round(rect.left) - 256,
        y: rect.top,
        height: Math.round(rect.height),
      });
    }
  };

  useEffect(() => {
    if (isMounted) {
      updatePosition();
      window.addEventListener('resize', updatePosition);
    }
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [isMounted]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;
      setTextareaHeight(newHeight);
      updatePosition();
    }
  }, [text]);

  const updateConversation = (
    chatId: string,
    message: ConversationMessage | string,
    action: "add" | "update",
    setSecuredGPTHistory: React.Dispatch<React.SetStateAction<DataHistory>>,
    loading?: boolean
  ) => {
    setSecuredGPTHistory(prevHistory => {
      const sessionData = prevHistory[chatId];

      if (!sessionData) {
        console.error(`No session found for chatId: ${chatId}`);
        return prevHistory;
      }

      const updatedConversation = [...sessionData.conversation];

      if (action === 'update') {
        if (updatedConversation.length === 0) {
          console.error(`No messages found in conversation for chatId: ${chatId}`);
          return prevHistory;
        }

        // Update the last message in the conversation
        const lastMessage = updatedConversation[updatedConversation.length - 1];

        if (typeof message === 'string') {
          if (lastMessage.assistant !== undefined) {
            updatedConversation[updatedConversation.length - 1] = { ...lastMessage, assistant: message, loading: loading };
          } else if (lastMessage.user !== undefined) {
            updatedConversation[updatedConversation.length - 1] = { ...lastMessage, user: message };
          }
        } else {
          console.error(`Expected message to be of type string for update action`);
          return prevHistory;
        }
      } else if (action === 'add') {
        // Add a new message to the conversation
        if (typeof message !== 'string') {
          updatedConversation.push(message);
        } else {
          console.error(`Expected message to be of type ConversationMessage for add action`);
          return prevHistory;
        }
      } else {
        console.error(`Unknown action: ${action}`);
        return prevHistory;
      }

      const updatedSessionData = {
        ...sessionData,
        conversation: updatedConversation,
      };

      return {
        ...prevHistory,
        [chatId]: updatedSessionData,
      };
    });
  };


  const askGPTStream = async (
    setErrorRequestChpt: (arg0: boolean) => void,
    sessionId: string,
    text: string,
    model: ChatModel,
    setLoadingGPT: (arg0: boolean) => void,
    gptConfig: SecuredGPTConfig) => {

    setLoadingGPT(true);

    updateConversation(
      sessionId,
      { data_name: null, data_type: "text", role: "user", content: text, user: text, model: null },
      "add",
      gptConfig.setSecuredGPTHistory
    )

    try {
      const response = await fetch(`/api/secured_gpt/askstream`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({
          id: sessionId,
          message: text,
          model: model
        }),
      });
      if (response.ok && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let accumulatedText = '';

        updateConversation(
          sessionId,
          { data_name: null, data_type: "text", role: "assistant", content: accumulatedText, assistant: accumulatedText, model: model, loading: !done },
          "add",
          gptConfig.setSecuredGPTHistory
        )
        let last = 0;
        while (!done && last != 1) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            if (chunk.startsWith("credit_event_stream_vinci_sdh")) {
              const parts = chunk.split("##");

              parts.forEach(part => {
                if (part.startsWith("credit_event_stream_vinci_sdh")) {
                  const creditValue = parseFloat(part.split(": ")[1]);
                  if (remainingCredit && creditValue) {
                    const new_credit = remainingCredit - creditValue;
                    setRemainingCredit(new_credit);
                  }
                } else if (part.startsWith("res_title_vinci_sdh")) {
                  const newTitle = part.split(": ").slice(1).join(": ");
                  gptConfig.setSecuredGPTHistory(prevHistory => ({
                    ...prevHistory,
                    [sessionId]: {
                      ...prevHistory[sessionId],
                      title: newTitle,
                    }
                  }));
                }
              });
            } else {
              accumulatedText += chunk;
            }
          }
          updateConversation(
            sessionId,
            accumulatedText,
            "update",
            gptConfig.setSecuredGPTHistory,
            !done
          )
          if (done)
            last = 1
        }
        setLoadingGPT(false);
      }
      else {
        setErrorRequestChpt(true)
        updateConversation(
          sessionId,
          { data_name: null, data_type: "text", role: "error", content: "An Error has occurred", error: "An Error has occurred", model: model },
          "add",
          gptConfig.setSecuredGPTHistory
        )
        setLoadingGPT(false)
      }
    } catch (error) {
      setLoadingGPT(false)
    }
  };

  const askNavChat = async (
    setErrorRequestChpt: (arg0: boolean) => void,
    sessionId: string,
    text: string,
    model: ChatModel,
    setLoadingGPT: (arg0: boolean) => void,
    gptConfig: SecuredGPTConfig) => {

    setLoadingGPT(true);

    updateConversation(
      sessionId,
      { data_name: null, data_type: "text", role: "user", content: text, user: text, model: null },
      "add",
      gptConfig.setSecuredGPTHistory
    )

    try {
      const response = await fetch(`/api/nav_chat/askstream`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({
          id: sessionId,
          message: text,
        }),
      });
      if (response.ok && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let accumulatedText = '';

        updateConversation(
          sessionId,
          { data_name: null, data_type: "text", role: "assistant", content: accumulatedText, assistant: accumulatedText, model: "gemini", loading: !done },
          "add",
          gptConfig.setSecuredGPTHistory
        )
        let last = 0;
        while (!done && last != 1) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            if (chunk.startsWith("credit_event_stream_vinci_sdh")) {
              const parts = chunk.split("##");

              parts.forEach(part => {
                if (part.startsWith("credit_event_stream_vinci_sdh")) {
                  const creditValue = parseFloat(part.split(": ")[1]);
                  if (remainingCredit && creditValue) {
                    const new_credit = remainingCredit - creditValue;
                    setRemainingCredit(new_credit);
                  }
                } else if (part.startsWith("res_title_vinci_sdh")) {
                  const newTitle = part.split(": ").slice(1).join(": ");
                  gptConfig.setSecuredGPTHistory(prevHistory => ({
                    ...prevHistory,
                    [sessionId]: {
                      ...prevHistory[sessionId],
                      title: newTitle,
                    }
                  }));
                }
              });
            }
            else if (chunk.indexOf("<get_dashboard_insigh") !== -1) {
              accumulatedText += chunk.substring(0, chunk.indexOf("<get_dashboard_insigh"));
              console.log(chunk)
            }
            else {
              accumulatedText += chunk;
            }
          }
          updateConversation(
            sessionId,
            accumulatedText,
            "update",
            gptConfig.setSecuredGPTHistory,
            !done
          )
          if (done)
            last = 1
        }
        setLoadingGPT(false);
      }
      else {
        setErrorRequestChpt(true)
        updateConversation(
          sessionId,
          { data_name: null, data_type: "text", role: "error", content: "An Error has occurred", error: "An Error has occurred", model: model },
          "add",
          gptConfig.setSecuredGPTHistory
        )
        setLoadingGPT(false)
      }
    } catch (error) {
      setLoadingGPT(false)
    }
  };

  const addInfo = async (
    sessionId: string,
    type: "text_info" | "text_switch",
    text: string,
    gptConfig: SecuredGPTConfig) => {

    updateConversation(
      sessionId,
      { data_name: null, data_type: type, role: "info", content: text, info: text, model: null },
      "add",
      gptConfig.setSecuredGPTHistory
    )

    try {
      await fetch(`/api/secured_gpt/info`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({
          id: sessionId,
          message: text,
          type: type
        }),
      });
    } catch (error) {
      console.log(error)
    }
  };

  return { text, textareaHeight, setText, textareaRef, inputRef, updatePosition, chatGPTInputPosition, setChatGPTInputPosition, askGPTStream, setIsMounted, updateConversation, addInfo, askNavChat };
}
