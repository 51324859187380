import { useRef } from "react";
import { SVGIcon } from "../../assets";
import { messageTMM } from "../../hooks";

interface DisplayPrompt {
    prompts: string[] | null;
    reloadPrompt?: () => Promise<void>
    ask: ((text: string) => Promise<void>) | undefined | (() => void);
    resetMessages: React.Dispatch<React.SetStateAction<messageTMM[]>>
    app: "SDH" | "GPT";
    setInput: (text: string) => void;
    resetText?: string;
    resetDoc?: boolean;
    setResetDoc?: React.Dispatch<React.SetStateAction<boolean>>;
}



export const PromptBubble: React.FC<DisplayPrompt> = ({ prompts, reloadPrompt, ask, resetMessages, app, setInput, resetText, resetDoc, setResetDoc }) => {
    if (!prompts) {
        prompts = [
            "What is the AI?",
            "What is the AI?",
            "Where can I find Traffic figure for Gatwick?",
            "What is the latest news about Belgrade Airport?",
        ]
    }
    const ref = useRef()

    return (
        <div className="flex w-full justify-center items-center mb-2.5">
            <div className="flex w-11/12 justify-center items-center">
                <div className={`flex min-w-[90px] ${app != 'SDH' && "w-full"} justify-between items-center gap-2`}>
                    <button onClick={() => resetMessages([])} type="submit" className="p-3 cursor-pointer hover:shadow-md rounded-md text-xs text-[#4A4A4A] border border-[#E6E6E6] bg-white hover:border-red-500 hover:text-red-500">
                        {resetText ? resetText : "Reset Chat"}
                    </button>
                    {resetDoc && setResetDoc && <button onClick={() => setResetDoc(true)} type="submit" className="pl-2 pr-2 pt-1 pb-1 cursor-pointer hover:shadow-md rounded-md text-xs text-text-primary-light border border-text-primary-light bg-white hover:border-red-500 hover:text-red-500">
                        {"Delete documents"}
                    </button>}
                </div>
                {app === 'SDH' &&
                    <div className="flex w-full items-center justify-end">
                        {Array.isArray(prompts) && prompts?.map((e, i) => {
                            return (
                                <div onClick={(e) => setInput(prompts![i])} key={i} className="flex justify-between p-3 ml-2 cursor-pointer hover:shadow-md rounded-md border border-[#E6E6E6] bg-white hover:text-text-primary-light hover:border-text-primary-light">
                                    <p className="font-medium text-[#4A4A4A] text-xs">{e}</p>
                                </div>
                            )
                        })}
                        <div className="flex pl-2 cursor-pointer">
                            <div onClick={reloadPrompt}>
                                <SVGIcon icon="REFRESH_ICON" className=" fill-[#4A4A4A]" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
};
