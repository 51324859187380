
import { useState, useEffect } from 'react';
import { useCredit } from '../providers';

export type DateScope = 'Weekly' | 'Monthly';

export interface CTRConfig {
  bu: string[];
  date_scopes_options: string[];
  year_over_year_comparisons_options: string[];
  [key: string]: any;
  Weekly: { period1_options: string[], period0_options: string[] };
  Monthly: { period1_options: string[], period0_options: string[] };
}

type Section = { [key: string]: string; }
export interface SetupPanelState {
  bu: string | null;
  dateScope: string;
  year: string;
  period0: string;
  period1: string;
  level1: string;
  level2: string;
  level1_nb: number;
  level2_nb: number;
  section_id: string;
  correction: string;
  language: string | null;
  format_recoding: string;
}

export interface News {
  ids: string[];
  intro: string;
  section: Section;
  level1: string[];
  level2: string[];
  max_level1: number;
  max_level2: number;
  level1_selected: string;
  level2_selected: string;
  level1_nb: number;
  level2_nb: number;
  credit: number;
}

export const useCTRConfig = (sessionId: string, token: string | null) => {
  const [config, setConfig] = useState<CTRConfig | null>(null);
  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false)
  const [news, setNews] = useState<News | null>(null);
  const { remainingCredit, setRemainingCredit } = useCredit()


  useEffect(() => {
    if (token === null || sessionId === "") {
      setConfig(null);
      return;
    }
    fetch(`/api/custom_traffic_report/config/${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        return response.json()
      })
      .then(data => { setConfig(data); setLoadingConfig(false) })
      .catch(error => { console.error('Erreur lors de la récupération de la configuration', error); });
  }, [sessionId, token]);

  const performFetch = async (url: string, setupData: SetupPanelState, action: string) => {
    setLoader(true)
    if (setupData && setupData.bu && setupData.period0 && setupData.period1 && !loadingConfig) {
      setLoadingConfig(true);
      const payload = JSON.stringify({
        "id": sessionId,
        "date_scope": setupData.dateScope,
        "period0": setupData.period0,
        "period1": setupData.period1,
        "business_unit": setupData.bu,
        "year_compared": parseInt(setupData.year),
        "level1": setupData.level1,
        "level2": setupData.level2,
        "level1_nb": setupData.level1_nb,
        "level2_nb": setupData.level2_nb,
        "section_ids": news?.ids,
        "section_id": setupData.section_id,
        "correction": setupData.correction,
        "language": setupData.language,
        "format_recoding": setupData.format_recoding
      });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: payload
        });

        if (action == "download") {
          try {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `cfr_newsletter_${sessionId}.docx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          } catch (error) {
            handleFetchError(error);
          }
        } else {
          const data = await response.json();

          if (action == "load_paragraph") {
            await handleLoadParagraph(data);
          } else if (action == "correct_paragraph") {
            if(news)
            setNews({...news, section: data.section});
            updateRemainingCredit(data.credit);
          } else {
            setNews(data)
          }
        }
      } catch (error) {
        handleFetchError(error);
      }

      setLoadingConfig(false);
      setLoader(false)
    }
  };

  async function handleLoadParagraph(data: any) {
    const resInfo = await fetch(`/api/custom_traffic_report/infos/${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!resInfo.ok) {
      throw new Error('Erreur lors de la récupération des infos');
    }

    const infoData = await resInfo.json();
    if(news)
    setNews({...news, section: infoData.section});

    updateRemainingCredit(infoData.credit);
  }

  function updateRemainingCredit(credit: number) {
    if (remainingCredit && credit) {
      const newCredit = remainingCredit - credit;
      setRemainingCredit(newCredit);
    }
  }

  function handleFetchError(error: unknown) {
    console.error('Erreur lors de la récupération de la configuration', error);
  }

  // Fonctions spécifiques
  const fetchDataForSubmit = async (setupData: SetupPanelState, action: string) => {
    const url = `/api/custom_traffic_report/${action}`;
    await performFetch(url, setupData, action);
  };

  return { config, loadingConfig, loader, setLoader, fetchDataForSubmit, news, setNews };
};