import React from "react";
import { useDropzone } from "react-dropzone";
import { PDFDocument } from 'pdf-lib';
 
interface FileDropzoneProps {
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  setAvailableOptions: React.Dispatch<React.SetStateAction<string[]>>;
  mapOpt: { [key: string]: string[] };
  fileName: string;
  docTypes: string[];
}
 
const FileDropzone: React.FC<FileDropzoneProps> = ({
  setFile,
  setFileName,
  setAvailableOptions,
  mapOpt,
  fileName,
  docTypes,
}) => {
  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0];
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts.pop()?.toLowerCase();
      const baseFileName = fileNameParts.join('.').replaceAll('.', '-');
      const modifiedFileName = `${baseFileName}.${fileExtension}`;
      file = new File([file], modifiedFileName, {type: file.type});
  
      setFile(file);
      setFileName(modifiedFileName);

      if (fileExtension && mapOpt[fileExtension]) {
        setAvailableOptions(mapOpt[fileExtension]);
      } else {
        setAvailableOptions([]);
      }
    }
  };  
 
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
    },
    onDrop,
    maxFiles: 1,
  });
 
  return (
    <div
      {...getRootProps()}
      className={`dropzone border-dashed border-2 border-gray-400 ${fileName && "border-text-primary-light"
        } rounded p-4 text-center cursor-pointer`}
    >
      <input {...getInputProps()} />
      <p>
      Drag and drop your file here, or click to select your file
      </p>
      <p className="text-sm text-gray-600 mt-2">
      Accepted file : {docTypes.map((type) => type.toUpperCase()).join(", ")}
      </p>
      {fileName && (
        <p className="text-sm text-text-primary-light mt-2">
          Selected file : {fileName}
        </p>
      )}
    </div>
  );
};
 
export default FileDropzone;
