import React, { ReactElement, useEffect, useState } from "react";
import { Ask, SelectedChat, SDHIntro, DisplayPrompt, ChatConversation, LoaderComponent, ChatConversationGPT, SelectedModelOptionGPT, TrafficReportViewer } from "../../components";
import { HomeAppPreview } from "../../components/HomeAppPreview";
import { ChatInput } from "../SecureGPT/ChatInput";
import { useAuth } from "../../providers";
import { messageGPT, useSecureGPTConfig, useSessionId } from "../../hooks";
import { useTMMConfig } from "../../hooks/tellMeMore";
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { SpecifyChatDropdown } from "../../components/Dropdown/SpecifyChatDropdown";
import { messageTMM } from "../../hooks/tellMeMore";
import { SHARE_ENV } from "worker_threads";
import { SelectedChatOptionTMM } from "../../components";
import { languageOptions } from "../Newsletter/Newsletter";
import { ThemePrompts } from "../../hooks";


type ChannelData = {
  messages: ReactElement[];
  history: string[];
};

type ChannelsMap<T extends string> = {
  [K in T]: ChannelData;
};

type LanguageCode = 'FR' | 'EN' | 'PT' | 'ES' | 'JA';

type QuestionTranslation = {
  [key in LanguageCode]: string;
};

export const availableModels: string[] = ['gpt', 'gpt4', 'mistral', 'gemini']

interface SecureChatProps {
  appName?: 'GPT' | 'SDH';
  prompt?: string;
  immediatelySendPrompt?: boolean;
  plugin?: string;
}


export const SecureChat: React.FC<SecureChatProps> = ({ appName, prompt, immediatelySendPrompt, plugin }) => {
  const { token } = useAuth()
  const sessionId = useSessionId(`/api/tell_me_more/clear_session`);
  const secureSessionId = useSessionId(`/api/securegpt/clear_session`);
  const { config, reloadPrompt, messages, ask, error, loadingTMM, availableTools, setTool, tool, setMessages, loadingTMMConfig, buTrafficTeport } = useTMMConfig(token, sessionId);
  const { conversations, setConversations, askGPTStream, errorGPT, loadingGPT, errorRequestGPT, contextGPT, setContext, languageGPT, fetchConfig, suggestedPrompts, resetChat } = useSecureGPTConfig(token, secureSessionId);
  const [app, setApp] = useState<"SDH">('SDH')
  const [currentModel, setCurrentModel] = useState<string>("gpt")

  const [isVisibleGPT, setIsVisibleGPT] = useState(false);
  const [isVisibleTMM, setIsVisibleTMM] = useState(false);

  const [shouldSendMessage, setShouldSendMessage] = useState(false);

  const handleChangeLanguage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    await fetchConfig(`/api/securegpt/change/config/${e.target.value}/${secureSessionId}`)
    await reloadPrompt()
  };

  const toggleVisibility = () => {
    setIsVisibleGPT(!isVisibleGPT);
  };

  const toggleVisibilityTMM = () => {
    setIsVisibleTMM(!isVisibleTMM);
  };

  const handleChangeTMMOptions = (value: string) => {
    setTool(value)
  }


  const questions = ["What is the AI?", "What is VINCI's core business?", "Help me to compose an email to...", "Help me to solve this problem: ..."]
  const questionTranslations: QuestionTranslation[] = [
    {
      EN: "What is the AI?",
      FR: "Qu'est-ce que l'IA ?",
      PT: "O que é a IA?",
      ES: "¿Qué es la IA?",
      JA: "AIとは何ですか？"
    },
    {
      EN: "What is VINCI's core business?",
      FR: "Quel est le cœur de métier de VINCI ?",
      PT: "Qual é o negócio principal da VINCI?",
      ES: "¿Cuál es el negocio principal de VINCI?",
      JA: "VINCIの主な事業は何ですか？"
    },
    {
      EN: "Help me to compose an email to...",
      FR: "Aidez-moi à rédiger un e-mail à...",
      PT: "Ajude-me a compor um email para...",
      ES: "Ayúdame a redactar un correo electrónico a...",
      JA: "...にメールを作成するのを手伝ってください。"
    },
    {
      EN: "Help me to solve this problem: ...",
      FR: "Aidez-moi à résoudre ce problème : ...",
      PT: "Ajude-me a resolver este problema: ...",
      ES: "Ayúdame a resolver este problema: ...",
      JA: "この問題を解決するのを手伝ってください：..."
    }
  ];

  const channelData: ChannelsMap<"SDH" | "GPT"> = {
    SDH: { messages: [], history: [] },
    GPT: { messages: [], history: [] },
  };

  const [input, setInput] = useState("")
  const [activePlugin, setActivePlugin] = useState<string>('')

  const getPluginTMM = (plugin: string): string => {
    const parts = plugin.split('-');
    return parts[0];
  };

  useEffect(() => {
    if (plugin) {
      setActivePlugin(getPluginTMM(plugin))
    }
  }, [plugin])


  useEffect(() => {
    if (activePlugin && activePlugin.length > 0) handleChangeTMMOptions(activePlugin)
  }, [activePlugin])


  const handleInputClick = () => {

  };

  useEffect(() => {
    if (appName === 'SDH') {
      setApp(appName);
    }
  }, [appName])

  useEffect(() => {
    if (immediatelySendPrompt && prompt && prompt.length > 0) {
      setShouldSendMessage(true);
      if (appName === "GPT") {
        setCurrentModel("gpt")
      }
    }
  }, [immediatelySendPrompt, prompt]);

  useEffect(() => {
    if (shouldSendMessage && config && prompt && prompt.length > 0) {
      if (appName === 'GPT') {
        askGPTStream(prompt, "gpt");
      } else if (appName === 'SDH') {
        ask(prompt);
      }
      setShouldSendMessage(false);
    }
  }, [shouldSendMessage, config, prompt, appName]);


  const postFeed = (text: string) => {
    ask(text)
  }

  const resetModelMessages = async (model: string) => {
    await resetChat(model)
    setConversations(prevConversations => ({
      ...prevConversations,
      [model]: [],
    }));
  };


  return (
    <LoaderComponent loadingConfig={config === null} >
      <div className={`flex flex-col h-full`}>
        <div className="flex flex-col w-full items-center justify-center">
          <SelectedChat app={app} setApp={setApp} language={languageGPT} handleChangeLanguage={handleChangeLanguage} />
          {app === 'SDH' && <SelectedChatOptionTMM loadingTMM={loadingTMM} plugin={activePlugin} setActivePlugin={setActivePlugin} availableTools={availableTools} loadingTMMConfig={loadingTMMConfig} />}
        </div>
        <div className="flex flex-grow max-h-full justify-center">
          {
            app == "SDH" ?
              <TellMeMore language={languageGPT} pluginActive={activePlugin} isVisible={isVisibleTMM} changeVisibility={toggleVisibilityTMM} availableTools={availableTools} loadingTMM={loadingTMM} messages={messages} config={config} reloadPrompt={reloadPrompt} ask={ask} buTrafficTeport={buTrafficTeport} /> :
              <GPT language={languageGPT} suggestedPrompts={suggestedPrompts} setContext={setContext} context={contextGPT} changeVisibility={toggleVisibility} isMenuVisible={isVisibleGPT} errorRequestGPT={errorRequestGPT} loadingGPT={loadingGPT} messages={conversations[currentModel]} ask={(t) => askGPTStream(t, currentModel)} questions={questionTranslations} setInput={setInput} currentModel={currentModel} />
          }
        </div>
        <div className="h-25">
          <ChatInput
            onSendMessage={postFeed}
            handleInputClick={handleInputClick}
            input={input}
            setInput={setInput}
            prompts={config ? config.suggested_prompts : []}
            reloadPrompt={reloadPrompt}
            ask={ask}
            loading={loadingGPT || loadingTMM}
            app={app}
            resetMessages={app === 'SDH' ? setMessages : () => resetModelMessages(currentModel!)}
            activePlugin={activePlugin}
          />
        </div>
      </div>
    </LoaderComponent>
  );
};

interface Tool {
  name: string;
  id: string;
}

interface TTMProps {
  messages: messageTMM[];
  ask: (text: string) => Promise<void>;
  config: any;
  reloadPrompt: () => Promise<void>;
  loadingTMM: boolean;
  availableTools: Tool[];
  isVisible: boolean;
  changeVisibility: () => void;
  pluginActive: string;
  plugin?: string
  language: LanguageCode
  buTrafficTeport: {}
}

export const TellMeMore = ({ language, messages, ask, config, reloadPrompt, loadingTMM, availableTools, isVisible, changeVisibility, pluginActive, plugin, buTrafficTeport }: TTMProps) => {
  return (

    <div className="flex-1 flex flex-col h-full items-center justify-center py-3 w-full">

      {
        messages.length == 0 ?
          <div className="flex-col">
            <SDHIntro language={language} />

            {
              pluginActive != "get_flash_news_traffic" ?
                <>
                  <DisplayPrompt plugin={pluginActive} prompts={config.suggested_prompts} reloadPrompt={reloadPrompt} ask={ask} refresh={false} loading={config && config.suggested_prompts && config.suggested_prompts.length == 0} />
                </>
                :
                <div className="flex gap-20 justify-center w-full">

                  <div className="flex flex-col gap-2 items-center">
                    <p className="text-sm text-[#4A4A4A] font-semibold">Weekly Traffic News</p>
                    <div className="flex gap-3">
                      {Object.entries(config.bu_traffic_report.weekly).map(([key, value]) => (
                        <div className="w-12">
                          <TrafficReportViewer text={value as string} bu={key} />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-center">
                    <p className="text-sm text-[#4A4A4A] font-semibold">Monthly Traffic News</p>
                    <div className="flex gap-3">
                      {Object.entries(config.bu_traffic_report.monthly).map(([key, value]) => (
                        <div className="w-12">
                          <TrafficReportViewer text={value as string} bu={key} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
            }
          </div>
          :
          <ChatConversation loadingTMM={loadingTMM} messages={messages} pluginActive={pluginActive} />
      }
    </div>

  )
}

interface GPTProps {
  messages: messageGPT[];
  ask: (text: string) => Promise<void>;
  questions: QuestionTranslation[];
  setInput: (text: string) => void;
  loadingGPT: boolean;
  errorRequestGPT: boolean;
  isMenuVisible: boolean;
  changeVisibility: () => void;
  context: string;
  setContext: (context: string) => Promise<null | undefined>;
  suggestedPrompts: ThemePrompts;
  language: LanguageCode;
  currentModel: string
}

export const GPT = ({ messages, ask, questions, setInput, loadingGPT, errorRequestGPT, isMenuVisible, changeVisibility, context, setContext, suggestedPrompts, language, currentModel }: GPTProps) => {
  const [openDropdown, setOpenDropdown] = useState<null | 'main' | 'specify'>(null);
  //const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const translations: any = {
    "FR": "Comment puis-je vous aider aujourd'hui ?",
    "EN": "How can I help you today?",
    "PT": "Como posso ajudá-lo(a) hoje?",
    "ES": "¿Cómo puedo ayudarte hoy?",
    "JA": "今日はどのようにお手伝いしましょうか？"
  }

  const modelIcon: any = {
    'gpt': 'GPT_CHAT_ICON_XL',
    'gpt4': 'GPT4_CHAT_ICON_XL',
    'mistral': 'MISTRAL_CHAT_ICON_XL',
    'gemini': 'GEMINI_CHAT_ICON_XL'
  }

  const toggleDropdown = (dropdownName: 'main' | 'specify') => {
    setOpenDropdown(prev => prev === dropdownName ? null : dropdownName);
  };
  return (
    <>
      <div className="flex-1 flex flex-col h-full items-center justify-center py-3 w-full relative">
        {isMenuVisible && (
          <div className="absolute w-[90%] h-[95%] bg-transparent backdrop-blur-md bg-opacity-90 flex flex-col items-center justify-center z-10 rounded-xl">
            <div className="flex flex-col gap-3 overflow-auto">
              <SpecifyChatDropdown setContext={setContext} context={context} isOpen={openDropdown === 'specify'} toggleOpen={() => toggleDropdown('specify')} />
              <Dropdown suggestedPrompts={suggestedPrompts} isOpen={openDropdown === 'main'} toggleOpen={() => toggleDropdown('main')} />
            </div>
            <button onClick={changeVisibility} className="m-5 p-2 pl-6 pr-6 text-black flex justify-between items-center rounded-lg shadow border border-gray-500 hover:border-red-500 hover:text-red-500 focus:outline-none">
              Close
            </button>
          </div>
        )}
        {
          messages.length == 0 ?

            <div className="flex-1 flex flex-col h-full max-w-full items-center justify-center py-8">
              {openDropdown === null &&
                <div className="flex flex-col items-center mb-12">

                  <ChatLogo icon={modelIcon[currentModel]} className={`${currentModel != "mistral" && "rounded-full"} mb-4`} />
                  <h4 className="text-2xl font-medium">{translations[language] ? translations[language] : "How can I help you today ?"}</h4>
                </div>}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4">
                {questions.map((question, i) => (
                  <button key={i} onClick={() => setInput(question[language])} className="border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal hover:shadow-xl">
                    <p>{question[language]}</p>
                  </button>
                ))}
              </div>
            </div>
            :
            <ChatConversationGPT errorRequestGPT={errorRequestGPT} loadingChat={loadingGPT} messages={messages} currentModel={currentModel} />
        }
      </div>
    </>

  )
}