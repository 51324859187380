import { useState, useEffect } from "react";
import { useAuth } from "../../providers";
import { ChangeEvent } from "react";
import { DropzoneExcel } from "./DropzoneExcel";

const excelOptions = [
    { label: "HR_template", value: "HR" },
    { label: "Website_template", value: "WEB" },
];

export const SentimentAnalysis = () => {
    const { token } = useAuth()
    const [visibleSection, setVisibleSection] = useState<number | null>(null);
    //const [excelOptions, setExcelOptions] = useState<string[]>((['VINCI1.xlsx', 'VINCI2.xlsx']))
    const [fileToDownload, setFileToDownload] = useState<string>('HR');
    const [excelUploadedSucces, setExcelUploadedSucces] = useState<boolean>(false)
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [rowNumbers, setRowNumbers] = useState<string>('');
    const [excelData, setExcelData] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<string>('0')
    const [totaColumns, setTotalColumns] = useState<string>('')
    const [maxTotalRows, setMaxTotalRows] = useState<number>(0)
    const [questionTemplate, setQuestionTemplate] = useState<string>("Analyze the sentiment of the statement {cell_content} within the specific context of the {column_name} theme, related to our business of selling products online'")
    const [context, setContext] = useState<string>("You are an expert in sentiment analysis scoring.\n- Assign a sentiment score from -1 (negative) to 1 (positive), ensuring that the score reflects the statement's direct relevance and impact on the theme.\n- If the statement is positive or negative but not related to the theme, assign a 0 score to indicate neutrality.\n- Focus on the thematic connection to ensure the score accurately represents sentiment concerning the specific theme, among all of these themes: {list_of_themes}.\n- ONLY answer with the score and nothing else.")
    const [loaderSubmit, setLoaderSubmit] = useState<boolean>(false)
    const [tooMuchRowsError, setTooMuchRowsError] = useState<boolean>(false)
    const [totalCells, setTotalCells] = useState<number>(0)

    const handleCellsChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setTotalCells(parseInt(e.target.value))
    }

    useEffect(()=>{
        if(excelData.length > 0){
            let totalCol = excelData[0].length - 1
            setTotalColumns(totalCol.toString())
        }
    },[excelData])

    useEffect(()=>{
        setTotalCells(parseInt(totalRows) * parseInt(totaColumns))
    },[totalRows,totaColumns])

    useEffect(()=>{
        if(totalCells > 300) setTooMuchRowsError(true)
        else setTooMuchRowsError(false)
    },[totalCells])


    const instructions = [
        {
            title: "Download and fill the template",
            content: "To get started, you can download the provided Excel template. The first column of your Excel file should contain the texts to be analyzed. Each column in your Excel file, except the first one, represents a theme for the sentiment analysis. The header of each column should clearly state the theme."
        },
        {
            title: "Upload your Excel file",
            content: "Once you have filled the template, upload the file. It should be in .xlsx format. The AI will perform sentiment analysis on the text data (from the first column) in the context of each theme."
        },
        {
            title: "Edit the question template and general instruction for the AI",
            content: "You can modify the question template and the general instruction for the AI in the setup section. The question template has two placeholders: \"{cell_content}\" and \"{column_name}\". \"{cell_content}\" will be replaced with the content of the cell being analyzed, and \"{column_name}\" will be replaced with the name of the column (theme) that the cell belongs to. The general instruction for the AI also has a placeholder: \"{list_of_themes}\". This will be replaced with a list of column names (themes) that are relevant to the analysis."
        },
        {
            title: "Set the number of rows to analyze",
            content: "You can set the number of rows to analyze from your data. The default is the total number of rows in your data."
        },
        {
            title: "Submit",
            content: "Click the 'Submit' button to start the sentiment analysis. The analysis might take some time depending on the size of your data and the number of themes."
        },
        {
            title: "Download the result",
            content: "After the analysis, you can download the result as an Excel file. The result will include the sentiment analysis for each theme."
        }
    ];


    const toggleSection = (index: number) => {
        setVisibleSection(prevState => (prevState === index ? null : index));
    };

    const handleChangeFileDownload = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFileToDownload(e.target.value);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const downloadFile = async () => {
        try {
            const response = await fetch(`/api/sentiment/template/${fileToDownload}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error("Erreur de telechargement.");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileToDownload == "HR" ? "HR_template.xlsx" : "Website_template.xlsx"}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);

            //onSuccess();
        } catch (error) {
            //onError(error);
            console.log(error);
        }
    };

    const generateSentiment = async () => {
        if(parseInt(totalRows) > maxTotalRows) return
        setLoaderSubmit(true)
        if (file) {
            const formData = new FormData();
            //console.log(questionTemplate, context, totalRows)
            formData.append("file", file);
            formData.append("question_template", questionTemplate);
            formData.append("context", context);
            formData.append("limit", totalRows);

            try {
                const response = await fetch(`/api/sentiment/document`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Erreur de telechargement.");
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `output.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);

                //onSuccess();
            } catch (error) {
                //onError(error);
                console.log(error);
            }
        }
        setLoaderSubmit(false)
    };

    return (
        <div className="flex flex-col items-center gap-5 pb-10">
            <div className="p-5 justify-center items-center text-center font-bold">
                <h1 className="text-2xl text-[#0D3D62] font-trendaSemiBold">Welcome to the Sentiment Analysis App</h1>
                <p className="text-xl text-[#0D3D62] font-trendaSemiBold">This application uses AI to perform sentiment analysis on your data.</p>
            </div>
            <div className="flex flex-col bg-white border rounded-md w-4/5 p-3 justify-center items-start">
                <h1 className="text-xl text-[#0D3D62] font-trendaSemiBold pl-4">How to Use:</h1>
                <div className="flex flex-col items-start p-4 gap-3 w-full">
                    {instructions.map((instruction, index) => (
                        <div key={index} className="bg-[#0D3D620D] p-4 rounded-md w-full" onClick={() => toggleSection(index)}>
                            <button className="flex items-center justify-between w-full text-left text-black">
                                <span><strong className="text-md">{(index + 1 + '.')}</strong>&nbsp;{instruction.title}</span>
                                <svg className={`transform transition-transform  ${visibleSection === index ? 'rotate-90' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            {visibleSection === index && (
                                <div className="mt-2 pl-4 pr-2 py-2 border-l-2 border-gray-200">
                                    <p>{instruction.content}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col gap-4 w-4/5 border border-gray-300 p-4 rounded-lg shadow-lg">
                <div className="flex flex-col">
                    <label className="text-md p-1">Excel File Template</label>
                    <select className="w-2/5 p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500 max-w-3xl" value={fileToDownload} onChange={handleChangeFileDownload}>
                        {excelOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <button onClick={() => downloadFile()} className="w-[20%] px-4 py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">Download</button>
            </div>
            <div className="flex flex-col gap-4 w-4/5 border border-gray-300 p-4 rounded-lg shadow-lg">
                <div className="flex flex-col">
                    <p>{excelUploadedSucces ? "Scroll for setup" : 'Please Upload an Excel File'}</p>
                </div>
                <div className="flex flex-col">
                    <DropzoneExcel onExcelParsed={setExcelData} setFile={setFile} setFileName={setFileName} fileName={fileName} setExcelUploadedSucces={setExcelUploadedSucces} setTotalRows={setTotalRows} setMaxTotalRows={setMaxTotalRows} />
                </div>
            </div>
            {excelUploadedSucces &&
                <div className="flex flex-col gap-4 w-4/5 border border-gray-300 p-4 rounded-lg shadow-lg ">
                    <button onClick={toggleDropdown} className="hover:text-emerald-500 text-gray-700 flex justify-between items-center w-full px-4 py-2 text-left bg-transparent rounded-lg shadow border border-gray-300 focus:outline-none">
                        <span className="w-full">Set Up&nbsp;</span>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={dropdownOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                        </svg>
                    </button>
                    <div className={`w-90 bg-transparent relative transition-all ease-in-out duration-500 overflow-hidden ${dropdownOpen ? 'border border-gray-300 rounded-lg' : ''} ${dropdownOpen ? 'max-h-150' : 'max-h-0'}`}>
                        <div className="w-full p-3 flex flex-col gap-4">
                            <div className="flex flex-col">
                                <label className="text-md p-1">Edit the question template:</label>
                                <textarea value={questionTemplate} onChange={(event) => setQuestionTemplate(event.target.value)} className="w-full h-20 p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" onInput={handleInput} />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-md p-1">Edit the general instruction for the AI:</label>
                                <textarea value={context} onChange={(event) => setContext(event.target.value)} className="w-full h-48 p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" onInput={handleInput} />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-md p-1">Choosen Excel File: {fileName}</label>
                                <div className="overflow-auto max-h-96">
                                    <table className="min-w-full">
                                        <tbody>
                                            {excelData.map((row, rowIndex) => (
                                                <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                                    {Object.values(row).map((value, valueIndex) => (
                                                        <td key={valueIndex} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                            {valueIndex === 0 && rowIndex !== 0 ? String(value).substring(0,50) + "..." : String(value)}                                                        
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-col w-2/5">
                                <label className="text-md p-1">Number of rows:</label>
                                <input className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500 resize-none"
                                    onChange={(e)=> setTotalRows(e.target.value)}
                                    value={totalRows}
                                    max={maxTotalRows}
                                    min={1}
                                    type="number"
                                />
                            </div>
                            <label className="text-md p-1">Number of columns: {totaColumns}</label>
                            <label className="text-md p-1">Number of requests (max: 300) : {totalCells ? totalCells : 0} </label>
                            <div className="flex flex-col">
                                {/* <button onClick={generateSentiment} disabled={loaderSubmit || tooMuchRowsError || totalRows.length === 0} className="cursor-pointer w-full py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{loaderSubmit ? 'Generating file...' : (tooMuchRowsError ? `Too many cells in file you have currently ${parseInt(totalRows) * parseInt(totaColumns)} cells (${parseInt(totalRows)} rows and ${parseInt(totaColumns)} column${parseInt(totaColumns) > 1 ? 's' : '' })` : 'Submit')}</button> */}
                                <button onClick={generateSentiment} disabled={loaderSubmit || tooMuchRowsError || totalRows.length === 0} className="cursor-pointer w-full py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{loaderSubmit ? 'Generating file...' : (tooMuchRowsError ? `Too many requests` : 'Submit')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}