import { SVGIcon } from "../../../assets";
import { useActiveTabs } from "../../../providers";
import { useChatGPT } from "../providers";
import { GoogleTranslatorIcon, PaperclipIcon } from "./Icon";



export const ChatGPTModalChoiceModal = ({ }: {}) => {
    const { gptModel, setModalStatus, gptConfig } = useChatGPT()
    const { returnFav } = useActiveTabs()

    const program = [{
        "title": "Multi Docs",
        "description": "Upload documents or URLs and extract data, including scanned files",
        "icon": <PaperclipIcon />,
        "onclick": () => { gptConfig.setMDModeActive(true); setModalStatus("MULTI_DOCS"); },
        "display": returnFav([7]).length > 0
    }, {
        "title": "Document Translator",
        "description": "Translates PDF, PPTX, and DOCX files into different languages",
        "icon": <GoogleTranslatorIcon />,
        "onclick": () => { setModalStatus("TRANSLATOR") },
        "display": returnFav([3]).length > 0
    }, {
        "title": "Sentiment analysis",
        "description": "Download XLSX files and perform sentiment analysis on their data.",
        "icon": <SVGIcon size="24px" icon="SENTIMENT_ANALYSIS_ICON" />,
        "onclick": () => { setModalStatus("SENTIMENT_ANALYSIS") },
        "display": returnFav([4]).length > 0
    }
    ]
    return (
        <div className="font-trendaSemiBold">
            <div className="flex items-center w-full m-1.5 p-2.5 text-sm cursor-pointer focus-visible:outline-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group relative focus-visible:bg-[#f5f5f5] rounded-md my-0 px-3 mx-2 radix-state-open:bg-[#f5f5f5] gap-2.5 py-3 !pr-3">
                <span className="text-sm text-gray-400">Functionalities</span>
            </div>
            {
                gptModel.chatModelOpt.map((e, i) => {
                    const title = e.title;
                    const isCurrentApp = e.id == gptModel.selectedChatModel;

                    return (
                        <div onClick={() => { if (gptConfig.mDModeActive || !isCurrentApp) { gptConfig.setMDModeActive(false); gptModel.setSelectedChatModel(e.id); setModalStatus("CLOSE"); } }}
                            className="flex items-center w-full text-sm cursor-pointer focus-visible:outline-0 hover:bg-gray-50 focus-visible:bg-[#f5f5f5] rounded-md py-2 px-6" >
                            <div className="flex grow items-center justify-between gap-2">
                                <div>
                                    <div className="flex items-center gap-3">
                                        <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center">
                                            {e.icon}
                                        </span>
                                        <div>
                                            {title}
                                            <div className="text-[#7d7d7d] text-xs">{e.description}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center">
                                    {
                                        !gptConfig.mDModeActive && gptModel.selectedChatModel == e.id &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" className="icon-md"><path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242" clipRule="evenodd"></path></svg>
                                    }
                                </span>

                            </div>
                        </div>
                    );
                })
            }
            {
                program.map((p, i) => {
                    if (!p.display)
                        return <></>
                    return (
                        <div onClick={p.onclick}
                            className="flex items-center w-full text-sm cursor-pointer focus-visible:outline-0 hover:bg-gray-50 focus-visible:bg-[#f5f5f5] rounded-md gap-2.5 py-3 px-6" >
                            <div className="flex grow items-center justify-between gap-2">
                                <div>
                                    <div className="flex items-center gap-3">
                                        <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center">
                                            {p.icon}
                                        </span>
                                        <div>
                                            {p.title}
                                            <div className="text-[#7d7d7d] text-xs">{p.description}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center">
                                    {
                                        gptConfig.mDModeActive && p.title === "Multi Docs" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" className="icon-md"><path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242" clipRule="evenodd"></path></svg>
                                    }
                                </span>

                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}