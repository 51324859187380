import { useState,useEffect } from "react";
import { useCredit } from "../providers";


export interface ConfigOAG {
    vinci_airports: string[];
    competitor_airports: {
        [airportCode: string]: string[];
    };
    airport_recoding: {
        [airportCode: string]: string;
    };
    languages: {
        [isoCode: string]: string;
    };
    months: {
        [week: string]: string;
    }
}


export const useOAGConfig = (token: string| null, sessionId: string) => {
    const [loadingConfig, setLoadingConfig] = useState<boolean>(true)
    const [config, setConfig] = useState<ConfigOAG | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [analysis, setAnalysis] = useState<string>('')
    const { remainingCredit, setRemainingCredit } = useCredit()

    useEffect(()=>{
        if (token === null || sessionId === "") {
            setConfig(null);
            return;
        }
        fetch(`/api/competition_analyst/config/${sessionId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
            }
            return response.json()
        })
        .then(data => { setConfig(data); setLoadingConfig(false) })
        .catch(error => { console.error('Erreur lors de la récupération de la configuration', error); });

    },[sessionId,token])

    const analyse = async(sessionId: string,airportVinci: string,airportCompetitor: string,refMin: string,refMax:string,language:string) =>{
        setLoading(true)
        const response = await fetch('/api/competition_analyst/analyze',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
            body: JSON.stringify({
                id: sessionId,
                airport_vinci: airportVinci,
                airport_competitor: airportCompetitor,
                reference_period_min: refMin,
                reference_period_max: refMax,
                language,
            })

        })
        if(response.ok){
            const responseData = await response.json()
            setAnalysis(responseData.answer)
            if (remainingCredit && responseData.credit) {
                const new_credit = remainingCredit - responseData.credit
                setRemainingCredit(new_credit)
            }
        }

        setLoading(false)
    }

    return {loadingConfig,loading, config,analysis,setAnalysis,analyse}
}