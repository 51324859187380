import { useTheme } from "../../providers";
import { MainTemplate } from "../../templates";

export const Main = (props: {
  isSidebarOpen: boolean;
  layoutSize: { open: string; close: string };
}) => {
  const { isSidebarOpen, layoutSize } = props;
  const { appTheme } = useTheme();

  return (
    <main
      className={`flex h-full max-w-full overflow-hidden flex-col flex-1 ${
        isSidebarOpen ? layoutSize.open : layoutSize.close
      } ${appTheme === "dark" ? "bg-background-dark" : "bg-background-light"}`}
    >
      
      <MainTemplate />
    </main>
  );
};
