import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";

interface AskProps {
    text: string;
}

export const Ask: React.FC<AskProps> = ({ text }) => {
    return (
        <div className="flex items-start mb-3">
            <div className="flex items-start">
                <ChatLogo icon="USER_CHAT_ICON" />
            </div>
            <p className="text-sm pl-3 text-[#4A4A4A] mt-2">{text}</p>
        </div>
    );
};


