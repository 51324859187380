import { useChatGPT } from "../providers";

export const languageOptions = [
    { label: "English", value: "EN" },
    { label: "Spanish", value: "ES" },
    { label: "French", value: "FR" },
    { label: "Portuguese", value: "PT" },
    { label: "Japanese", value: "JA" }
];

export const ChatGPTLanguage = ({ }) => {
    const { gptConfig } = useChatGPT()

    const handleChangeLanguage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await gptConfig.fetchConfig(`/api/secured_gpt/change/config/${e.target.value}`)
    };

    return (
        <select className="p-1.5 text-black bg-transparent rounded-xl shadow border border-gray-200 focus:outline-none cursor-pointer w-full" value={gptConfig.languageGPT} onChange={handleChangeLanguage}>
            {languageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    )
}