import { defaultStyles, FileIcon } from "react-file-icon";
import { useMultiDocs } from "../../providers/MultiDocsProvider";
import { useState } from "react";

const instructions = [
    {
        title: "Download and fill the template",
        content: "To get started, you can download the provided Excel template. The first column of your Excel file should contain the texts to be analyzed. Each column in your Excel file, except the first one, represents a theme for the sentiment analysis. The header of each column should clearly state the theme."
    },
    {
        title: "Upload your Excel file",
        content: "Once you have filled the template, upload the file. It should be in .xlsx format. The AI will perform sentiment analysis on the text data (from the first column) in the context of each theme."
    },
    {
        title: "Edit the question template and general instruction for the AI",
        content: "You can modify the question template and the general instruction for the AI in the setup section. The question template has two placeholders: \"{cell_content}\" and \"{column_name}\". \"{cell_content}\" will be replaced with the content of the cell being analyzed, and \"{column_name}\" will be replaced with the name of the column (theme) that the cell belongs to. The general instruction for the AI also has a placeholder: \"{list_of_themes}\". This will be replaced with a list of column names (themes) that are relevant to the analysis."
    },
    {
        title: "Set the number of rows to analyze",
        content: "You can set the number of rows to analyze from your data. The default is the total number of rows in your data."
    },
    {
        title: "Submit",
        content: "Click the 'Submit' button to start the sentiment analysis. The analysis might take some time depending on the size of your data and the number of themes."
    },
    {
        title: "Download the result",
        content: "After the analysis, you can download the result as an Excel file. The result will include the sentiment analysis for each theme."
    }
];


export const Instructions = ({ }) => {
    const [visibleSection, setVisibleSection] = useState<number | null>(null);
    const toggleSection = (index: number) => {
        setVisibleSection(prevState => (prevState === index ? null : index));
    };
    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full bg-white justify-center rounded-xl gap-2.5">
                <div className="flex flex-col justify-center items-start">
                    <h1 className="text-lg text-text-primary-light font-trendaSemiBold pb-2">How to Use:</h1>
                    <div className="flex flex-col items-start gap-3 w-full">
                        {instructions.map((instruction, index) => (
                            <div key={index} className={`border border-secondary-light ${visibleSection !== index && "hover:bg-secondary-light"} p-2 py-3 rounded-md w-full`} onClick={() => toggleSection(index)}>
                                <button className="flex items-center justify-between w-full text-left text-black">
                                    <span><strong className="text-md">{(index + 1 + '.')}</strong>&nbsp;{instruction.title}</span>
                                    <svg className={`transform transition-transform  ${visibleSection === index ? 'rotate-90' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                {visibleSection === index && (
                                    <div className="p-2">
                                        <p className="text-justify">{instruction.content}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}