import { SelectOptions } from "../DocumentTranslator/SelectedOptions"

interface CFRSelectOptions {
    entries: string[] | {
        [key: string]: string;
    };
    defaultValue: string;
    firstIndex: number;
    secondIndex: number;
    value: string | null;
    setValue: (value: string | null) => void;
}

export const CFRSelect = ({ entries, defaultValue, firstIndex, secondIndex, value, setValue }: CFRSelectOptions) => {
    return (
        <SelectOptions
            defaultValue={defaultValue}
            options={Object.entries(entries)}
            renderOption={(id) => ({
                value: id[firstIndex],
                label: id[secondIndex],
            })}
            selectedValue={value ? value : ""}
            onChange={(e) => setValue(e.target.value)}
        />
    )
}