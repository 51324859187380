import React from "react";
import { useState, useEffect } from "react";
import DropzoneLargeDoc from "./DropzoneLargeDoc";
import { LoaderCircle } from "../../components";
import { useSessionId } from "../../hooks";
import { useAuth, useCredit } from "../../providers";
import { SVGIcon } from "../../assets";
import "./LargeDoc.css"
import { ChatInput } from "../SecureGPT/ChatInput";
import { AskConversationProps } from "./ChatConversationLargeDoc";
import { DocumentDropdown, DocumentDropdownGeneral, DocumentDisplay } from "./Documents";

interface UploadedDocRes {
    [key: string]: string;
}


export const LargeDoc = () => {
    // const [dropdownOpen, setDropdownOpen] = useState<boolean>(true)
    const [files, setFiles] = useState<File[] | []>([]);
    const [urlDoc, setUrlDoc] = useState<string>('')
    const [summaryChecked, setSummaryChecked] = useState<boolean>(true)
    const [ocrChecked, setOCRChecked] = useState<boolean>(false)
    const [uploadLoader, setUploadLoader] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
    const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocRes>({})
    const sessionId = useSessionId(`/api/multi_doc/clear_session`);
    const [showDropzone, setShowDropzone] = useState<boolean>(true)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [messages, setMessages] = useState<AskConversationProps[]>([]);
    const [input, setInput] = useState<string>('');
    const [resToQuestion, setResToQuestion] = useState(null)
    const [loaderAsk, setLoaderAsk] = useState(false)
    // const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [openDropdown, setOpenDropdown] = useState<number | 'general' | null>(null);
    const { token } = useAuth()
    const [triggerReset, setTriggerReset] = useState<boolean>(false)
    const [triggerResetDoc, setTriggerResetDoc] = useState<boolean>(false)
    const [currentlyUploading, setCurrentlyUploading] = useState<number>(0)
    const [loaderLongUpload, setLoaderLongUpload] = useState<boolean>(false)
    const [fileUploadError, setFileUploadError] = useState<string[]>([])
    const [totalFileNumber, setTotalFileNumber] = useState<number>(0)
    const { remainingCredit, setRemainingCredit } = useCredit()

    const resetAll = () => {
        setTriggerReset(true)
    }

    const resetAllDoc = () => {
        setTriggerResetDoc(true)
    }


    useEffect(() => {
        if (triggerResetDoc) {
            setMessages([])
            setFiles([])
            setOpenDropdown('general')
            setUploadSuccess(false)
            setUploadedDocuments({})
            setInput('')
            setTotalFileNumber(0)
            setLoaderLongUpload(false)
            setUploadLoader(false)
            setSelectedFiles([])

            fetch(`/api/multi_doc/clear_session/${sessionId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .catch(error => console.error('Erreur lors du nettoyage de la session', error));
        }
    }, [triggerResetDoc])

    useEffect(() => {
        if (triggerReset) {
            setMessages([])
            // setFiles([])
            setOpenDropdown('general')
            // setUploadSuccess(false)
            // setUploadedDocuments({})
            setInput('')

            // fetch(`/api/multi_doc/clear_session/${sessionId}`, {
            //     method: 'DELETE',
            //     headers: {
            //       'Authorization': `Bearer ${token}`
            //     }
            //   })
            // .catch(error => console.error('Erreur lors du nettoyage de la session', error));
        }
    }, [triggerReset])


    const toggleDropdown = (identifier: number | 'general') => {
        if (openDropdown === identifier) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(identifier);
        }
    };

    const handleSubmitGeneral = async () => {
        if (loaderAsk) return;
        if (input.length === 0 || Object.entries(uploadedDocuments).length === 0) return
        if (openDropdown !== 'general') toggleDropdown('general')
        setLoaderAsk(true)
        setMessages(prevMessages => [...prevMessages, {
            type: 'Ask',
            text: input
        }])
        setInput('')
        try {
            const response = await fetch(
                `/api/multi_doc/ask_question`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: sessionId,
                        question: input,
                    })
                }
            );

            const data = await response.json()
            setResToQuestion(data.answer.answer)
            setLoaderAsk(false)
            setMessages(prevMessages => [...prevMessages, {
                type: 'Answer',
                text: data.answer.answer
            }])

        } catch (error) {
            console.log(error)
            setLoaderAsk(false)
            setInput('')
        }
        return;
    }

    const removeFile = (fileName: string) => {
        if (loaderLongUpload) return;
        const updatedFiles = selectedFiles.filter(file => file.name !== fileName);
        setSelectedFiles(updatedFiles);
        setFiles(updatedFiles);
    };

    useEffect(() => {
        //console.log(fileUploadError)
        if (fileUploadError && fileUploadError.length > 0) {
            setLoaderLongUpload(false)
            //setCurrentlyUploading(0)
        }
    }, [fileUploadError])

    const handleUpload = async () => {
        if (loaderLongUpload) return;
        if (files.length === 0) return;
        setUploadSuccess(false);
        setUploadLoader(true);
        setLoaderLongUpload(true)

        let uploadCount = 0;

        files.forEach((file) => {
            const formData = new FormData();
            setTotalFileNumber(prev => prev + 1)
            formData.append("files_present", files.length > 0 ? "true" : "false");
            formData.append('files', file);
            formData.append("session_id", sessionId);
            formData.append("gen_summary", String(summaryChecked));
            formData.append("use_extractor_ocr", String(ocrChecked));
            if (urlDoc) formData.append("url", urlDoc);

            fetch(`/api/multi_doc/upload_documents`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            })
                .then(response => {
                    //setFileUploadError([...fileUploadError!, file.name]) //testing
                    if (!response.ok) {
                        if (response.status === 504) {
                            setFileUploadError([...fileUploadError!, file.name])
                            setTotalFileNumber(prev => prev - 1)
                            //throw new Error(`Timeout occurred while uploading file ${file.name}`);
                        }
                        return
                    }
                    return response.json();
                })
                .then(data => {
                    setUploadedDocuments(prevDocs => ({
                        ...prevDocs,
                        ...data.docs_info
                    }));
                    if (remainingCredit && data.credit) {
                        const new_credit = remainingCredit - data.credit
                        setRemainingCredit(new_credit)
                    }
                })
                .catch(error => {
                    console.error("Error uploading file:", error);
                })
                .finally(() => {
                    setUploadSuccess(true);
                    setTimeout(() => {
                        setUploadLoader(false);
                    }, 2000);
                    uploadCount++;
                    setSelectedFiles([]);
                    if (uploadCount === files.length) {
                        setLoaderLongUpload(false)
                        //setFiles([])
                    }
                });
        });
    };

    useEffect(() => {
        setCurrentlyUploading(totalFileNumber - Object.entries(uploadedDocuments).length)
    }, [uploadedDocuments, totalFileNumber])


    return (
        <div className="relative flex flex-col items-center pb-10 h-full">
            <div className="relative flex flex-col items-start justify-start p-10 w-full gap-4">
                <div className="justify-start items-start text-center font-bold">
                    <h1 className="text-2xl text-[#0D3D62] font-trendaSemiBold"> Multi Documents Q<span className="font-sans font-medium">&amp;</span>A</h1>
                </div>
                <div className="flex flex-row w-full gap-4">
                    {showDropzone ?
                        <>
                            <div className="flex flex-col w-full">
                                <DropzoneLargeDoc loaderLong={loaderLongUpload} setFiles={setFiles} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                            </div>
                            <div onClick={() => setShowDropzone(!showDropzone)} className="cursor-pointer flex items-center justify-center bg-white px-3 rounded-xl border border-[#CFCFCF]">
                                <SVGIcon size="20px" icon="LINKS_ICON" />
                            </div>
                        </>
                        :
                        <>
                            <div onClick={() => setShowDropzone(!showDropzone)} className=" cursor-pointer flex items-center justify-center bg-white px-3 rounded-xl border border-[#CFCFCF]">
                                <SVGIcon size="20px" icon="UPLOAD_ICON" />
                            </div>
                            <div className="flex flex-col w-full bg-white p-3.5 border border-[#CFCFCF] justify-center rounded-xl">
                                <input placeholder="URL of the document" className="placeholder-gray-custom p-3 bg-[#F6F6F6] border-gray-400 rounded-xl focus:outline-none" type="text" value={urlDoc} onChange={(e) => setUrlDoc(e.target.value)} />
                            </div>
                        </>
                    }
                    <div className="flex flex-col w-4/11 justify-between items-start gap-3">
                        <div className="flex flex-row justify-center gap-3">
                            <div onClick={() => setSummaryChecked(!summaryChecked)} className="relative cursor-pointer">
                                <input
                                    id="summaryRadio"
                                    className="opacity-0 absolute h-5 w-5  cursor-pointer"
                                    type="radio"
                                    name="summary"
                                    checked={summaryChecked}
                                />
                                <div className=" cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded cursor-pointer">
                                    {summaryChecked && (
                                        <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    )}
                                </div>
                            </div>
                            <label htmlFor="summaryRadio" className="text-sm cursor-pointer">Generate document titles and summaries</label>
                        </div>
                        <div className="flex flex-row justify-center gap-3">
                            <div onClick={() => setOCRChecked(!ocrChecked)} className="relative cursor-pointer">
                                <input
                                    id="ocrRadio"
                                    className="opacity-0 absolute h-5 w-5  cursor-pointer"
                                    type="radio"
                                    name="ocr"
                                    checked={ocrChecked}
                                />
                                <div className=" cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded cursor-pointer">
                                    {ocrChecked && (
                                        <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    )}
                                </div>
                            </div>
                            <label htmlFor="ocrRadio" className="text-sm cursor-pointer">Use OCR</label>
                        </div>
                        <div className="flex flex-row justify-center w-full h-full overflow-hidden">
                            <button disabled={uploadLoader || (files.length === 0 && urlDoc.length === 0) || loaderLongUpload} onClick={handleUpload} className="cursor-pointer flex items-center justify-center gap-3 w-full text-sm px-4 py-1 bg-white text-black rounded-lg border border-[#CFCFCF] hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">Upload documents{!uploadLoader && <SVGIcon size="20px" icon="ARROW_SMALL_ICON" />}</button>
                            {uploadLoader &&
                                <div className="flex items-center justify-center scale-50">
                                    <LoaderCircle uploadSuccess={uploadSuccess} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {files.length > 0 && loaderLongUpload && <p className="text-sm">Currently Uploading {currentlyUploading} file{currentlyUploading > 1 ? 's' : ''}..</p>}
                {fileUploadError && fileUploadError.length > 0 && !loaderLongUpload &&
                    <>
                        <p className="text-sm">A Timeout occurred for the following {fileUploadError.length > 1 ? 'files' : 'file'}. Please try with {fileUploadError.length > 1 ? '' : 'a'} smaller {fileUploadError.length > 1 ? 'files' : 'file'}:</p>
                        <ol className="text-sm">
                            {fileUploadError?.map((error, index) => (
                                <li key={index}>•{'\u00A0'}{error}</li>
                            ))}
                        </ol>
                    </>
                }
                <div className="flex flex-col w-full mt-4 gap-5">
                    {selectedFiles.map((file) => (
                        <DocumentDisplay fileName={file.name} onRemove={() => removeFile(file.name)} />
                    ))}
                </div>
                <div className="w-full overflow-auto flex flex-col gap-5 min-h-[420px] scrollbar-hide">
                    {uploadSuccess && uploadedDocuments && (
                        Object.entries(uploadedDocuments).map(([fileName, summary], index) => (
                            <DocumentDropdown key={index} triggerResetDoc={triggerResetDoc} setResetTriggerDoc={setTriggerResetDoc} resetTrigger={triggerReset} setResetTrigger={setTriggerReset} summary={summary || ''} fileName={fileName} token={token!} session_id={sessionId!} isOpen={openDropdown === index} toggleDropdown={() => toggleDropdown(index)} index={index} />
                        ))
                    )}
                    {messages.length > 0 &&
                        uploadSuccess && uploadedDocuments && (
                            <DocumentDropdownGeneral messages={messages} summary="" token={token!} session_id={sessionId!} isOpen={openDropdown === 'general'} toggleDropdown={() => toggleDropdown('general')} />
                        )}
                </div>
            </div>
            <div className="w-full">
                <ChatInput resetDoc={true} setResetDoc={setTriggerResetDoc} disabled={Object.entries(uploadedDocuments).length === 0} placeHolderMess="Ask something general about your documents.." resetText="Reset Q&A" resetMessages={resetAll} onSendMessage={handleSubmitGeneral} handleInputClick={() => { }} input={input} setInput={setInput} loading={loaderAsk} />
            </div>
        </div>
    )
}