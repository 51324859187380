import React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import { TypeToIcon } from '../components';
import { useCredit } from '../providers';
import { templatePrompts } from '../utils/sdhDataTemplate';

export type messageGPT = {
    type: string;
    props: {
        type: keyof typeof TypeToIcon;
        text: string;
        typed: boolean;
        loading?: boolean;
    };
}

interface PromptDefinition {
    definition: string;
    prompt_template: string;
}

export interface ThemePrompts {
    [theme: string]: {
        [key: string]: PromptDefinition;
    };
}

export type LanguageCode = 'FR' | 'EN' | 'PT' | 'ES' | 'JA';

const modelName: any = {
    'gpt': 'GPT',
    'gpt4': 'GPT4',
    'mistral': 'MISTRAL',
    'gemini': 'GEMINI'
}

export const useSecureGPTConfig = (token: string | null, sessionId: string) => {
    const [answer, setAnswer] = useState<string | null>(null);
    const [errorGPT, setErrorGPT] = useState<Error | null>(null);
    const { remainingCredit, setRemainingCredit } = useCredit()
    const [loadingGPT, setLoadingGPT] = useState<boolean>(false); //loader
    const [errorRequestGPT, setErrorRequestChpt] = useState<boolean>(false)
    const [contextGPT, setContextGPT] = useState<string>('')
    const [contextGPTLoader, setContextGPTLoader] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null);
    const [suggestedPrompts, setSuggestedPrompts] = useState<ThemePrompts>(templatePrompts);
    const [languageGPT, setLanguageGPT] = useState<LanguageCode>('EN')
    const [conversations, setConversations] = useState<{ [key: string]: messageGPT[] }>({ 'gpt': [], 'gpt4': [], 'mistral': [], 'gemini': [] });

    const askGPTStream = async (text: string, model: string) => {
        setLoadingGPT(true);
        setConversations(prevConversations => ({
            ...prevConversations,
            [model]: [
                ...(prevConversations[model] || []),
                {
                    type: 'Ask',
                    props: {
                        type: 'GPT4',
                        text: text,
                        typed: false,
                    },
                }
            ]
        }));
        try {
            const response = await fetch(`/api/securegpt/askstream`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({
                    id: sessionId,
                    message: text,
                    model: model
                }),
            });
            if (response.ok && response.body) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let accumulatedText = '';

                // Ajoute une réponse initiale vide pour ce modèle
                setConversations(prevConversations => ({
                    ...prevConversations,
                    [model]: [
                        ...prevConversations[model],
                        {
                            type: 'TextAnswer',
                            props: {
                                type: modelName[model],
                                text: accumulatedText,
                                typed: false,
                            },
                        }
                    ]
                }));
                setLoadingGPT(false);
                let last = 0;
                while (!done && last != 1) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    if (value) {
                        const chunk = decoder.decode(value, { stream: true });
                        if (chunk.startsWith("credit_event_stream_vinci_sdh")) {
                            const creditValue = parseFloat(chunk.split(": ")[1]);
                            console.log(`Nombre de crédits: ${creditValue}`);
                            if (remainingCredit && creditValue) {
                                const new_credit = remainingCredit - creditValue
                                setRemainingCredit(new_credit)
                            }
                        } else {
                            accumulatedText += chunk;
                        }
                    }
                    if (done)
                        last = 1
                    // Met à jour le state avec le texte accumulé
                    setConversations(prevConversations => ({
                        ...prevConversations,
                        [model]: [
                            ...prevConversations[model].slice(0, -1),  // Enlève le dernier élément
                            {
                                type: 'TextAnswer',
                                props: {
                                    type: modelName[model],
                                    text: accumulatedText,
                                    typed: false,
                                    loading: !done
                                },
                            }
                        ]
                    }));
                }

                setLoadingGPT(false);
            }
            else {
                setErrorRequestChpt(true)
                setConversations(prevConversations => ({
                    ...prevConversations,
                    [model]: [
                        ...prevConversations[model],
                        {
                            type: 'TextAnswerError',
                            props: {
                                type: modelName[model],
                                text: "An Error has occurred",
                                typed: false,
                            }
                        }
                    ]
                }));

                setLoadingGPT(false)
            }
        } catch (error) {
            setLoadingGPT(false)
        }
    };

    const askGPT = async (text: string, model: string) => {
        setLoadingGPT(true);
        setConversations(prevConversations => ({
            ...prevConversations,
            [model]: [
                ...(prevConversations[model] || []),
                {
                    type: 'Ask',
                    props: {
                        type: modelName[model],
                        text: text,
                        typed: false,
                    },
                }
            ]
        }));
        const response = await fetch(`/api/securegpt/ask`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify({
                id: sessionId,
                message: text,
                model: model
            }),
        });
        if (response.ok) {
            const responseData = await response.json()
            setConversations(prevConversations => ({
                ...prevConversations,
                [model]: [
                    ...prevConversations[model],
                    {
                        type: 'TextAnswer',
                        props: {
                            type: modelName[model],
                            text: responseData.answer,
                            typed: false,
                        },
                    }
                ]
            }));

            if (remainingCredit && responseData.credit) {
                const new_credit = remainingCredit - responseData.credit
                setRemainingCredit(new_credit)
            }

            setLoadingGPT(false);
        }
        else {
            setErrorRequestChpt(true)
            setConversations(prevConversations => ({
                ...prevConversations,
                [model]: [
                    ...prevConversations[model],
                    {
                        type: 'TextAnswerError',
                        props: {
                            type: modelName[model],
                            text: "An Error has occurred",
                            typed: false,
                        }
                    }
                ]
            }));

            setLoadingGPT(false)
        }
    };

    const setContext = async (context: string) => {
        setContextGPTLoader(true)
        try {
            const response = await fetch('/api/securegpt/context', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({
                    id: sessionId,
                    context: context,
                }),
            })

            const data = await response.json()
            setContextGPT(data.context)
            setContextGPTLoader(false)
        }
        catch (error) {
            console.log(error)
            setContextGPTLoader(false)
            return null
        }
    }

    const resetChat = async (model: string) => {
        try {
            const response = await fetch('/api/securegpt/reset_chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({
                    id: sessionId,
                    model: model,
                }),
            })
        }
        catch (error) {
            console.log(error)
            return null
        }
    }

    const fetchConfig = async (url: string) => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            setContextGPT(data.context)
            setSuggestedPrompts(data.suggested_prompts)
            setLanguageGPT(data.language)

        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };


    useEffect(() => {
        if (token === null || sessionId === "") {
            setErrorGPT(null);
            return;
        }
        fetchConfig(`/api/securegpt/config/${sessionId}`);
    }, [token, sessionId]);

    return { conversations, setConversations, askGPT, errorGPT, loadingGPT, errorRequestGPT, setContext, contextGPTLoader, suggestedPrompts, contextGPT, error, languageGPT, fetchConfig, askGPTStream, resetChat };
};

