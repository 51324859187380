// SVGIcon.tsx
import React from "react";
import { SVGIcons } from "./icons";

export type SVGIconsType = keyof typeof SVGIcons;

interface SVGIconProps {
  icon: SVGIconsType;
  size?: string;
  className?: string;
  wrapper?: string;
  fill?: string;
}

export const SVGIcon: React.FC<SVGIconProps> = ({
  icon,
  size = "24px",
  className,
  wrapper,
  fill = "#686868",
}) => {
  const IconComponent = SVGIcons[icon];
  const style = {
    width: size,
    fill: fill,
  };
  if (wrapper) {
    return (
      <div className={wrapper}>
        <IconComponent style={style} className={className} />
      </div>
    )
  } else {
    return (
      <IconComponent style={style} className={className} />
    )
  }

};
