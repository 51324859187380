import React, { useState } from 'react';
import PptxGenJS from 'pptxgenjs';

export const ScreenCapture: React.FC = () => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [screenshots, setScreenshots] = useState<Blob[]>([]);

  const startScreenCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "always" } as any,
        audio: false
      });
      setMediaStream(stream);
    } catch(err) {
      console.error("Error starting screen capture:", err);
    }
  };

  const takeScreenshot = () => {
    if (!mediaStream) return;

    const video = document.createElement('video');
    video.srcObject = mediaStream;
    video.play();

    video.onloadedmetadata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            setScreenshots(prev => [...prev, blob]);
          }
        }, 'image/png');
      }
    };
  };

  const downloadScreenshotsAsPPTX = async () => {
    let pptx = new PptxGenJS();

    const targetWidthInches = 10; // 720pt / 72
    const targetHeightInches = 5.625; // 405pt / 72

    for (const screenshot of screenshots) {
        const dataUrl = await new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target?.result as string);
            };
            reader.onerror = (error) => {
                console.error("Error converting screenshot to data URL:", error);
                resolve('');
            };
            reader.readAsDataURL(screenshot);
        });

        let slide = pptx.addSlide();

        slide.addImage({
            data: dataUrl,
            x: 0,
            y: 0,
            w: targetWidthInches,
            h: targetHeightInches
        });
    }

    pptx.writeFile({ fileName: "Screenshots.pptx" });
  };

  const stopScreenCapture = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach(track => track.stop());
    setMediaStream(null);
    setScreenshots([])
  };

  return (
    <div className='flex flex-col w-full items-center justify-center gap-3 pb-3'>
      {!mediaStream ? (
        <button onClick={startScreenCapture} className="cursor-pointer flex flex-row text-sm text-[#0D3D62] p-2 border border-[#CFCFCF80] rounded-lg justify-center items-center hover:shadow-xl">Start Screen Capture</button>
      ) : (
        <>
          <button onClick={takeScreenshot} className="cursor-pointer flex flex-row text-sm text-[#0D3D62] p-2 border border-[#CFCFCF80] rounded-lg justify-center items-center hover:shadow-xl">Take Screenshot ({screenshots.length})</button>
          {screenshots.length > 0 && <button onClick={downloadScreenshotsAsPPTX} className="cursor-pointer flex flex-row text-sm text-green-600 p-2 border border-green-600 rounded-lg justify-center items-center hover:shadow-xl" disabled={screenshots.length === 0}>Download PPTX</button>}
          <button onClick={stopScreenCapture} className="cursor-pointer flex flex-row text-sm text-red-600 p-2 border border-red-600 rounded-lg justify-center items-center hover:shadow-xl">Stop Screen Capture</button>
        </>
      )}
    </div>
  );
};

export default ScreenCapture;

