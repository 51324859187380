import { ReactElement } from "react";
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";
import { LanguageCode } from "../../hooks";

type QuestionTranslation = {
    [key in LanguageCode]: ReactElement<any, any>;
};
const messageTranslations: QuestionTranslation = {
    EN: <>
        <p className="text-sm pl-3 text-[#4A4A4A]">I'm a secure AI able to answer your questions. I am also linked to the data in <span className="font-semibold">VINCI Airports</span>' Smart Data Hub.</p>
        <p className="text-sm pl-3 text-[#4A4A4A]">Start by asking me something or choose a suggestion ! 😊</p>
    </>,
    FR: <>
        <p className="text-sm pl-3 text-[#4A4A4A]">Je suis une IA sécurisée capable de répondre à vos questions. Je suis également connecté aux données du Smart Data Hub des <span className="font-semibold">aéroports VINCI</span>.</p>
        <p className="text-sm pl-3 text-[#4A4A4A]">Commencez par me poser une question ou choisissez une suggestion ! 😊</p>
    </>,
    PT: <>
        <p className="text-sm pl-3 text-[#4A4A4A]">Sou uma IA segura capaz de responder às suas perguntas. Estou também ligada aos dados do Smart Data Hub da <span className="font-semibold">VINCI Airports</span>.</p>
        <p className="text-sm pl-3 text-[#4A4A4A]">Comece por me fazer uma pergunta ou escolha uma sugestão! 😊</p>
    </>,
    ES: <>
        <p className="text-sm pl-3 text-[#4A4A4A]">Soy una IA segura capaz de responder a tus preguntas. También estoy vinculado a los datos del Smart Data Hub de  <span className="font-semibold">VINCI Airports</span>.</p>
        <p className="text-sm pl-3 text-[#4A4A4A]">¡Comienza por preguntarme algo o elige una sugerencia! 😊</p>
    </>,
    JA: <>
        <p className="text-sm pl-3 text-[#4A4A4A]">私はあなたの質問に答えることができる安全なAIです。また、 <span className="font-semibold">VINCI Airports</span>のSmart Data Hubの</p>
        <p className="text-sm pl-3 text-[#4A4A4A]">データにも接続されています。何か質問をしてみるか、提案を選んでください！😊</p>
    </>
}

export const SDHIntro = ({ language }: { language: LanguageCode }) => {
    return (
        <div className="flex w-full pt-3 pb-8">
            <div><ChatLogo icon="IA_CHAT_ICON" /></div>
            <div>
                <p className="text-sm pl-3 text-[#4A4A4A] font-semibold">Hello!</p><br />
                {messageTranslations[language]}
            </div>
        </div>
    );
};
