import { useState } from "react";
import { useAuth } from "../../../../providers";


const excelOptions = [
    { label: "HR_template", value: "HR" },
    { label: "Website_template", value: "WEB" },
];

export const DownloadTemplate = ({ }) => {
    const { token } = useAuth()
    const [fileToDownload, setFileToDownload] = useState<string>('HR');
    const handleChangeFileDownload = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFileToDownload(e.target.value);
    };
    const downloadFile = async () => {
        try {
            const response = await fetch(`/api/sentiment/template/${fileToDownload}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error("Erreur de telechargement.");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileToDownload == "HR" ? "HR_template.xlsx" : "Website_template.xlsx"}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);

            //onSuccess();
        } catch (error) {
            //onError(error);
            console.log(error);
        }
    };
    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full bg-white justify-center rounded-xl gap-2.5">
                <div className="flex flex-col">
                    <h1 className="text-lg text-text-primary-light font-trendaSemiBold py-2">Excel File Template:</h1>
                    <div className="flex gap-3">
                        <select className="p-2 bg-secondary-light border-secondary-light rounded-lg focus:outline-none " value={fileToDownload} onChange={handleChangeFileDownload}>
                            {excelOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <button onClick={() => downloadFile()} className="w-[20%] px-4 py-2 bg-transparent text-black rounded-lg border hover:bg-secondary-light border-secondary-light focus:outline-none">Download</button>

                    </div>
                </div>
            </div>
        </div>
    )
}