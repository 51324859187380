import React, { useEffect, useState } from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import { useDataGouv } from '../providers/DataGouvProvider';

interface CalendarProps {
  years: string[];
}

export const Calendar: React.FC<CalendarProps> = ({ years }) => {
  const startYear = Number(years[0]);
  const {setMonthYearSelected} = useDataGouv()

  const generateMonths = (current: Date) => {
    const months = [];
    for (let i = 3; i >= -1; i--) {
      const month = subMonths(current, i);
      months.push(format(month, 'MMMM yyyy'));
    }
    return months;
  };

  const currentMonth = new Date();
  const [currentStartDate, setCurrentStartDate] = useState(currentMonth);
  const months = generateMonths(currentStartDate);
  const [selectedDate, setSelectedDate] = useState<string | null>(months[3]);

  useEffect(() => {
    const currentMonthYear = format(currentMonth, 'MM/yyyy');
    setMonthYearSelected(currentMonthYear); 
  }, []);

  // const handlePrevClick = () => {
  //   const newStartDate = subMonths(currentStartDate, 4);
  //   setCurrentStartDate(newStartDate);
  // };

  // const handleNextClick = () => {
  //   const newStartDate = addMonths(currentStartDate, 4);
  //   setCurrentStartDate(newStartDate);
  // };

  return (
    <div className="flex w-full text-sm gap-1">
      {/* <button
        className="text-gray-600 bg-white p-0.5 border border-[#EEEEEE] rounded-tl-md rounded-bl-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-90"
          width="18"
          height="18"
          fill="black"
          viewBox="0 0 24 24"
        >
          <path
            fill="black"
            fillRule="evenodd"
            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
          ></path>
        </svg>
      </button> */}
      <div className="flex overflow-x-auto">
        {months.map((month, index) => (
          <div key={index} className="">
            <div
              onClick={() => {
                setSelectedDate(month);
                const monthYear = format(new Date(month), 'MM/yyyy');
                setMonthYearSelected(monthYear);
              }}
              className={`cursor-pointer flex items-center justify-center w-[120px] text-black text-sm rounded-md ${
                selectedDate === month ? 'bg-[#EEEEEE]' : 'bg-white'
              } px-1 py-2 border border-[#EEEEEE]`}
            >
              {month}
            </div>
          </div>
        ))}
      </div>

      {/* <button
        className="text-gray-600 bg-white p-0.5 border border-[#EEEEEE] rounded-tr-md rounded-br-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="-rotate-90"
          width="18"
          height="18"
          fill="black"
          viewBox="0 0 24 24"
        >
          <path
            fill="black"
            fillRule="evenodd"
            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
          ></path>
        </svg>
      </button> */}
    </div>
  );
};