import { useEffect, useRef, useState } from "react";
import { ModelGeminiIcon, ModelGPT4Icon, ModelGPTClassicIcon, ModelMistralIcon } from "../components";
import { ChatLogo } from "../../../assets/SVGIcon/ChatLogo";
import { useChatGPT } from "../providers";
import { ChatGPTInputHook, messageGPT } from "./chatGPTInput";
import { DataHistory, SecuredGPTConfig } from "./useChatGPTConfig";

export type ChatModel = 'gpt' | 'gpt4' | 'mistral' | 'gemini';

type ChatAppData = {
    id: ChatModel;
    title: string;
    icon: JSX.Element;
    description: string;
}

export interface ChatGPTModelHook {
    chatModelOpt: ChatAppData[];
    selectedChatModel: ChatModel;
    setSelectedChatModel: React.Dispatch<React.SetStateAction<ChatModel>>;
}

export function getLastMsgFunction(data: DataHistory, session_id: string):  ChatModel | "ia_multidocs" | null | undefined {
    for (const key in data) {
        if (data[key].session_id === session_id) {
            return data[key].conversation
            .filter(e => e.role === "assistant")
            .slice(-1)[0]?.model;        }
    }
    return undefined;
}

export const useChatGPTModel = (gptInput: ChatGPTInputHook, gptConfig: SecuredGPTConfig, ): ChatGPTModelHook => {
    const chatModelOpt: ChatAppData[] = [
        {
            id: 'gpt',
            title: "GPT 3.5",
            icon: <ChatLogo icon={"GPT_CHAT_ICON"} />,
            description: 'Faster for everyday tasks',
        },
        {
            id: 'gpt4',
            title: "GPT 4o",
            icon: <ChatLogo icon={"GPT4_CHAT_ICON"} />,
            description: 'Ideal for complex tasks',
        },
        {
            id: 'mistral',
            title: "Mistral",
            icon: <ChatLogo icon={"MISTRAL_CHAT_ICON"} />,
            description: 'Efficient and precise for advanced analysis',
        },
        {
            id: 'gemini',
            title: "Gemini",
            icon: <ChatLogo icon={"GEMINI_CHAT_ICON"} />,
            description: 'Versatile and high-performance for innovative solutions',
        }
    ];

    const [selectedChatModel, setSelectedChatModel] = useState<ChatModel>('gpt')
    const prevChatModelRef = useRef<ChatModel | "ia_multidocs" | null | undefined>(getLastMsgFunction(gptConfig.securedGPTHistory, gptConfig.selectedChatId));

    useEffect(() => {
        if (gptConfig.mDModeActive) {
            prevChatModelRef.current = "ia_multidocs"
        } else {
            prevChatModelRef.current = getLastMsgFunction(gptConfig.securedGPTHistory, gptConfig.selectedChatId)
        }
    }, [gptConfig.selectedChatId, gptConfig.securedGPTHistory, gptConfig.mDModeActive]);

    useEffect(() => {
        const prevChatModel = prevChatModelRef.current;
        const newChatModel = selectedChatModel;

        const previousFunctionality = prevChatModel == "ia_multidocs" ? "Multi Docs mode" : `${chatModelOpt.find(e => e.id == prevChatModel)?.title} model`
        const nextFunctionality = gptConfig.mDModeActive ? "Multi Docs mode" : chatModelOpt.find(e => e.id == newChatModel)?.title
        
        let info = `You have now left the ${previousFunctionality}.`

        const switchChatModel = async () => {
            await gptInput.addInfo(
                gptConfig.selectedChatId,
                "text_switch",
                `${prevChatModel == newChatModel || (prevChatModel == "ia_multidocs" && gptConfig.mDModeActive)  ? '' : `${info} `}You are now using ${nextFunctionality}.`,
                gptConfig
            );
        };

        // Call the function to log the change
        if (prevChatModel != undefined && prevChatModel != null){
            switchChatModel();
        }

    }, [selectedChatModel, gptConfig.mDModeActive]);

    return { chatModelOpt, selectedChatModel, setSelectedChatModel };
};

