import React from "react";
import { useDropzone } from "react-dropzone";
import { PDFDocument } from 'pdf-lib';
import { useState } from "react";
import { SVGIcon } from "../../assets/SVGIcon";
import { getUploadFileType, UploadFile } from "../ChatGPT/hooks/useMultiDocsConfig";

interface FileDropzoneProps {
    files: UploadFile[]
    setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>
    loaderLong: boolean;
}

const DropzoneLargeDocNew: React.FC<FileDropzoneProps> = ({
    files,
    setFiles,
    loaderLong
}) => {

    const onDrop = (acceptedFiles: File[]) => {
        const newFiles: UploadFile[] = acceptedFiles
            .filter(af => !files.some(f => f.file.name === af.name))
            .map(af => ({
                file: af,
                ocrChecked: false,
                summaryChecked: true,
                processed: false,
                selected: false,
                type: getUploadFileType(af.type)
            }));
        if (files.length === 0 && newFiles.length > 0) {
            newFiles[0].selected = true;
        }
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
    };

    const { getRootProps, getInputProps, isDragReject, fileRejections } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
            "text/plain": [".txt"],
        },
        onDrop,
        maxFiles: 10,
        multiple: true,
        maxSize: 200 * 1024 * 1024, // 200MB in bytes
        noClick: loaderLong
    });

    return (
        <>
            <div
                {...getRootProps()}
                className={`dropzone border-dashed border-2 border-[#CFCFCF] rounded-xl p-2.5 text-center cursor-pointer w-full`}
            >
                <input {...getInputProps()} />
                <p className="font-bold">
                    Drag and drop your files here, or click to select your files.
                </p>
                <p className="text-sm text-gray-600 mt-1">
                    Accepted Files: PDF, DOCX, PPTX, TXT<br/>
                    Maximum size per file: 200MB
                </p>
                {fileRejections.length > 0 && (
                    <p className="text-sm text-red-500 mt-2">
                        Some files have been rejected.
                    </p>
                )}
            </div>
        </>
    );
};

export default DropzoneLargeDocNew;
