import { useAuth } from "../../../providers";
import { useChatGPT } from "../providers";
import { NewChatBtn } from "./NewChatBtn";

export const ChatGPTTop = ({ }: {}) => {
    const { tokenInfo } = useAuth();
    const firstLetterName = tokenInfo?.name.substring(0, 1).toUpperCase() || "P"

    const {
        modalStatus,
        setModalStatus,
        gptModel,
        gptConfig,
        chatStatus,
        setChatStatus,
        gptInput
    } = useChatGPT();


    const title = !gptConfig.mDModeActive ? gptModel.chatModelOpt.find(chat => chat.id === gptModel.selectedChatModel)?.title : "MultiDocs";

    const closeAssistant = () => {
        setChatStatus("ASSISTANT_CLOSE")
    }

    return (
        <>
            {
                chatStatus != "ASSISTANT_OPEN" ?
                    <div className="sticky top-0 p-3 mb-1.5 grid grid-cols-2 items-center justify-between z-10 h-14 bg-white">

                        <div className={`flex`}>
                            <div onClick={() => { setModalStatus("MODEL") }} id="radix-:r97:" aria-haspopup="menu" aria-expanded="false" data-state="closed" className={`text-[#0d3d62] group flex cursor-pointer items-center gap-1 rounded-lg py-1.5 px-3 hover:bg-gray-100 overflow-hidden ${modalStatus == "MODEL" && "bg-gray-100"}`}>
                                <div className="text-[#0d3d62] font-medium text-lg whitespace-nowrap font-trendaSemiBold">
                                    {title}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24" className="icon-md text-token-text-tertiary"><path fill="currentColor" fillRule="evenodd" d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414" clipRule="evenodd"></path></svg>
                            </div>
                        </div>

                        <div className="flex justify-end items-center gap-2">
                            <NewChatBtn />
                            <button className="bg-text-primary-light w-10 h-10 flex items-center justify-center text-lg rounded-full text-white" onClick={() => { setModalStatus("PARAMS") }}>
                                <span className="">{firstLetterName}</span>
                            </button>
                        </div>
                    </div>
                    :
                    <div className="relative px-4 py-2 gap-2 flex flex-col shadow-lg">
                        <div className=" p-2 border border-opacity-0 rounded-xl">
                            <p className="text-sm">
                                Welcome back to our secured chat.
                                Here’s what you can do:
                                <ul className=" list-disc pl-4">
                                    <li>Ask where to find a specific KPI</li>
                                    <li>Find the exact location where a chart is available</li>
                                    <li>Discover the KPI repartition possibilities</li>
                                </ul>
                            </p>
                        </div>
                        <div className=" shadow-lg p-2 border border-opacity-0 rounded-xl">
                            <p className="text-sm">
                                Here are some things you can ask:
                                <div className="flex flex-col gap-2 pt-2">
                                    {
                                        [
                                            "Where can I find a chart to compare PAX predict with budget?",
                                            "Where is the top 10 airlines in number of seats?",
                                            "Give me the location of the line chart with the budget versions."
                                        ].map((text, index) =>
                                            <div className="p-2 border rounded-xl hover:shadow-md cursor-pointer" onClick={() => gptInput.setText(text)}>{text}</div>
                                        )
                                    }
                                </div>
                            </p>
                        </div>
                    </div>
            }
        </>
    )
}
