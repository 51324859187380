import { useEffect, useState } from "react"

export const useDataQuality = (token: string | null) =>{
    const [dataQuality, setDataQuality] = useState<any>()

    useEffect(()=>{
        if(token === null) return
        fetch(`/api/gouvernance/overview`,{
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response)=>response.json())
        .then((data)=>{
            setDataQuality(data)
        })
    },[token])

    const getQualityDetailForBu = async (asset: string, use_case: string, year:number): Promise<any> => {
        if (token === null) return;
      
        return fetch(`api/gouvernance/quality`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ asset, use_case, year})
        })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error("Error fetching quality details:", error);
            return null
        });
    };      

    return {dataQuality,getQualityDetailForBu}
}