import React from 'react';
import { useAuth } from '../../providers';
import { useState,useEffect } from 'react';
import { languageOptions } from './Newsletter';
import { detailOption } from './Newsletter';
import { writingStyleOption } from './Newsletter';
import { themeIndustryOption } from './Newsletter';

interface Option {
  label: string;
  value: string;
}

interface UserInfo {
  userName: string;
  userEmail: string;
  language: string;
  newsletterFrequency: string;
  format: string;
  writtingStyle: string;
  themes? : string[];
}


interface DropdownNewletterProp {
  userInfo : UserInfo;
  email: string;
  subscribe(userInfoPayload: UserInfo, type: string): Promise<void>;
  deleteSubscription(email: string, newsletterType: string): Promise<void>;
  type: 'access' | 'industry';
  isOpen: boolean;
  onToggle: () => void;
  subscribeLoader: boolean; 
  deleteLoader: boolean;
  emailVerified: boolean;

}

export const DropdownNewsletter: React.FC<DropdownNewletterProp>= ({userInfo, email, subscribe, deleteSubscription,type,isOpen,onToggle, subscribeLoader,deleteLoader,emailVerified}) => {
  const [name,setName] = useState<string>('')
  const [language, setLanguage] = useState<string>('EN');
  const [theme, setTheme] = useState<string[]>(['Traffic'])
  const [frequency, setFrequency] = useState<string>('Daily');
  const [format, setFormat] = useState<string>('Brief');
  const [writingStyle, setWritingStyle] = useState<string>('Written');
  const [isUserSubscribed, setIsUserSubscribed] = useState<boolean>(false)
  

  const toggleDropdown = () =>{
    onToggle()
  }

  const handleChangeFrequency = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFrequency(e.target.value);
  };

  const setNameNews = (e: React.ChangeEvent<HTMLInputElement>) =>{
    setName(e.target.value)
  }

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
  }

  const handleChangeTheme = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTheme = e.target.value;
    if(theme.includes(selectedTheme)) return;
    setTheme([...theme,selectedTheme]);
  }

  const removeTheme = (themeToRemove: string) => {
    if(theme.length === 1) return
    setTheme(currentThemes => currentThemes.filter(theme => theme !== themeToRemove));
  }

  useEffect(()=>{
    if (subscribeLoader) return;
    if(userInfo && Object.values(userInfo).every((value => value === null))){
        //setEmailVerified(true)
        setIsUserSubscribed(false)
    }
    else if(userInfo && Object.entries(userInfo).length > 0){
        //setEmailVerified(true)
        setIsUserSubscribed(true)
        setName(userInfo.userName || '');
        setLanguage(userInfo.language || 'EN');
        setFrequency(userInfo.newsletterFrequency || 'Daily');
        setFormat(userInfo.format || 'Brief'); 
        setWritingStyle(userInfo.writtingStyle || 'Written');
        if(userInfo.themes?.length! > 0) setTheme(userInfo.themes || [])
    }
   },[userInfo])

  const handleSubscribe = async() =>{
    if(subscribeLoader) return;
    const subscribeObj: any = {
        userEmail: email,
        userName: name,
        language: language,
        newsletterFrequency: frequency,
        format: format,
        writtingStyle: writingStyle,
        isUserSubscribed: isUserSubscribed,
        themes: theme
    }
    if (type === 'industry') {
        subscribeObj.newsletterType = 'industry'
    }
    if (type === 'access') {
        subscribeObj.newsletterType = 'access'
    }
    await subscribe(subscribeObj,type)
    toggleDropdown()
}


const deleteSubscriptionWrapper = async() =>{
    await deleteSubscription(email,type)
    // setEmail('')
    setName('')
    setLanguage('EN')
    setFrequency('Daily')
    setFormat('Brief')
    setWritingStyle('Written')
    //setEmailVerified(false)
    // setDropdownOpen(false)
    if (type === 'industry') {
       setTheme([])
    }
    toggleDropdown()
}

  
  return (
    <>
      {emailVerified &&
          <div className="w-full rounded-xl">
              <button onClick={toggleDropdown} className="hover:text-emerald-500 text-gray-700 flex justify-between items-center w-full px-4 py-2 text-left bg-transparent rounded-lg shadow border border-gray-300 focus:outline-none">
                  <span className="w-full">
                    {isUserSubscribed ? (type === 'access' ? "Edit VINCI Media Monitoring Settings" : "Edit VINCI Industry Market Trend")
                    : 
                    (type === 'access' ? "Subscribe to VINCI Media Monitoring" : "Subscribe to VINCI Industry Market Trend")
                    }
                    &nbsp;
                    </span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
                  </svg>
              </button>
              <div  className={`w-90 bg-transparent relative transition-all ease-in-out duration-500 overflow-hidden ${isOpen ? 'border border-gray-300 rounded-lg' : ''} ${isOpen ? 'max-h-120' : 'max-h-0'}`}>
                  <div className="w-full p-3 flex flex-col gap-4">
                      <div className="flex flex-col">
                          <label className="text-md p-1">Name</label>
                          <input className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" value={name} onChange={setNameNews}></input>
                      </div>
                      <div className="flex flex-col">
                          <label className="text-md p-1">Language</label>
                          <select className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" value={language} onChange={handleChangeLanguage}>
                              {languageOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                      {option.label}
                                  </option>
                              ))}
                          </select>
                      </div>
                      {type === 'industry' && 
                      <>
                        <div className="flex flex-col">
                            <label className="text-md p-1">Themes</label>
                            <select className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" value={theme[-1]} onChange={handleChangeTheme}>
                                {themeIndustryOption.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {theme.map((selectedTheme, index) => (
                                <span key={index} className="px-2 py-1 bg-gray-200 rounded-full cursor-pointer" onClick={() => removeTheme(selectedTheme)}>
                                    {selectedTheme} &times;
                                </span>
                            ))}
                        </div>
                        </>
                      }
                      <div className="flex flex-col">
                          <label className="text-md p-1">Newsletter Frequency</label>
                          <div className="flex flex-row items-center space-x-4">
                          <label>
                              <input type="radio" name={`frequency-${type}`} value="Daily" checked={frequency === 'Daily'} onChange={handleChangeFrequency} />
                              &nbsp;&nbsp;Daily
                          </label>
                          <label>
                              <input type="radio" name={`frequency-${type}`} value="Weekly" checked={frequency === 'Weekly'} onChange={handleChangeFrequency} />
                              &nbsp;&nbsp;Weekly
                          </label>
                          <label>
                              <input type="radio" name={`frequency-${type}`} value="Monthly" checked={frequency === 'Monthly'} onChange={handleChangeFrequency} />
                              &nbsp;&nbsp;Monthly
                          </label>
                          </div>
                      </div>
                      <div className="flex flex-row gap-5">
                          <div className="flex flex-col w-full">
                              <label>Detail Level</label>
                              <select className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" value={format} onChange={(e)=>setFormat(e.target.value)}>
                                  {detailOption.map((option)=> (
                                      <option key={option.value} value={option.value}>
                                          {option.label}
                                      </option>
                                  ))}
                              </select>
                          </div>
                          <div className="flex flex-col w-full">
                              <label>Writing Style</label>
                              <select className="w-full p-2 bg-gray-200 border-gray-400 rounded-lg focus:outline-none focus:ring focus:ring-emerald-500" value={writingStyle} onChange={(e)=>setWritingStyle(e.target.value)}>
                                  {writingStyleOption.map((option)=> (
                                      <option key={option.value} value={option.value}>
                                          {option.label}
                                      </option>
                                  ))}
                              </select>
                          </div>
                      </div>
                      {isUserSubscribed ? 
                      <div className="flex flex-row w-full">
                          <button onClick={handleSubscribe} className="mx-4 mb-4 px-4 py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{subscribeLoader ? "Updating.." : 'Update Settings'}</button>
                          <button onClick={deleteSubscriptionWrapper} className="mx-4 mb-4 px-4 py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-red-400 hover:border-red-400 focus:outline-none">{deleteLoader ? "Canceling.." : "Unsubscribe"}</button>
                      </div>
                      :
                      <button onClick={handleSubscribe} className="mx-4 mb-4 px-4 py-2 bg-transparent text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none">{subscribeLoader ? "Loading..." : "Subscribe"}</button>
                      }
                  </div>
              </div>    
          </div>
        }
    </>
  );
};
