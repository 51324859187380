import React, { useEffect, useState } from 'react';
import { SelectorFileType, SelectorFileVersion } from './Selector';
import { useDataGouv } from '../providers/DataGouvProvider';
import { ButtonIngestion, ButtonIngestionProps, CustomButton } from './ButtonIngestion';
import { FileStatusProps } from './FileStatus';
import { SVGIcon } from '../../../assets';
import { FileData } from '../hooks/useDataIngestion';

enum FileTypes {
  FINANCE_BUDGET = 'finance_budget',
  FINANCE_CAPEX = 'finance_capex',
  FINANCE_CAPEX_FORECAST = 'finance_capex_forecast',
  FINANCE_CAPEX_FORECAST_LONGTERM = 'finance_capex_forecast_longterm',
  FINANCE_REF_CAPEX_PROJECTS = 'finance_ref_capex_projects',
  FINANCE_CAPEX_DETAILED = 'finance_capex_detailed',
  FINANCE_COCKPIT_BUDGET = 'finance_cockpit_budget',
  FINANCE_COCKPIT_FTE_BUDGET = 'finance_cockpit_fte_budget',
  FINANCE_COCKPIT_FTE_RH = 'finance_cockpit_fte_rh',
  FINANCE_COCKPIT_GENERAL_LEDGER = 'finance_cockpit_general_ledger',
  FINANCE_COCKPIT_MAPPING_LOCAL = 'finance_cockpit_mapping_local',
  FINANCE_COCKPIT_PAX = 'finance_cockpit_pax',
  FINANCE_COCKPIT_TRAFFIC = 'finance_cockpit_traffic',
  FINANCE_COCKPIT_TRAFFIC_BUDGET = 'finance_cockpit_traffic_budget',
  FINANCE_CUSTOMER_REVENUES = 'finance_customer_revenues',
  FINANCE_DEPOSITS = 'finance_deposits',
  FINANCE_GENERAL_LEDGER = 'finance_general_ledger',
  FINANCE_GUARANTEES = 'finance_guarantees',
  FINANCE_MAPPING_USECASE = 'finance_mapping_usecase',
  FINANCE_MAPPING_LOCAL = 'finance_mapping_local',
  FINANCE_PROCUREMENTS = 'finance_procurements',
  FINANCE_PROVISIONS = 'finance_provisions',
  FINANCE_RECEIVABLES = 'finance_receivables',
  FINANCE_SUPPLIER_OPEX = 'finance_supplier_opex',
  FINANCE_PIGMENT_TRAFFIC_CONSO = 'finance_pigment_traffic_conso',
  FINANCE_PIGMENT_HR_CONSO = 'finance_pigment_hr_conso',
  FINANCE_PIGMENT_EURO = 'finance_pigment_euro',
  FINANCE_PIGMENT_GL_LOCAL = 'finance_pigment_gl_local',
  FINANCE_PIGMENT_MAPPING_LOCAL = 'finance_pigment_mapping_local',
}

enum FilenameContains {
  CAPEX = 'CAPEX',
  CAPEX_FORECAST = 'CAPEX_FORECAST',
  MAPPING_LOCAL = 'MAPPING_LOCAL',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
  SUPPLIER_OPEX = 'SUPPLIER_OPEX',
  CUSTOMER_REVENUES = 'CUSTOMER_REVENUES',
}
interface FileLoaderTable {
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;

}

export const FileTable: React.FC<FileLoaderTable> = ({files, setFiles}) => {
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-100" style={{ backgroundColor: '#EEEEEE' }}>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File name</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File type</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Version</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Uploaded by</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <FileRow key={index} index={index} fileName={file.name} setFiles={setFiles} files={files} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileName: string;
  setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;
  files: File[];
  index: number
}

const FileRow: React.FC<FileRowProps> = ({ fileName, setFiles, files, index }) => {
    const {buSelected, dateSelected, uploadFileFromDropzone, ingestionData} = useDataGouv()
    const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
    const [selectedVersion, setSelectedVersion] = useState('B0')
    const [isVersioned, setIsVersioned] = useState<boolean>(false);

    const isValidTypeInFilename = (filename: string): FilenameContains | null => {
      if (filename.includes("CAPEX")) return FilenameContains.CAPEX;
      if (["CAPEX_B0", "CAPEX_R1", "CAPEX_R2", "CAPEX_R3", "CPXFORECAST", "CAPEX_FORECAST"].some(val => filename.includes(val))) {
        return FilenameContains.CAPEX_FORECAST;
      }
      if (filename.includes("MAPPINGLOCAL")) return FilenameContains.MAPPING_LOCAL;
      if (filename.includes("GL")) return FilenameContains.GENERAL_LEDGER;
      if (filename.includes("SUPPLIER")) return FilenameContains.SUPPLIER_OPEX;
      if (filename.includes("CUSTOMER")) return FilenameContains.CUSTOMER_REVENUES;

      for (const value of Object.values(FilenameContains)) {
        if (filename.includes(value)) {
          return value as FilenameContains;
        }
      }

      return null;
    };

    useEffect(() => {
      const matchedType = isValidTypeInFilename(fileName);
      if (matchedType) {
        setSelectedFileType(matchedType);
        setIsVersioned(matchedType === FilenameContains.CAPEX_FORECAST || matchedType === FilenameContains.MAPPING_LOCAL);
      } else {
        const buOptions = ingestionData?.[buSelected!] || [];
        const fileTypeOptions = Array.from(new Set(buOptions.map((option) => option.file_type)));
  
        const regexMatchedType = fileTypeOptions.find((type) => {
          const regex = new RegExp(`(?:^|[_\\-\\.])${type}(?:[_\\-\\.]|$)`, 'i');
          return regex.test(fileName);
        });
  
        if (regexMatchedType) {
          setSelectedFileType(regexMatchedType);
          setIsVersioned(regexMatchedType === 'BUDGET' || regexMatchedType === 'CPXFORECAST' || regexMatchedType === 'CAPEX_FORECAST');
        }
      }
    }, [fileName, ingestionData, buSelected]);
    
    const handleFileTypeChange = (fileType: string) => {
        setSelectedFileType(fileType);
        setIsVersioned(fileType === 'BUDGET' || fileType === 'CPXFORECAST' || fileType === 'CAPEX_FORECAST');
    };

    const handleVersionChange = (fileVersion: string) => {
      const versionWithoutYear = fileVersion.split(dateSelected!)[0]; 
      setSelectedVersion(versionWithoutYear);
    };

    const handleRemoveFile = () => {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    };    

    const handleConfirmClick = () => {
      if (!buSelected || !selectedFileType) return
    
      const linkedFiles = ingestionData?.[buSelected]?.filter((fileData) => fileData.linked === selectedFileType);
    
      const linkedToFile = ingestionData?.[buSelected].find((elem) => elem.file_type === selectedFileType);
    
      let filesToSend: FileData[] | undefined = [];
    
      if (linkedFiles && linkedFiles.length > 0 && linkedToFile) {
        filesToSend = [...linkedFiles, linkedToFile];
      } else {
        filesToSend = ingestionData?.[buSelected]?.filter((fileData) => {
          const isNotLinked = fileData.linked === undefined;
          const isMatchingFileType = fileData.file_type === selectedFileType;
          return isNotLinked && isMatchingFileType;
        });
      }
    
      filesToSend?.forEach((file) => {
        if (isVersioned) {
          uploadFileFromDropzone(buSelected, file.file_type, files[index], selectedVersion);
        } else {
          uploadFileFromDropzone(buSelected, file.file_type, files[index]);
        }
      });

      handleRemoveFile()
    };     

  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="px-4 py-1.5 text-sm text-gray-800">{fileName}</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <SelectorFileType options={ingestionData!} buName={buSelected!}   
            onChange={(e) => handleFileTypeChange(e.target.value)}
            setSelectedFileType={setSelectedFileType}
            value={selectedFileType}
        />
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        {isVersioned && (
            <SelectorFileVersion
                buName={buSelected!}
                year={dateSelected!}
                onChange={(e)=> handleVersionChange(e.target.value)}
                disabled={false}
            />
        )}      
       </td>
      <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <CustomButton text='Confirm file info' onClick={handleConfirmClick}/>
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800 cursor-pointer" onClick={handleRemoveFile}>
        <SVGIcon icon='TRASH_ICON'/>
      </td>
    </tr>
  );
};
