import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '../Auth';

interface CreditContextType {
  remainingCredit: number | null;
  setRemainingCredit: (credit: number) => void;
}

const CreditContext = createContext<CreditContextType | undefined>(undefined);

export const CreditProvider = ({ children }: React.PropsWithChildren) => {
  const [remainingCredit, setRemainingCredit] = useState<number | null>(null);
  const { token, logout } = useAuth()
  useEffect(() => {
    if (!remainingCredit && token) {
      fetch('/api/credit', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setRemainingCredit(data.remaining_credit);
        })
        .catch(error => {
          console.error('Error while fetching remaning credit:', error);
          logout()
        })
    }

  }, [remainingCredit]);

  return (
    <CreditContext.Provider value={{ remainingCredit, setRemainingCredit }}>
      {children}
    </CreditContext.Provider>
  );
};

export const useCredit = (): CreditContextType => {
  const context = useContext(CreditContext);
  if (context === undefined) {
    throw new Error('useCredit doit être utilisé au sein d\'un CreditProvider');
  }
  return context;
};
