import { useState } from "react";
import { useChatGPT } from "../providers";
import { BookIcon, LinkIcon, ManageFileIcon, PaperclipIcon, ParamsIcon } from "./Icon";
import { SVGIcon } from "../../../assets/SVGIcon";
import { UploadDocuments } from "./MultiDocs/UploadDocuments";
import { AnalyseLinks } from "./MultiDocs/AnalyseLinks";
import { ManageFiles } from "./MultiDocs/ManageFiles";
import { useMultiDocs } from "../providers/MultiDocsProvider";

const paramConfig = [
    {
        id: 0,
        title: "Upload Documents",
        icon: <SVGIcon size="18px" icon="UPLOAD_ICON" />,
        paramComponent: <UploadDocuments />
    },
    {
        id: 1,
        title: "Analyse Links",
        icon: <SVGIcon size="18px" icon="LINKS_ICON" />,
        paramComponent: <AnalyseLinks />
    },
    {
        id: 2,
        title: "Manage Files",
        icon: <ManageFileIcon />,
        paramComponent: <ManageFiles />
    }
]


export const ChatGPTModalMultiDocs = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig,
        gptModel
    } = useChatGPT();
    const { config } = useMultiDocs()

    const [appSelected, setAppSelected] = useState<Number>(0)

    return (
        <div className="w-[750px]">
            <div className="px-2 pb-2 pt-2 sm:p-4 flex items-center justify-between border-b border-black/10 dark:border-white/10">
                <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-8 h-8 text-text-primary-light">
                        <PaperclipIcon />
                    </div>
                    <h2 className="text-lg font-medium leading-6 text-text-primary-light font-trendaSemiBold">Multi Documents</h2>
                </div>
                <button className="flex h-8 w-8 items-center justify-center" onClick={
                    () => {
                        if (gptConfig.mDModeActive && config.uploadFiles.filter(file => file.processed).length == 0)
                            gptConfig.setMDModeActive(false)
                        setModalStatus("CLOSE")
                    }
                }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                        <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div className="flex">
                <div className="flex flex-col m-2 md:m-0 md:px-2  md:py-4 flex-shrink-0  md:min-w-[180px] gap-1">
                    {
                        paramConfig.map((c, i) => {
                            return (
                                <button type="button" onClick={() => setAppSelected(c.id)} className={`${appSelected == c.id && "bg-secondary-light"} hover:bg-secondary-light group flex items-center justify-start gap-1.5 rounded-md px-2 py-1.5 text-sm text-text-primary-light`}>
                                    {c.icon}
                                    <div className="text-left">{c.title}</div>
                                </button>
                            )
                        })
                    }
                </div>
                <div className="flex flex-col m-2 p-2 gap-2 content-center w-full">
                    {paramConfig.map((c) => (
                        appSelected === c.id && (
                            <div key={c.id}>
                                {c.paramComponent}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    )
}