import { createContext, useContext } from "react";
import { MultiDocsConfig, useMultiDocsConfig } from "../hooks/useMultiDocsConfig";
import { useSessionId } from "../../../hooks";


interface CreditContextType {
  config: MultiDocsConfig;
}

const MultiDocsContext = createContext<CreditContextType | undefined>(undefined);


export const MultiDocsProvider = ({ children }: React.PropsWithChildren) => {
  const multiDocsSessionId = useSessionId(`/api/multi_doc/clear_session`);
  const config = useMultiDocsConfig(multiDocsSessionId)

  return (
    <MultiDocsContext.Provider value={{
      config,
    }}>
      {children}
    </MultiDocsContext.Provider>
  );
};

export const useMultiDocs = () => {
  const context = useContext(MultiDocsContext);
  if (!context) {
    throw new Error('useMultiDocs must be used within a MultiDocsProvider');
  }
  return context;
};