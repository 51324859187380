import { useMemo, useState } from "react";
import { useChatGPT } from "../providers";
import { BookIcon, ParamsIcon, ProfilIcon } from "./Icon";
import { ChatGPTOptimizedPrompts } from "./ChatGPTOptimizedPrompts";
import { ChatGPTLanguage } from "./ChatGPTLanguage";
import { ChatGPTContext } from "./ChatGPTContext";

export const ChatGPTModalParameters = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig
    } = useChatGPT();

    const config = useMemo(() => {
        return [
            {
                id: 0,
                title: "Language",
                description: "Update language parameter to get optimized prompts in your language",
                icon: <ParamsIcon />,
                paramComponent: <ChatGPTLanguage />
            },
            {
                id: 1,
                title: "Optimized Prompts",
                description: "Find and Store optimized prompt for your needs",
                icon: <BookIcon />,
                paramComponent: <ChatGPTOptimizedPrompts />
            },
            {
                id: 2,
                title: "Context",
                description: "Update your context to guide SecuredGPT as you wish",
                icon: <ProfilIcon />,
                paramComponent: <ChatGPTContext />
            }
        ];
    }, []);

    const [appSelected, setAppSelected] = useState<Number>(0)

    return (
        <div className="w-[600px]">
            <div className="px-2 pb-2 pt-2 sm:p-4 flex items-center justify-between border-b border-black/10 dark:border-white/10">
                <h2 className="text-lg font-semibold leading-6 text-token-text-primary">Settings</h2>
                <button className="flex h-8 w-8 items-center justify-center" onClick={() => { setModalStatus("CLOSE") }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                        <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div className="flex">
                <div className="flex flex-col m-2 md:m-0 md:px-4  md:py-4 flex-shrink-0  md:min-w-[180px] gap-2">
                    {
                        config.map((c, i) => {
                            return (
                                <button type="button" onClick={() => setAppSelected(c.id)} className={`${appSelected == c.id && "bg-[#ececec]"} hover:bg-[#ececec] group flex items-center justify-start gap-2 rounded-md px-2 py-1.5 text-sm`}>
                                    {c.icon}
                                    <div className="text-left">{c.title}</div>
                                </button>
                            )
                        })
                    }
                </div>
                <div className="flex flex-col m-2 p-2 gap-2 content-center">
                    {config.map((c) => (
                        appSelected === c.id && (
                            <div key={c.id}>
                                <div className="text-black text-md pb-2">{c.description}</div>
                                {c.paramComponent}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    )
}