export const templatePrompts = {
    "Communication": {
        "Press release": {
            "definition": "Write press release about some event",
            "prompt_template": "Write a press release on behalf of [SENDER: INSTITUTION, POSITION, NAME] about [SOME EVENT] mentioning the following points:\n<POINTS>\n- information 1\n- information 2\n</POINTS>\nUse a formal tone [SPECIFY APPROPRIATE TONE]."
        },
        "Prose checking": {
            "definition": "Detect inappropriate wording, expression",
            "prompt_template": "Detect inappropriate, offensive, sarcastic, expression in the following text:\n<TEXT>\n</TEXT>"
        },
        "Write letters": {
            "definition": "Write a letter to an institution, a partner, etc. for a specified event",
            "prompt_template": "Write a mail to [INSTITUTION, POSITION, NAME] about the following event: [EVENT] on behalf of [SENDER: INSTITUTION, POSITION, NAME]\nUse a formal tone."
        },
        "Press analysis": {
            "definition": "Analyze press articles about an event",
            "prompt_template": "Please use Data Room feature for this"
        }
    },
    "Day to day": {
        "Write an e-mail": {
            "definition": "Compose an email <<<prestataire, client, airline>>> sur un topic donné. <<avec une indication de ton>>",
            "prompt_template": "I have a this problem: [YOUR COMPUTER PROBLEM]. Write a mail to be send to my administrative hierarchy about it."
        },
        "Improve an e-mail": {
            "definition": "Enhance a draft email concerning a specific subject for improved clarity and effectiveness.",
            "prompt_template": "I am trying to write an e-mail about [SUBJECT]. I have made the following draft. Improve it.\n\n[YOUR DRAFT]"
        },
        "Translate a text": {
            "definition": "Translate a given text into the specified language.",
            "prompt_template": "Translate it in [LANGUAGE]:\n\n[TEXT]"
        },
        "Summarize a text": {
            "definition": "Provide a concise summary of the content in the given text, potentially following a specific format.",
            "prompt_template": "Summarize the following text. [OPTIONAL FORMAT]:\n\n[TEXT]"
        },
        "Prepare an onsite visit": {
            "definition": "Write me a plan for my next to <<>> <<duration>> <<people to meet>> <<topic>> <<max duration>> . Option: add the planning as a table.",
            "prompt_template": "Write me a plan for my next visit to [SITE] for [NUMBER OF DAYS] with meetings with the following list of peole, with the subjects and duration:\n<MEETNGS>\n- position (name), topic, duration\n- position (name), topic, duration\n- position (name), topic, duration\n</MEETINGS>\n[Option: Add the planning as a table]."
        }
    },
    "Finance": {
        "Email account/budget closure notice": {
            "definition": "Write an email notifying relevant parties about the accounting closure of an account or budget, including relevant details.",
            "prompt_template": "Compose an email notifying the financial close of [CLOSE DATE] while considering the following INFORMATION:\n<INFORMATION>\n[REMOVE OR ADAPT THE FOLLOWING ITEMS ACCORDING YOUR NEEDS]\n- Closing Schedule\n- Account Reconciliations\n- Expense Recognition\n- Revenue Recognition\n- Inventory Procedures\n- Fixed Assets Management\n- Accruals and Adjustments\n- Intercompany Reconciliations\n- Journal Entry Procedures\n- Financial Reporting\n- Compliance Checks\n- Review and Approval\n- Documentation and Record Keeping\n- Post-Close Activities\n- Communication Plan\n</INFORMATION>\n[ADDITIONAL INFORMATION FOR FORMATTING]."
        }
    },
    "Human ressources": {
        "Declined application Email": {
            "definition": "Draft an email notifying a candidate that their job application has been declined.",
            "prompt_template": "Write an email to notify [M/MS X] that their job application to [POSITION] has been declined."
        },
        "Job description ": {
            "definition": "Create a job description for a specified position, including sections on responsibilities, required skills, and profile details.",
            "prompt_template": "Write a job posting for the position of [JOB TITLE]. Include sections for 'duties,' 'desired skills,' and 'profile' in the job description."
        },
        "Travel & expenses policies": {
            "definition": "Write a document outlining the travel policy for the specified company, considering relevant details.\n- remboursements\n- jours récupérables\n- franchise\n- delai de remboursement\n- limite de claim\n- business vs. economic class durée 4h avec max tarif\n[ADD your specific requirements as additional bullet points]",
            "prompt_template": "Draft a document explaining the travel policy of my company [COMPANY NAME] while considering the following information:\n<INFORMATION>\n- reimboursements: specify eligible items\n- days off: specify conditions here\n- franchise\n- reimboursement delay\n- claim date limit: specify information\n- business vs. economic class: from 4 hours, with max price equals to X\n[ADD your specific requirements as additional bullet points]\n</INFORMATION>"
        },
        "\"Invitation to Interview for [Position]\"": {
            "definition": "\"Formal email inviting candidate [name, surname] to interview for [position] at our organization.\"",
            "prompt_template": "Write the content of a formal email to the candidate [name, surname] informing him of the invitation to an interview for the position of [position]."
        },
        "Competency Questionnaire for [Position]": {
            "definition": "Evaluate candidates' [skill] proficiency through targeted competency questions for [position] application assessment.",
            "prompt_template": "Create a set of 5 competency questions to test [skill] knowledge among candidates applying for the position of [position]."
        },
        "\"Unconventional Interview Questions for [Position] with [Experience]\"": {
            "definition": "\"Discover a unique set of interview questions designed to assess candidates' [experience] for [position].\"",
            "prompt_template": "Generate a list of unusual and surprising 10 questions I might ask during a recruitment interview with a candidate for a [position]. He or she should have experience in [experience]"
        }
    },
    "IT/Software Developpment": {
        "Explain this code": {
            "definition": "Provide an explanation for a given piece of code, detailing its functionality and logic.",
            "prompt_template": "Explain the following JavaScript CODE.\n<CODE>\n[YOUR CODE HERE]\n</CODE>"
        },
        "Optimize my code": {
            "definition": "Improve the efficiency and performance of provided code while maintaining its functionality.",
            "prompt_template": "Optimise the following Python code:\n\n[YOUR CODE]"
        },
        "Write a code": {
            "definition": "Create a Python code to complete specified tasks as outlined in the prompt.",
            "prompt_template": "Write a Python Code that make the following tasks:\n\n[YOUR TASKS]"
        },
        "Translate programmation language": {
            "definition": "Convert R code into its equivalent Python code representation.",
            "prompt_template": "Transform this R code into a Python Code.\n\n<CODE>"
        }
    },
    "Procurement": {
        "Conditions of purchase for personnel subcontracting": {
            "definition": "Develop subcontracting-specific terms and conditions for purchases.",
            "prompt_template": "Create specific terms and conditions of purchase for personnel subcontracting, considering the following information:\n<CONDITIONS>\n- Financial terms, payment terms, payment conditions, and invoices;\n- Deadlines and penalties in case of exceeding;\n- Delivery and acceptance, logistics: certificates of conformity, shipping slip, transport, packaging;\n- Quality control;\n- Transfer of ownership;\n- Guarantees and risks;\n- Confidentiality and professional secrecy;\n- Industrial and intellectual property rights;\n- Damages and insurance;\n- Disputes and competent jurisdiction.\n[ADD HERE YOUR SPECIFICS CONDITIONS OR REMOVE THIS]\n</CONDITIONS>\n"
        }
    },
    "Training": {
        "Write a tutorial": {
            "definition": "Generate a tutorial on a specified subject, covering its key concepts and details.",
            "prompt_template": "You are an teaching expert. Create an engaging and informative tutorial on [TOPIC] for [TARGET AUDIENCE'S LEVEL OF EXPERIENCE] (e.g., beginners, intermediates, advanced learners). The tutorial should start by introducing the basics of [BRIEF DESCRIPTION OF WHAT THE BASICS MIGHT INCLUDE], considering [ANY SPECIFIC FACTORS OR CONSIDERATIONS RELATED TO THE TOPIC]. It should then guide the readers through the process of [MAIN STEPS OR PROCESSES INVOLVED IN THE TOPIC], including any necessary preparations and practical tips. The aim is for the readers to feel confident in [DESIRED OUTCOME OR SKILL] by the end of the tutorial. Please include a list of materials or prerequisites at the beginning and provide step-by-step instructions with clear and simple explanations for each part of the process. Visual aids or links to resources for further reading would be beneficial to support the learning experience."
        },
        "Write an agenda for a course": {
            "definition": "Generate an agenda for a specific topic and target audience.",
            "prompt_template": "You are an teaching expert. Create the agenda for a training on  [TOPIC] for [TARGET AUDIENCE'S LEVEL OF EXPERIENCE] (e.g., beginners, intermediates, advanced learners). Please list main entries and sub-entries, with a short description of at most one sentence for each subentry."
        },
        "Write a quizz": {
            "definition": "Generate a quizz for a specific subject and a specific target audience.",
            "prompt_template": "You are an teaching expert. Write a quizz on  [TOPIC] for [TARGET AUDIENCE'S LEVEL OF EXPERIENCE] (e.g., beginners, intermediates, advanced learners). Use single choice questions or multiple choices questions."
        }
    }
}

export const templateTools = [{"name":"Flash News Traffic","id":"get_traffic_report"},{"name":"Flash News Finance","id":"get_finance_report"},{"name":"Airport’s Headline","id":"get_news"},{"name":"Traffic Trend Forecast (OAG)","id":"competition_analysis"},{"name":"Traffic Trend Forecast (Predict)","id":"predict_analyst"}]