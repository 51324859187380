import DOMPurify from "dompurify";
import { useEffect, useRef } from "react";
import { SetupPanelState } from "../../types";
import { ButtonRedirectSDH } from "../../components/ButtonRedirectSDH";
import { LoaderCircle } from "../../components";

interface News {
    ids: string[];
    intro: string;
    section: {
        [key: string]: string;
    };
}

export const CFRNews: React.FC<{ data: News | null, setupData: SetupPanelState | null, loading: boolean }> = ({ data, setupData, loading }) => {
    const newsRef = useRef<HTMLDivElement>(null);

    const createMarkup = (htmlString: string) => {
        return { __html: DOMPurify.sanitize(htmlString) };
    };

    useEffect(() => {
        if (newsRef.current) {
            newsRef.current.scrollTop = 0;
        }
    }, [data]); 

    return (
        <div className="relative lg:w-9/12 w-8/12 p-4 h-full flex flex-col items-center overflow-y-auto" ref={newsRef}>
            {data &&
                <div className="absolute top-3 right-3">
                    <ButtonRedirectSDH id={13}/>
                </div> 
            }
            <div className="lg:w-8/12 w-full py-8">
                {/* Display loading indicator when loading */}
                {loading ? (
                    <LoaderCircle text="One moment please..."/>
                ) : data ? (
                    // Display data if not loading and data is present
                    <>
                        <div className="mb-2" dangerouslySetInnerHTML={createMarkup(data.intro)} />
                        {data.ids.map((id, index) => {
                            const sectionHtml = data.section[id];
                            return sectionHtml ? (
                                <div className="mb-4" key={index} dangerouslySetInnerHTML={createMarkup(sectionHtml)} />
                            ) : null;
                        })}
                    </>
                ) : (
                    // Displayed only if there is no data and not loading
                    <div className="text-center text-text-primary-light ">
                        <h1 className="font-semibold md:text-4xl text-3xl">VINCI Airports</h1>
                        <h2 className="font-medium md:text-3xl text-2xl py-2">Finance Report</h2>
                        <p className="p-8 text-justify">
                            Meet Finance Report, your AI-powered tool designed to help you craft personalized finance newsletters.<br/><br/>
                            Dive into financial data and insights tailored to your preferences by adjusting parameters on the <span className="font-bold">right panel</span>.
                            Start generating your finance newsletter today and transform how you interact with financial news and trends.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );    
};