import { Calendar } from "./components/Calendar";
import { FileLoader } from "./components/FileLoader";
import { FileChecks } from "./components/FileChecks";


export const Ingestion = () =>{
    return(
        <div className="flex flex-col w-full bg-white p-3 rounded-xl items-center text-text-primary-light gap-5">
            <Calendar years={['2023', '2024']}/>
            <FileLoader/>
            <FileChecks/>
        </div>

    )
}