import { useState } from "react"
import { DropdownDropzone } from "./DropdownDropzone"
import { FileTable } from "./FileLoaderTable"

export const FileLoader = () => {
    const [files, setFiles] = useState<File[]>([])
    return(
        <div className="flex flex-col gap-1 text-black w-full">
            <div className="flex flex-col w-full justify-start">
                <h1 className="font-trendaSemiBold text-xl">File Loader</h1>
                <p className="text-sm font-trenda">
                    Uploaded files using the multiple files loader or 
                    files uploaded via SFTP need to be associated to a file type and a version if necessary.
                </p>
            </div>
            <DropdownDropzone files={files} setFiles={setFiles}/>
            {files.length > 0 &&
                <FileTable files={files} setFiles={setFiles}/>
            }
        </div>

    )
}