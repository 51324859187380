import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';

interface CFRSliderType {
    max: number;
    value: number | number[];
    setValue: (value: number | number[]) => void
}

export const CTRSlider = ({ max, value, setValue }: CFRSliderType) => {
    const [tmp, setTmp] = useState<number | number[]>(value)
    useEffect(()=>{
        if (value != tmp)
            setTmp(value)
    }, [value])

    return (
        <div className="py-2">
            <div className="w-full mx-auto px-1">
            <div className="flex justify-center text-md">
                    <span>{`${tmp}`}</span>
                </div>
                <Slider
                    trackStyle={{ backgroundColor: "#0D3D62" }}
                    railStyle={{ backgroundColor: "#0D3D62" }}
                    handleStyle={{
                        borderColor: "#0D3D62",
                        height: 15,
                        width: 15,
                        marginTop: -5,
                        backgroundColor: "#FFFFFF"
                    }}
                    activeDotStyle={{
                        backgroundColor: "#FFFFFF",
                        borderColor: "#0D3D62",
                        boxShadow: 'none'
                    }}
                    min={1}
                    max={max}
                    value={tmp}
                    defaultValue={1}
                    onChange={setTmp}
                    onChangeComplete={setValue}
                />
                <div className="flex justify-between text-xs mt-2">
                    <span>{`1`}</span>
                    <span>{`${max}`}</span>
                </div>
            </div>
        </div>
    )
}