import { defaultStyles, FileIcon } from "react-file-icon";
import { useMultiDocs } from "../../providers/MultiDocsProvider";

export interface UploadFile {
    filename: string;
    type: "txt" | "pdf" | "pptx" | "docx" | "html";
    ocrUsed: boolean
}

export const ManageFiles = ({ }) => {
    const {
        config
    } = useMultiDocs()

    return (
        <div className="flex flex-col w-full font-trendaSemiBold overflow-y-auto">
            <div className="flex flex-col w-full gap-2 max-h-80">
                {config.uploadFiles.map((file, index) => {
                    if (!file.processed)
                        return <></>
                    return (
                        <div className="relative overflow-hidden rounded-xl border flex flex-col justify-between min-h-20 p-2">
                            <div className="flex w-full justify-between" onClick={() => console.log(file.file.name)}>
                                <div className="truncate font-semibold text-sm">{file.file.name.length > 40 ? file.file.name.substring(0, 37) + '...' : file.file.name}</div>
{/*                                 <span className="text-xxs px-2 py-1 cursor-pointer font-trendaSemiBold border-red-500 text-red-500 h-fit rounded hover:shadow-lg border">{"Delete file".toUpperCase()}</span>
 */}                            </div>

                            <div className="flex gap-3">
                                <div className="relative w-10 shrink-0 overflow-hidden rounded-md items-center justify-center content-center">
                                    <FileIcon
                                        extension={file.type.toUpperCase()}
                                        type="document"
                                        {...defaultStyles[file.type]}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    {file.ocrChecked &&
                                        <div className="flex items-center gap-1">
                                            <span className="text-xs cursor-pointer">{"OCR Used".toUpperCase()}</span>
                                            <svg className="w-4 h-4 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        </div>
                                    }
                                </div>
                            </div>


                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}