import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDataGouv } from "../providers/DataGouvProvider";
import { SelectorFileVersion } from "./Selector";
import { ButtonIngestion, ButtonIngestionProps, CustomButton, ErrorButton } from "./ButtonIngestion";
import { FileStatus, FileStatusProps } from "./FileStatus";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { FileData } from "../hooks/useDataIngestion";
import { LinkedFileDropdown } from "./LinkedFileDropdown";
import { useAuth } from "../../../providers";
import { SVGIcon } from "../../../assets";

type KpiData = {
  [key: string]: string[][];
};

export const FileChecksTable = () => {
  const { buSelected, ingestionData } = useDataGouv();
  const [openModalRowId, setOpenModalRowId] = useState<string | null>(null)

  if (!ingestionData || !buSelected) {
    return <div className="mt-3 font-trenda">Loading Data..</div>;
  }
  
  const buOptions = ingestionData![buSelected!] || [];

  const linkedFilesMap: { [key: string]: FileData[] } = {};
  let unlinkedFiles: FileData[] = [];

  buOptions.forEach(file => {
    if (file.linked) {
      if (!linkedFilesMap[file.linked]) {
        linkedFilesMap[file.linked] = [];
      }
      linkedFilesMap[file.linked].push(file);
    }
    else if (file.file_type in linkedFilesMap) {
        linkedFilesMap[file.file_type] = [file, ...(linkedFilesMap[file.file_type] || [])];
      }
    else {
      unlinkedFiles.push(file);
    }
  });

  unlinkedFiles = unlinkedFiles.filter(
    file => !Object.keys(linkedFilesMap).includes(file.file_type)
  );

  return (
    <div className="w-full border border-gray-200 rounded-md overflow-x-scroll no-scrollbar">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-100" style={{ backgroundColor: '#EEEEEE' }}>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File{'\u00A0'}Type</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File{'\u00A0'}Name</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Version</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Date{'\u00A0'}Uploaded</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Uploaded{'\u00A0'}By</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Status</th>
            <th className="text-left text-sm font-medium text-gray-600"></th>
            <th className="text-left text-sm font-medium text-gray-600"></th>
            <th className="text-left text-sm font-medium text-gray-600"></th>
          </tr>
        </thead>
        <tbody>
          {unlinkedFiles.map((file, index) => (
            <FileRow 
              key={`unlinked-${index}`} 
              fileType={file.file_type}
              version={file.version} 
              final_filename={file.final_filename} 
              rowId={`unlinked-${file.file_type}-${file.version || ''}-${index}`}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
            />
          ))}

          {Object.entries(linkedFilesMap).map(([primaryFileType, linkedFiles], index) => (
            <LinkedFileDropdown
              key={`linked-group-${index}`}
              linkedFiles={linkedFiles}
              mainFileName={primaryFileType}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
              rowId={`linked-${primaryFileType}-${primaryFileType || ''}-${index}`} 

            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
interface FileRowProps {
  fileType: string;
  version?: string;
  isDropdown?: boolean
  fileLinked?: File | null
  final_filename?: string | null
  rowId: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
}
 export const FileRow: React.FC<FileRowProps> = ({fileType, version, isDropdown, fileLinked, final_filename, rowId, openModalRowId, setOpenModalRowId}) => {
  const { buSelected, dateSelected, ingestionData , uploadFileFromDropzone, kpis, rejectKPIs} = useDataGouv();
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [isVersioned, setIsVersioned] = useState<boolean>(false);
  const [numVersions, setNumVersions] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandedError, setIsExpandedError] = useState<boolean>(false);
  const [file,setFile] = useState<File | null>(null)
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [lineHeightKpis, setLineHeightKpis] = useState<number>(0);
  const [loaderDownload, setLoaderDownload] = useState(false)
  const {token} = useAuth()
  const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});
  const modalRef = useRef<HTMLDivElement | null>(null);
  const showModal = rowId === openModalRowId; 

  const handleToggleModal = () => {
    if (showModal) {
      setOpenModalRowId(null);
    } else {
      setOpenModalRowId(rowId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (showModal) {
          setOpenModalRowId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleReject = async() =>{
    rejectKPIs(buSelected!, fileType, version!)
    setFile(null)
    setIsExpanded(false)
    setLineHeightKpis(0)
  }

  const handleDownloadTemplate = async() => {
    setLoaderDownload(true)
    try{
      const response = await fetch(`/api/gouvernance/download_template?file_asset=${buSelected}&file_type=${fileType}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if(!response.ok) return
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)      
    }
    catch(error){
      console.log(error)
      return null
    }
    finally{
      setLoaderDownload(false)
    }
  };

  const handleDownload = async(url: string) => {
    setLoadingDownloads((prev) => ({ ...prev, [url]: true }));
    try{
      const response = await fetch(`/api/gouvernance/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({gs_url : url})
      });
      if(!response.ok) return
      const signedUrl = JSON.parse(await response.text());
      const a = document.createElement('a');
      a.href = signedUrl;
      a.download = url.split('/').pop() || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    catch(error){
      console.log(error)
      return null
    }
    finally{
      setLoadingDownloads((prev) => ({ ...prev, [url]: false }));
    }
  };
  
  const toggleRow = () => {
    setIsExpanded(!isExpanded);
  };

  const buData = ingestionData![buSelected!] || [];
  const currentFile = buData.find((file) => (file.file_type === fileType) && (!file.version || file.version === version)) || {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
  };

  const dropdownRef = useRef<HTMLTableRowElement | null>(null);
  const dropdownKpisRef = useRef<HTMLTableRowElement | null>(null);



  useEffect(() => {
    if (fileType === 'BUDGET' || fileType === 'CPXFORECAST' || fileType === 'CAPEX_FORECAST') {
      setIsVersioned(true);
      setNumVersions(version!);
    } else {
      setIsVersioned(false);
    }
    console.log(currentFile.url)
  }, [fileType, currentFile]);

  useEffect(()=>{
    if(file){
      handleConfirmClick()
    }
  },[file])

  useEffect(()=>{
    if(fileLinked){
      if (!buSelected || !fileType) return;
      if (isVersioned) {
          uploadFileFromDropzone(buSelected, fileType, fileLinked!, version);
      } else {
          uploadFileFromDropzone(buSelected, fileType, fileLinked!);
      }
    }
  },[fileLinked])

  useEffect(() => {
    if (dropdownRef.current && (isExpandedError)) {
      const scrollableContentHeight = dropdownRef.current.scrollHeight;
      setLineHeight(scrollableContentHeight); 
    }
  }, [isExpandedError]);

  useEffect(() => {
    if (dropdownKpisRef.current && (isExpanded)) {
      const scrollableContentHeight = dropdownKpisRef.current.scrollHeight;
      setLineHeightKpis(scrollableContentHeight); 
    }
  }, [isExpanded]);

  const handleConfirmClick = () => {
    if (!buSelected || !fileType) return;

    if (isVersioned) {
        uploadFileFromDropzone(buSelected, fileType, file!, version);
    } else {
        uploadFileFromDropzone(buSelected, fileType, file!);
    }
  };

  return (
    <>
      <tr className={`border-b border-gray-200 transition-all duration-300 overflow-x-scroll`}>
        <td className={` ${isDropdown ? 'px-8': 'px-4'} py-1.5 text-sm text-gray-800`}>{fileType}</td>
        <td className="text-xs text-gray-800 flex flex-row items-center">
          <div className="flex items-center justify-center h-full py-2 mt-1">
          {final_filename ? 
            final_filename.substring(0, 15).replaceAll(" ", "\u00A0") + "..."
              : 
              file ? 
              file.name.substring(0, 15).replaceAll(" ", "\u00A0") + "..." 
              : 
              ""
          } 
          </div>         
            {file && currentFile.status.loaded !== "finished" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => setFile(null)}
              className="cursor-pointer"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          )}
        </td>
        <td className="px-4 py-1.5 text-sm text-gray-800">
          {isVersioned && (
            <SelectorFileVersion
              buName={buSelected!}
              year={dateSelected!}
              disabled={true}
              onChange={() => {}}
              version={version}
            />
          )}
        </td>
        <td className="px-4 py-1.5 text-sm text-gray-800">25-04-2024</td>
        <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td>
        <td className="px-4 py-1.5 text-sm text-gray-800">
          <FileStatus status={currentFile.status} />
        </td>

         <td className="px-4 py-1.5 text-sm text-gray-800">
         {isDropdown && (currentFile.status.loaded === 'finished' && currentFile.status.checkfile === 'finished') && <ButtonIngestion onClick={() => setIsExpanded(!isExpanded)} status={currentFile.status} setFile={setFile} />}
         {!isDropdown && <ButtonIngestion onClick={() => setIsExpanded(!isExpanded)} status={currentFile.status} setFile={setFile} />}
        </td>
        {(['loaded', 'checkfile', 'checkKpis', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && (
          <td className="px-4 py-1.5 text-sm text-gray-800">
            <ErrorButton onClick={() => setIsExpandedError(!isExpandedError)} isExpanded={isExpandedError} />
          </td>
        )}
        {!(['loaded', 'checkfile', 'checkKpis', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && <td className="px-4 py-1.5 text-sm text-gray-800"></td>}
        <td className="relative px-4 py-1.5 text-sm text-gray-800">
          <div className="flex flex-col items-center justify-center bg-[#EEEEEE] rounded-md h-[33px] w-[33px] p-1 gap-1 cursor-pointer" onClick={handleToggleModal}>
            <div className="rounded-full w-1 h-1 bg-[#686868]"/>
            <div className="rounded-full w-1 h-1 bg-[#686868]"/>
            <div className="rounded-full w-1 h-1 bg-[#686868]"/>
            {showModal &&
              <div ref={modalRef} className="absolute flex justify-start border-2 border-[#F6F6F6] flex-col items-start top-[40px] shadow-sm right-5 bg-white rounded-xl z-9999 w-[250px] py-3 px-5" style={{zIndex: 9999}}>
                <div className="hover:bg-[#EEEEEE] flex flex-row gap-3 w-full items-center cursor-pointer rounded-xl py-2 px-3" onClick={handleDownloadTemplate}>
                  <SVGIcon icon="FOLDER_DOWNLOAD_ICON"/>
                  <p className="w-full font-medium">Download Template</p>
                </div>
                {currentFile.url && currentFile.url?.length > 0 && 
                <div className="hover:bg-[#EEEEEE] py-2 px-3 rounded-xl flex flex-row gap-3 w-full justify-start items-center cursor-pointer" onClick={()=> handleDownload(currentFile.url!)}>
                  <SVGIcon icon="FOLDER_DOWNLOAD_ICON"/>
                  <p className="w-full font-medium">Download File</p>
                </div>
                }
              </div>
            }
          </div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className={"h-6 w-6 text-black cursor-pointer"}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={handleDownloadTemplate}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M7 10l5 5m0 0l5-5m-5 5V4"
            />
          </svg>
          {showTooltip && (
            <div className="absolute right-10 2xl:right-[65px] bottom-0 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded-md shadow-lg">
              Download{'\u00A0'}Template
            </div>
          )} */}
        </td>
      </tr>
      {isExpandedError && currentFile.error &&
        <tr className="bg-gray-100" ref={dropdownRef} >
          <td colSpan={9} className="">
            <div className="flex flex-row gap-10 w-full justify-start items-start px-6 pb-2">
                  <div className="relative">
                    <div className="w-[2px] bg-gray-400" style={{ height: `${lineHeight}px` }}></div>
                    <div className="absolute  bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full w-2 h-2"></div>
                  </div>
                  <div className="flex flex-col items-center justify-start no-scrollbar gap-1">
                    <p className="text-red-500 text-sm w-full underline">{currentFile.error.title}</p>
                    <p className="text-red-500 text-sm w-full">
                      <div
                        dangerouslySetInnerHTML={{ __html: currentFile.error.description }}
                        className="text-justify text-sm"
                      />
                    </p>
                  </div>
            </div> 
          </td>
        </tr>
      }
      {isExpanded && currentFile.status.checkKpis === 'running' && kpis && (
        <tr className="bg-gray-100" ref={dropdownKpisRef}>
          <td colSpan={9} className="px-4 pb-6">
            <div className="flex flex-row gap-10 w-full overflow-scroll">
              <div className="flex items-center justify-center">
                <div className="relative ml-2">
                  <div className="w-[2px] h-[240px] bg-gray-400"  style={{ height: `${lineHeightKpis}px` }}></div>
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full w-2 h-2"></div>
                </div>
              </div>
              <div className="flex flex-row text-sm gap-4 text-[#686868] w-full">
                <div className="flex flex-col w-full gap-1 mt-2">
                  <p className="font-bold text-black">KPIs</p>
                  {/* Dynamically iterate over each KPI table */}
                  <div className={`flex flex-row w-full gap-3 ${kpis && Object.keys(kpis).length > 2 && 'flex-wrap'} `}>
                    {kpis && Object.keys(kpis).map((kpiKey: string) => (
                      <div key={kpiKey} className="w-full">
                        <p className="font-bold text-black underline mb-2">{kpiKey.replace('_', ' ').toUpperCase()}</p>
                        <table className="table-auto w-full border-collapse">
                          <thead>
                            <tr>
                              {/* Generate headers dynamically */}
                              {kpis[kpiKey][0].map((header: string, index: number) => (
                                <th key={index} className="border px-4 py-2 text-left">
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* Render table rows for each KPI */}
                            {kpis[kpiKey].slice(1).map((row: string[], rowIndex: number) => (
                              <tr key={rowIndex}>
                                {row.map((cell: string, cellIndex: number) => (
                                  <td key={cellIndex} className="border px-4 py-2">
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex gap-4 flex-col w-[70%] items-center justify-center">
                <CustomButton text="Validate KPIs and process file for ingestion" onClick={() => {}} width="w-full" />
                <CustomButton text="Reject KPIs" onClick={handleReject} width="w-full" />
              </div>
            </div>
          </td>
        </tr>
      )}
      
    </>
  );
};

