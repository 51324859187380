import React, { useEffect } from 'react';
import { IngestionData } from '../hooks/useDataIngestion';

interface FileData {
  file_type: string;
  is_version: number;
  num_version: number;
}

interface AssetData {
  [key: string]: FileData[];
}

interface SelectorFileTypeProps {
  options: IngestionData;
  buName: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  setSelectedFileType: React.Dispatch<React.SetStateAction<string | null>>;
  value?: string | null


}
export const SelectorFileType: React.FC<SelectorFileTypeProps> = ({ options, buName, onChange, setSelectedFileType,value}) => {
  const buOptions = options[buName] || [];
  const fileTypeOptions = Array.from(new Set(buOptions.map((option) => option.file_type)));

  // useEffect(() => {
  //   if (fileTypeOptions.length > 0) {
  //       const firstOption = fileTypeOptions[0];
  //       setSelectedFileType(firstOption);
  //   }
  // }, []);

  return (
    <select className="border border-[#DDDDDD] text-black p-1 font-medium text-sm rounded-md focus:outline-none" value={value || ""} onChange={onChange}>
      {buOptions.length > 0 ? (
        fileTypeOptions.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))
      ) : (
        <option disabled>No options available</option>
      )}
    </select>
  );
};

interface SelectorFileVersionProps {
  options?: any;
  buName: string;
  year: string;
  disabled: boolean;
  version?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectorFileVersion: React.FC<SelectorFileVersionProps> = ({ buName, year, disabled, version, onChange }) => {
  const maxVersion = 3;

  const versions = [`B0`];
  for (let i = 1; i <= maxVersion; i++) {
    versions.push(`R${i}${year}`);
  }

  if (disabled && version) {
    return (
      <select className="border border-[#DDDDDD] text-black p-1 rounded-md focus:outline-none opacity-50" disabled>
        <option value={version}>{version} {year}</option>
      </select>
    );
  }

  return (
    <select className={`border border-[#DDDDDD] text-black p-1 rounded-md focus:outline-none ${disabled && 'opacity-50'}`} disabled={disabled} onChange={onChange}>
      {versions.length > 0 ? (
        versions.map((version, index) => (
          <option value={version} key={index}>
            {version}
          </option>
        ))
      ) : (
        <option disabled>No versions available</option>
      )}
    </select>
  );
};
