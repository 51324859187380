import { useEffect, useState } from "react";
import { DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";
import { SVGIcon } from "../../../../assets";
import { LoaderCircle } from "../../../../components";
import DropzoneLargeDocNew from "../../../LargeDoc/DropzoneLargeDocNew";
import { useMultiDocs } from "../../providers/MultiDocsProvider";
import { getUploadFileType } from "../../hooks/useMultiDocsConfig";
import { useChatGPT } from "../../providers";

export const UploadDocuments = ({ }) => {
    const [loaderLongUpload, setLoaderLongUpload] = useState<boolean>(false)
    const {modalStatus, setModalStatus, gptGesture} = useChatGPT()
    const {
        config
    } = useMultiDocs()

    const updateOCRChecked = (index: number, checked: boolean) => {
        const updatedFiles = config.uploadFiles.map((f, i) =>
            i === index ? { ...f, ocrChecked: checked } : f
        );
        config.setUploadFiles(updatedFiles);
    };

    const updateSummaryChecked = (index: number, checked: boolean) => {
        const updatedFiles = config.uploadFiles.map((f, i) =>
            i === index ? { ...f, summaryChecked: checked } : f
        );
        config.setUploadFiles(updatedFiles);
    };

    //REMOVE
    const [uploadLoader, setUploadLoader] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)

    const removeFile = (fileName: string) => {
        const updatedFiles = config.uploadFiles.filter(f => f.file.name !== fileName);
        config.setUploadFiles(updatedFiles);
    };

    return (
        <div className="flex flex-col w-full">
            <DropzoneLargeDocNew loaderLong={loaderLongUpload} setFiles={config.setUploadFiles} files={config.uploadFiles} />
            {
                config.uploadFiles.length > 0 &&
                <div className="flex flex-col w-full mt-4 gap-2 max-h-80 overflow-y-auto">
                    {config.uploadFiles.map((file, index) => {
                        if (file.processed)
                            return <></>
                        return (
                            <div className={`relative overflow-hidden rounded-xl border flex justify-between min-h-20`}>
                                <div className="flex flex-row items-center gap-3 p-2">
                                    <div className="relative w-10 shrink-0 overflow-hidden rounded-md">
                                        <FileIcon
                                            extension={getUploadFileType(file.file.type).toUpperCase()}
                                            type="document"
                                            {...defaultStyles[getUploadFileType(file.file.type)]}
                                        />
                                    </div>
                                    <div className="overflow-hidden">
                                        <div className="truncate font-semibold">{file.file.name.length < 35 ? file.file.name : file.file.name.substring(0, 33) + "..."}</div>
                                        <div className="flex gap-4 pt-2">
                                            <div className="flex flex-row justify-center gap-1.5 items-center">
                                                <div onClick={() => updateSummaryChecked(index, !file.summaryChecked)} className="relative cursor-pointer">
                                                    <input
                                                        id="summaryRadio"
                                                        className="opacity-0 absolute h-5 w-5  cursor-pointer"
                                                        type="radio"
                                                        name="summary"
                                                        checked={file.summaryChecked}
                                                    />
                                                    <div className=" cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded">
                                                        {file.summaryChecked && (
                                                            <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span className="text-xs cursor-pointer font-trendaSemiBold">{"Generate summary".toUpperCase()}</span>
                                            </div>
                                            {getUploadFileType(file.file.type) == "pdf" &&
                                                <div className="flex flex-row justify-center gap-1.5 items-center">
                                                    <div onClick={() => updateOCRChecked(index, !file.ocrChecked)} className="relative cursor-pointer">
                                                        <input
                                                            id="ocrRadio"
                                                            className="opacity-0 absolute h-5 w-5  cursor-pointer"
                                                            type="radio"
                                                            name="ocr"
                                                            checked={file.ocrChecked}
                                                        />
                                                        <div className=" cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded">
                                                            {file.ocrChecked && (
                                                                <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path d="M5 13l4 4L19 7"></path>
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <span className="text-xs cursor-pointer font-trendaSemiBold">{"Use OCR".toUpperCase()}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3">
                                    <svg onClick={() => removeFile(file.file.name)} className="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            }
            {
                config.uploadFiles.length > 0 &&
                <div className="flex flex-row justify-end w-full h-full overflow-hidden pt-4">
                    <button
                        onClick={async () => {await config.uploadDocuments();setModalStatus("CLOSE")}}
                        className={`flex min-w-48 justify-center mb-4 px-4 py-2 text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none ${config.uploadDocumentLoader ? "bg-emerald-500" : "bg-white"}`}>
                        {
                            config.uploadDocumentLoader ?
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> : <div className="flex items-center gap-1"><span>Upload documents</span> {!uploadLoader && <SVGIcon size="20px" icon="ARROW_SMALL_ICON" />}</div>}
                    </button>
                </div>
            }
        </div>
    )
}