import { useCallback, useEffect, useRef, useState } from "react";


export interface ChatGPTGestureHook {
    endOfMessagesRef: React.RefObject<HTMLDivElement>;
    scrollToEndOfMessages: () => void;
    isAtBottom: boolean;
    isMounted: boolean;
    setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useChatGPTGesture = (): ChatGPTGestureHook => {

    const endOfMessagesRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    // Put in provider this
    const [isMounted, setIsMounted] = useState(false);


    const scrollToEndOfMessages = () => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (!isMounted) return;

        const handleScroll = () => {
            const container = endOfMessagesRef.current?.parentElement?.parentElement;
            if (container) {
                const isAtBottom = container.scrollHeight - container.scrollTop - 10 <= container.clientHeight;
                setIsAtBottom(isAtBottom);
            }
        };

        const container = endOfMessagesRef.current?.parentElement?.parentElement;

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isMounted]);

    return { endOfMessagesRef, scrollToEndOfMessages, isAtBottom, isMounted, setIsMounted };
};

