import React, { useEffect, useState, useRef } from "react";
import { ChatLogo } from "../../assets/SVGIcon/ChatLogo";
import { TypeToIcon } from "../../components";
import { useTypingEffect } from "../../hooks/typingEffect";
import { SVGIcon } from "../../assets";
import { SelectOptionsNew } from "../DocumentTranslator/SelectedOptions";
import { ButtonRedirectSDH } from "../../components/ButtonRedirectSDH";
import { LoaderComponent } from "../../components";
import { useSessionId } from "../../hooks";
import { useAuth } from "../../providers";
import { useOAGConfig } from "../../hooks/competitionAnalysis";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { LoaderBlue } from "../../components";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

export const languageOptions = {
    "EN": "English",
    "ES": "Spanish",
    "FR": "French",
    "PT": "Portuguese",
    "JA": "Japanese"
};

export const CompetitionAnalysis = () => {
    const icon = TypeToIcon['IA_OAG'];
    const sessionId = useSessionId('/api/competition_analyst/clear_session')
    const { token } = useAuth()
    const { config, loadingConfig, loading, analysis, setAnalysis, analyse } = useOAGConfig(token, sessionId)
    const [vinciAirport, setVinciAirport] = useState('')
    const [dateRangeA, setDateRangeA] = useState('')
    const [dateRangeB, setDateRangeB] = useState('')
    const [dateRangeBOptions, setDateRangeBOptions] = useState<string[]>([])
    const [competitorAirport, setCompetitorAirport] = useState('')
    const [language, setLanguage] = useState('EN')
    const [competitorOptions, setCompetitorOptions] = useState<string[]>([])
    const [askSentence, setAskSentence] = useState('')
    const [answerSentence, setAnswerSentence] = useState('')
    const [typeText1] = useTypingEffect(askSentence, 30)
    const [typeText2] = useTypingEffect(answerSentence.length > 0 ? answerSentence : "Competition analysis", 30)
    const topRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (topRef.current && analysis.length > 0) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [analysis]);


    useEffect(() => {
        if (config && vinciAirport.length > 0) {
            setCompetitorOptions(config?.competitor_airports[vinciAirport]!)
            setCompetitorAirport('');
        }
    }, [vinciAirport, config])

    useEffect(() => {
        if (config && !loadingConfig) {
            if (config.months) {
                let keys_list = Object.keys(config.months)
                setDateRangeA(keys_list[0]);
                setDateRangeB(keys_list[1]);
            }
        }
    }, [config, loadingConfig]);

    function filterDatesAfter(startDate: string, dates: string[]): string[] {
        if (!config)
            return []
        const start = new Date(config.months[startDate]);

        // Filtrer le tableau pour ne garder que les dates après la date de départ
        const filteredDates = dates.filter(date => new Date(date) > start);

        return filteredDates;
    }


    useEffect(() => {
        if (config && config.months && dateRangeA) {


            const newToDateOptions = filterDatesAfter(dateRangeA, Object.values(config.months))
            setDateRangeBOptions(newToDateOptions);

            if (!newToDateOptions.includes(dateRangeB)) {
                setDateRangeB(getKeyByValue(newToDateOptions[0], Object.entries(config.months)));
            }
        }
    }, [dateRangeA, config?.months]);



    const handleSubmit = () => {
        if (dateRangeA === dateRangeB) return;
        if (!loading && vinciAirport.length > 0 && dateRangeA.length > 0 && dateRangeB.length > 0 && competitorAirport.length > 0) {
            setAnalysis('')
            buildAnswerSentence(vinciAirport, competitorAirport)
            analyse(sessionId, vinciAirport, competitorAirport, dateRangeA, dateRangeB, language)
        }
    }

    const renderAirportOption = (airportCode: string) => {
        const airportName = config!.airport_recoding[airportCode];
        return { value: airportCode, label: airportCode + " - " + airportName };
    };

    const renderLanguageOption = (languageCode: string) => {
        if (config) {
            let a = getKeyByValue(languageCode, Object.entries(languageOptions))
            return { value: a, label: `${languageCode}` };
        }
        return { value: languageCode, label: `${languageCode}` };
    };

    function getKeyByValue(searchValue: string, arr: [string, string][]): string {
        for (const [key, value] of arr) {
            if (value === searchValue) {
                return key;
            }
        }
        return searchValue;
    }

    const renderOption = (option: string) => {
        if (config) {
            let a = getKeyByValue(option, Object.entries(config.months))
            return { value: a, label: `Week ${a} - ${option}` };
        }
        return { value: option, label: `Week ${option} - ${option}` };
    };

    const handleChangeAirport = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setVinciAirport(event.target.value)
    };
    const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(event.target.value)
    };
    const handleChangeDateA = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDateRangeA(event.target.value)
    };
    const handleChangeDateB = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDateRangeB(event.target.value)
    };
    const handleChangeCompetitor = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCompetitorAirport(event.target.value)
    };

    const reset = () => {
        if (loading || loadingConfig) return
        setVinciAirport('')
        setCompetitorAirport('')
        setAskSentence('')
        setAnswerSentence('')
        setAnalysis('')
        if (config && !loadingConfig) {
            if (config.months) {
                let keys_list = Object.keys(config.months)
                const firstMonth = keys_list[0];
                setDateRangeA(firstMonth);
                setDateRangeB(firstMonth);
            }
        }
    }

    const buildAskSentence = (airportA: string, airportB: string, dateRangeA: string, dateRangeB: string) => {
        const sentence = `Generate the competition analysis between ${airportA} and ${airportB} from the week ${dateRangeA} to ${dateRangeB}`;
        setAskSentence(sentence)
    }

    const buildAnswerSentence = (airportA: string, airportB: string) => {
        const sentence = `Here is the OAG analysis between ${airportA} and ${airportB}`
        setAnswerSentence(sentence)
    }

    useEffect(() => {
        if (config && vinciAirport.length > 0 && !config?.competitor_airports[vinciAirport]!.includes(competitorAirport)) return
        if (vinciAirport.length > 0 && dateRangeA.length > 0 && dateRangeB.length > 0 && competitorAirport.length > 0) {
            buildAskSentence(vinciAirport, competitorAirport, dateRangeA, dateRangeB)
        }
    }, [vinciAirport, dateRangeA, dateRangeB, competitorAirport, config])

    const [isCopied, setIsCopied] = useState<boolean>(false);

    function extractCleanTextFromHtml(htmlString: string): string {
        // Créer un élément div temporaire pour charger le HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
    
        // Supprimer tous les éléments <style>
        tempDiv.querySelectorAll('style').forEach(style => style.remove());
    
        // Supprimer tous les attributs de style
        Array.from(tempDiv.querySelectorAll('*')).forEach(el => {
            el.removeAttribute('style'); // Retire les attributs de style
            el.removeAttribute('class'); // Retire les attributs de classe
        });
    
        // Ajouter des sauts de ligne pour les paragraphes et les éléments de liste
        Array.from(tempDiv.querySelectorAll('p, li')).forEach(element => {
            element.textContent += '\n'; // Ajoute un saut de ligne après le texte de chaque paragraphe ou élément de liste
        });
    
        // Extraire et retourner le texte nettoyé avec les sauts de ligne ajoutés
        return tempDiv.textContent || tempDiv.innerText || "";
    }

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(extractCleanTextFromHtml(analysis));
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setIsCopied(false);
        }
    };


    return (
        <LoaderComponent loadingConfig={loadingConfig}>
            <div className="flex flex-col items-center">
                <div className="p-5 justify-center items-center text-center font-bold">
                    <h1 className="text-3xl text-[#0D3D62] font-trendaSemiBold">Competition Analysis</h1>
                </div>
                <div className="flex flex-row w-full p-4 gap-6 h-full">
                    <div className="relative flex flex-col p-2.5 w-2/5 border h-full rounded-xl border-[#CFCFCF80] overflow-auto">
                        <p className="font-[#2E2E2E] font-bold">Parameters</p>
                        <div className="flex flex-col items-start p-1 w-full mt-3">
                            <p className="text-sm">Select a VINCI Airport</p>
                            <SelectOptionsNew<string>
                                defaultValue='-'
                                options={config?.vinci_airports!}
                                renderOption={renderAirportOption}
                                selectedValue={vinciAirport}
                                onChange={handleChangeAirport}
                            />
                        </div>
                        <div className="flex flex-col items-start p-1 w-full mt-3">
                            <p className="text-sm">Select a language</p>
                            <SelectOptionsNew<string>
                                defaultValue='EN'
                                options={Object.values(languageOptions)}
                                renderOption={renderLanguageOption}
                                selectedValue={language}
                                onChange={handleChangeLanguage}
                            />
                        </div>
                        <div className="flex flex-col items-start p-1 w-full mt-3">
                            <p className="text-sm">Date Range</p>
                            <div className="flex flex-row items-center w-full gap-2">
                                <SelectOptionsNew<string>
                                    defaultValue={config ? Object.values(config.months)[0] : ""}
                                    options={config ? Object.values(config.months) : []}
                                    renderOption={renderOption}
                                    selectedValue={dateRangeA}
                                    onChange={handleChangeDateA}
                                />
                                -
                                <SelectOptionsNew<string>
                                    defaultValue={config?.months[0]!}
                                    options={dateRangeBOptions}
                                    renderOption={renderOption}
                                    selectedValue={dateRangeB}
                                    onChange={handleChangeDateB}
                                />
                            </div>
                        </div>
                        <div className="border border-[#DDDDDD] border-1 mt-8" />
                        <p className="font-[#2E2E2E] font-bold mt-2">Competitor airport</p>
                        <div className="flex flex-col items-start p-1 w-full mt-3">
                            <p className="text-sm">Select a competitor airport</p>
                            <SelectOptionsNew<string>
                                defaultValue="-"
                                options={competitorOptions}
                                renderOption={renderAirportOption}
                                selectedValue={competitorAirport}
                                onChange={handleChangeCompetitor}
                            />
                        </div>
                        <div className="border border-[#DDDDDD] border-1 mt-8" />
                        <div className="w-full flex flex-row p-1 pt-5 gap-3">
                            <div onClick={reset} className="w-full cursor-pointer flex flex-row text-sm p-2 border border-[#CFCFCF80] rounded-lg bg-transparent justify-center items-center hover:shadow-xl">
                                Reset
                            </div>
                            <div onClick={handleSubmit} className="w-full cursor-pointer flex flex-row gap-3 text-sm p-2 border border-[#CFCFCF80] bg-white rounded-lg justify-center items-center hover:shadow-xl">
                                <p className="w-full">Generate&nbsp;analysis</p>
                                <SVGIcon size="20px" icon="ARROW_SMALL_ICON" />
                            </div>
                        </div>
                    </div>
                    <div className='bg-white w-full p-5 rounded-xl'>
                        <div className="flex flex-col p-1 h-full">
                            <div className="flex items-start mb-2">
                                <div className="flex items-start">
                                    <ChatLogo icon="USER_CHAT_ICON" />
                                </div>
                                <p className="text-sm pl-3 text-[#4A4A4A] mt-2 items-center">
                                    {
                                        askSentence.length > 0 ?

                                            typeText1
                                            :
                                            <div className="mt-1">
                                                <span className="relative flex h-3 w-3">
                                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                                                </span>
                                            </div>
                                    }
                                </p>
                            </div>
                            <div className={`flex flex-col px-6 py-3 shadow-md rounded-xl bg-[#0D3D62] bg-opacity-5 h-full`}>
                                <div className="flex mb-4 items-center justify-start">
                                    <ChatLogo icon={icon} />
                                    <p className="flex text-sm pl-3 text-[#4A4A4A]">{typeText2}</p>
                                </div>
                                <div className={`p-4 cursor-pointer ${analysis.length > 0 ? 'bg-white max-h-[500px] overflow-scroll scrollbar-hide' : 'bg-[#CFCFCF80] h-full'} rounded-xl transition-colors duration-400 ease-in-out`}>
                                    <div ref={topRef} />
                                    {/* <p>{answerOAG.length > 0 ? "Competition Analysis" : answerOAG }</p> */}
                                    {(analysis.length === 0) &&
                                        <div className="flex flex-col justify-center items-center h-full opacity-70 overflow-hidden">
                                            {loading ? <LoaderBlue /> : <SVGIcon size="200px" icon="BIG_VINCI_ICON" className="w-full h-full" />}
                                        </div>
                                    }
                                    {analysis.length > 0 && !loading &&
                                        <>
                                            <div className="relative markdown text-sm leading-normal">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                    components={{
                                                        ul: ({ node, ...props }) => <ul style={{ paddingTop: "10px", paddingBottom: "10px" }} {...props} />,
                                                        li: ({ node, ...props }) => <li style={{ fontSize: "17px", paddingTop: "2px", marginLeft: "20px", textAlign: "justify" }} {...props} />
                                                    }}
                                                >
                                                    {analysis}
                                                </ReactMarkdown>                                            </div>
                                            <div className="flex flex-row gap-2 items-center text-sm justify-end">
                                                {isCopied ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" >
                                                        <polyline points="20 6 9 17 4 12"></polyline> {/* Checked icon */}
                                                    </svg>
                                                ) : (
                                                    <svg onClick={copyTextToClipboard} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> {/* Copy icon */}
                                                    </svg>
                                                )}
                                            </div>
                                        </>

                                    }
                                </div>
                                <div className="flex flex-row items-start w-full pt-5 gap-4">
                                    <ButtonRedirectSDH id={5} />
                                    <ButtonRedirectSDH id={11} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoaderComponent>

    )
}