import React, { useEffect, useState, useRef } from 'react';
import { useActiveTabs, useAuth } from '../../providers';
import { LoaderComponent, LoaderSDH } from '../../components';

export const AIFactory = ({ appName, id }: { appName: string, id: number }) => {
  const [streamlitUrl, setStreamlitUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { token } = useAuth();
  const { closeTab } = useActiveTabs();

  useEffect(() => {
    if (token) {
      const awakeStreamlit = async () => {
        try {
          const pingResponse = await fetch(`/api/streamlit/ping/single`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              appName: appName
            }),
          });

          if (pingResponse.ok) {
            const response = await fetch(`/api/streamlit/url?appName=${appName}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });

            const data = await response.json();
            const iframeUrl = data.url;
            setStreamlitUrl(iframeUrl);
            setLoading(false);
          } else {
            console.error(`Le ping a échoué avec le statut ${pingResponse.status}`);
            setLoading(false);
            closeTab(id);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL Streamlit :', error);
          setLoading(false);
          closeTab(id);
        }
      };

      awakeStreamlit();
    }
  }, [token]);

  useEffect(() => {
    // Message handler for Streamlit communication
    const handleMessage = (event: MessageEvent) => {
      // Verify the origin matches your Streamlit app URL
      if (streamlitUrl && !event.origin.startsWith(new URL(streamlitUrl).origin)) return;

      try {
        const data = event.data;

        // Handle the close message from Streamlit
        if (data.type === 'closeTab') {
          closeTab(id);
        }
      } catch (error) {
        console.warn('Error handling Streamlit message:', error);
      }
    };

    // Add message listener
    window.addEventListener('message', handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [closeTab, id, streamlitUrl]);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <LoaderComponent loadingConfig={loading}>
        <></>
      </LoaderComponent>
      {streamlitUrl && (
        <iframe
          ref={iframeRef}
          src={streamlitUrl}
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
          allow="clipboard-read; clipboard-write; fullscreen; geolocation"
          style={{ height: "100%", width: "100%" }}
          title="Streamlit App"
          className={`border border-gray-300 rounded-md ${loading && "hidden"}`}
          onLoad={() => {
            console.log('Streamlit iframe loaded or reloaded');
          }}
          onError={() => {
            console.error('Streamlit iframe failed to load');
          }}
        />
      )}
    </div>
  );
};

export default AIFactory;
