import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import { SetupPanelState } from "../../types";
import { News } from "../../hooks/customTrafficReport";
import { ButtonRedirectSDH } from "../../components/ButtonRedirectSDH";
import { LoaderCircle } from "../../components";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

export const CTRNews: React.FC<{ data: News | null, loading: boolean, loader: boolean }> = ({ data, loading, loader }) => {
    const newsRef = useRef<HTMLDivElement>(null);
    const [linksLoading, setLinksLoading] = useState(true);

    const createMarkup = (htmlString: string) => {
        return { __html: DOMPurify.sanitize(htmlString) };
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLinksLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [data]);

    useEffect(() => {
        if (newsRef.current) {
            newsRef.current.scrollTop = 0;
        }
    }, [data]);

    return (
        <div className="relative lg:w-9/12 w-8/12 p-4 h-full flex flex-col items-center overflow-y-auto" ref={newsRef}>
            {data &&
                <div className="absolute top-3 right-3">
                    <ButtonRedirectSDH id={11} />
                </div>
            }
            <div className="lg:w-8/12 w-full py-8">
                {loader ? (
                    <LoaderCircle text="One moment please..." />
                ) : data ? (
                    <>
                        <div className="mb-2" dangerouslySetInnerHTML={createMarkup(data.intro)} />
                        {data.ids.map((id, index) => {
                            const sectionHtml = data.section[id];
                            return sectionHtml ? (
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                    components={{
                                        a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ fontStyle: "italic",color: linksLoading ? 'grey' : '#004489', pointerEvents: linksLoading ? 'none' : 'auto' }} />,
                                        ul: ({ node, ...props }) => <ul style={{ paddingTop: "10px", paddingBottom: "10px"}} {...props} />,
                                        li: ({ node, ...props }) => <li style={{ fontSize: "17px", paddingTop: "2px", marginLeft: "20px", textAlign: "justify"}} {...props} />
                                    }}
                                >
                                    {sectionHtml}
                                </ReactMarkdown>
                            ) : null;
                        })}

                    </>
                )
                    :
                    <div className="text-center text-text-primary-light ">
                        <h1 className="font-semibold md:text-4xl text-3xl">VINCI Airports</h1>
                        <h2 className="font-medium md:text-3xl text-2xl py-2">Traffic Report</h2>
                        <p className="p-8 text-justify">
                            Meet Traffic Report, your AI-powered tool designed to help you craft personalized traffic newsletters.<br /><br />
                            Dive into traffic data and insights tailored to your preferences by adjusting parameters on the <span className="font-bold">right panel</span>.
                            Start generating your traffic newsletter today and transform how you interact with traffic news and trends.
                        </p>
                    </div>
                }
            </div>
        </div>
    );
};