import { useEffect, useState } from "react"
import { DataTableQuality } from "./DataTable"
import { useDataGouv } from "./providers/DataGouvProvider"
import { useDataQuality } from "./hooks/useDataQuality"
import { useActiveTabs, useAuth } from "../../providers"
import { SelectOptions, SelectOptionsDataGouv } from "../DocumentTranslator/SelectedOptions"
import { Ingestion } from "./Ingestion"
import { Tile } from "powerbi-client"
import { DataGouvHome } from "./DataGouvHome"
import { SVGIcon } from "../../assets"
import { Referentiel } from "./Referentiel"

interface BUProps {
    title: string
}

const navOpt = [
    {
        id: 'Quality',
        title: "Quality",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Ingestion',
        title: "Ingestion",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Referentiel',
        title: "Referentiel",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Data Linéage',
        title: "Data Linéage",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    }
]

export const BUMainApp: React.FC<BUProps> = ({title}) => {
    const {buSelected, setBuSelected, dateSelected, setUseCase ,setDateSelected, buOptions, dateOptions, getQualityDetailForBu, app, setApp} = useDataGouv()
    const {token} = useAuth()
    const [dataForTable, setDataForTable] = useState<any[]>([])
    const [filterStatus, setFilterStatus] = useState<string | null>(null)
    const [loaderTable, setLoaderTable] = useState<boolean>(false)
    const {openTab} = useActiveTabs() 

    useEffect(()=>{
        if(title && title.length > 0){
            setUseCase(title)
        }
    },[title])
    

    const defaultTab = {
        id: 50,
        appId: "GenAI/SecureChatGPT",
        title: "Overview",
        icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
        description: [],
        app: () => <DataGouvHome/>,  
    }

    const setUpTable = async() =>{
        setLoaderTable(true)
        const data = await getQualityDetailForBu(buSelected!,title.toLocaleUpperCase(), parseInt(dateSelected!))
        setDataForTable(data)
        setLoaderTable(false)
    }

    useEffect(()=>{
        setUpTable()
    },[buSelected,dateSelected])

    const filteredData = filterStatus && filterStatus !== "All"
        ? dataForTable.filter(item => item.status === filterStatus)
        : dataForTable

    return(
        <div className="flex flex-col h-full items-center px-4 py-6">
            <div className="flex flex-col items-center justify-center p-3 text-text-primary-light w-full">
                <div className="flex flex-row w-full justify-start">
                    <div className="flex flex-col gap-4 w-full "> 
                        <div className="flex flex-row w-fit items-center px-2 py-1 border border-[#DDDDDD] rounded-xl text-black font-trenda text-sm">
                            <button className="flex items-center mr-4" onClick={() => openTab(defaultTab)}>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="rotate-90"
                                width="18"
                                height="18"
                                fill={'#EEEEEE'}
                                viewBox="0 0 24 24"
                                >
                                    <path
                                    fill='black'
                                    fillRule="evenodd"
                                    d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                                    clipRule="evenodd"
                                    ></path>
                                </svg> 
                                Overview
                            </button>
                            <div className="flex items-center rounded-xl w-fit cursor-pointer">
                                {navOpt.map((e, i) => {
                                    const isCurrentApp = e.id === app;
                                    const bgColorClass = isCurrentApp ? `bg-gray-200` : 'text-[#2E2E2E] opacity-50';
                                    const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                                    return (
                                        <div key={i} className={`text-center px-4 py-1.5 ${bgColorClass} ${transitionClass} rounded-md`} onClick={() => setApp(e.id)}>
                                            <h3 className="font-medium">{e.title}</h3>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-start p-1 mb-1 mt-2 gap-2">
                <p className="font-trendaSemiBold text-xl w-full text-black">{app} {buSelected} - {title}</p>
                <h1 className="text-black font-trenda">BU{'\u00A0'}Selection:</h1>
                  <SelectOptionsDataGouv
                  options={buOptions}
                  defaultValue="BU Selection"
                  renderOption={(method) => ({ value: method, label: method })}
                  selectedValue={buSelected!}
                  onChange={(e) =>{setBuSelected(e.target.value)} }
                />
            </div>
            {app === 'Ingestion' &&
                <Ingestion/>
             }
            {app === 'Quality' && <DataTableQuality data={filteredData} loader={loaderTable} filterStatus={filterStatus} setFilterStatus={setFilterStatus} />}
            {app === 'Referentiel' && <Referentiel/> }
            {app !== 'Ingestion' && app !== 'Quality' && app !== 'Referentiel' && 
                <div className="text-center">
                    <h2 className="text-2xl font-bold">{app} Section</h2>
                    <p>This section is under construction for {app}.</p>
                </div>
            }
        </div>

    )
}