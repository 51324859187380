import React, { useEffect, useRef, useState } from "react";
import { SVGIcon } from "../../assets";
import { PromptBubble } from "../../components";
import { messageTMM,messageGPT } from "../../hooks";
import { ChatLogoIcon } from "../../assets/SVGIcon/icons";
import { useTypingEffect } from "../../hooks/typingEffect";


interface IChatInputProps {
  onSendMessage: (message: string) => void;
  handleInputClick: () => void;
  input: string;
  setInput: (text: string) => void;
  prompts?: string[] | null;
  reloadPrompt?: (() => Promise<void>) | undefined
  ask?: ((text: string) => Promise<void>) | undefined | (() => void)
  loading : boolean;
  app?: "SDH" | "GPT";
  resetMessages: React.Dispatch<React.SetStateAction<messageTMM[] | messageGPT[]>>
  resetText? : string;
  placeHolderMess? : string;
  typedText? : string;
  handlePromptClick?: (prompt: string) => void
  disabled? : boolean;
  activePlugin? : string;
  resetDoc?: boolean;
  setResetDoc?: React.Dispatch<React.SetStateAction<boolean>>;

}


export const ChatInput: React.FC<IChatInputProps> = ({typedText,activePlugin,disabled,handlePromptClick ,resetMessages,onSendMessage, handleInputClick, input, setInput, prompts, reloadPrompt, ask ,loading, app,resetText,placeHolderMess,resetDoc,setResetDoc}) => {
  const characterLimit = 4000;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(()=>{
    if(activePlugin && activePlugin?.length > 0) {
      setInput('')
    }
  },[activePlugin])

  const handleSubmit = (e?: React.FormEvent) => {
    if(e) e.preventDefault();
    if(loading) return
    if(input.length === 0) return
    onSendMessage(input);
    setInput("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey && input.length > 0) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const maxHeight = 250; // Max height in pixels before scrolling
  const minHeight = 80; // Initial height of the textarea in pixels

  useEffect(() => {
    const resizeTextArea = () => {
      const textArea = textAreaRef.current;
      if (textArea) {
        textArea.style.height = `${minHeight}px`; 
        const newHeight = Math.max(textArea.scrollHeight, minHeight);
        textArea.style.height = `${newHeight}px`;
        if (newHeight >= maxHeight) {
          textArea.style.height = `${maxHeight}px`;
          textArea.style.overflowY = "auto";
        } else {
          textArea.style.overflowY = "hidden";
        }
      }
    };
    resizeTextArea();
  }, [input, maxHeight, minHeight]);

  useEffect(() => {
    const handleGlobalKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        if (!loading && input.length > 0) {
          handleSubmit();
        }
      }
    };
    document.addEventListener('keydown', handleGlobalKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [loading, input, handleSubmit]);

  return (
    <div className="flex flex-col w-full">
    <PromptBubble resetDoc={resetDoc} setResetDoc={setResetDoc} setInput={setInput} app={app!} resetMessages={resetMessages} prompts={prompts!} reloadPrompt={reloadPrompt} ask={ask} resetText={resetText}/>
      
      <form onSubmit={handleSubmit} ref={formRef} className="flex chat-input mb-4 w-full justify-center">
        <div className="relative w-11/12">
          <textarea
            disabled={disabled}
            ref={textAreaRef}
            value={input}
            onChange={(e)=> setInput(e.target.value)}
            onClick={handleInputClick}
            onKeyDown={handleKeyDown}
            className="form-control block w-full pl-3 pr-24 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none overflow-hidden resize-none"
            placeholder={placeHolderMess ? placeHolderMess :"Ask something..."}
            rows={3}
            style={{ maxHeight: "7rem", overflowY: "auto" }}
          >
          </textarea>

          <span className="absolute top-3 right-2 text-sm text-gray-500">
            {loading ?  
                <svg className="motion-reduce:hidden animate-spin" viewBox="0 0 24 24"/>
                : 
              <button type="submit" disabled={loading || (input.length === 0)} className="send-button">
                <SVGIcon icon={"PAPER_PLANE_ICON"} />
              </button> 
            }
          </span>
          <span className="absolute right-2 bottom-4 text-sm text-gray-500">
            {`${input.length}/${characterLimit}`}
          </span>
        </div>
      </form>
    </div>
  );
};
