import { Tabs } from "../../assets";
import { useActiveTabs, useAuth } from "../../providers";
import { FavCard } from "../FavCard";

interface HomeAppPreviewProps {
  showTitle?: boolean;
  titleOpacity: number;
  isAbsolute?: boolean
}

export const HomeAppPreview: React.FC<HomeAppPreviewProps> = ({
  showTitle = true,
  titleOpacity,
  isAbsolute = false
}) => {
  const { openTab, returnFav } = useActiveTabs();
  const { isAuthenticated } = useAuth()
  const fav = returnFav([1, 2, 10, 11]);
  const firstRow = fav.slice(0, 2);
  const secondRow = fav.slice(2);
  const renderNumbers = (tabsArr: Tabs[]) =>
    tabsArr.map((tabs, id) => (
      <div className=" min-h-32" key={id} onClick={() => openTab(tabs)}>
        <FavCard
          title={tabs.title}
          content={tabs.description || ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]}
          icon={tabs.icon}
        />
      </div>
    ));
  if (!showTitle) {
    return <></>;
  }
  return (
    <div className="flex w-full justify-center">
      <div className={`top-32 flex max-w-full w-11/12 justify-center flex-col  lg:flex-row`}>
        <div className="flex-1 flex items-center justify-between content-between flex-col px-3 py-4 pr-12">
          <div className="w-full text-text-primary-light">
            <h1 className="font-semibold md:text-5xl text-4xl">VINCI Airports</h1>
            <h2 className="font-medium md:text-4xl text-3xl py-4">Smart Data Hub</h2>
          </div>
          <div className="text-text-primary-light">
            <p className="text-lg font-medium text-justify">Welcome to our group of beta testers for the deployment of the Generative AI functionalities.{" "}</p>
            <p className="font-light text-base text-justify">In this beta testing phase, you will have the opportunity to explore and provide feedback on the latest cutting-edge features of Gen AI. Your insights will play a crucial role in shaping the future of our platform and ensuring that we deliver the best user experience possible.<br />To help you get started and make the most out of this testing phase, we have prepared a platform with different functionalities.</p>
          </div>
        </div>
        {isAuthenticated &&
          <div className="flex flex-1 justify-center flex-col px-3 py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">{renderNumbers(firstRow)}</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 pt-4">{renderNumbers(secondRow)}</div>
          </div>
        }
      </div>
    </div>
  );
};
