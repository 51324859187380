import { FileChecksTable } from "./FileChecksTable"

export const FileChecks = () => {
    return(
        <div className="flex flex-col gap-1 text-black w-full">
            <div className="flex flex-col w-full justify-start">
                <h1 className="font-trendaSemiBold text-xl">File Checks</h1>
                <p className="text-sm font-trenda">
                    Uploaded files run trough checks processes before being processed.
                </p>
            </div>
            <FileChecksTable/>
        </div>
    )
}