import { useRef } from "react";
import { ExploreBtn, SVGIcon, Section, Sharepoint, Tabs, getAppMenu, isSection } from "../../assets";
import { useActiveTabs } from "../../providers";
import { SideBarSection } from "./SideBarSection";
import { SideBarTabs } from "./SideBarTabs";
import { useHover } from "../../hooks";
import React from "react";

export const SideBarMenu = ({ isSidebarOpen }: { isSidebarOpen: Boolean }) => {

  const { menu } = useActiveTabs()

  return (
    <nav className={`${isSidebarOpen && "px-3"}`}>
      <ul>
        {menu?.map((elem: Section | Tabs, elemIndex) => {
          if (!isSection(elem) && (elem.appId == "GenAI/DocumentTranslator" || elem.appId == "GenAI/SentimentAnalysis" || elem.appId == "GenAI/LargeDocAssistant"))
            return <div key={elemIndex}></div>
          return (
            <React.Fragment key={elemIndex}>
              {elem.title == "SecuredGPT" &&
                <div className="relative group mb-2" key={`${elemIndex}`}>
                  <ExploreBtn isSidebarOpen={isSidebarOpen} />
                </div>
              }
              <div className="relative group mb-2">
                {isSection(elem) ? (
                  <SideBarSection
                    section={elem}
                    isSidebarOpen={isSidebarOpen}
                  />
                ) : (
                  <SideBarTabs
                    key={elem.id}
                    tabs={elem}
                    isSidebarOpen={isSidebarOpen}
                    isBelongToSection={false}
                  />
                )}
              </div>
              {elem.title == "SecuredGPT" &&
                <div className="relative group mb-2">
                  <Sharepoint isSidebarOpen={isSidebarOpen} />
                </div>
              }
            </React.Fragment>
          )
        })}
      </ul>
    </nav>
  );
};
