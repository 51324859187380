import { useState } from "react";
import { SVGIcon } from "../../../../assets";
import { LoaderCircle } from "../../../../components";
import { useMultiDocs } from "../../providers/MultiDocsProvider";

export const AnalyseLinks = () => {
    const [loaderLongUpload, setLoaderLongUpload] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');
    const [summaryChecked, setSummaryChecked] = useState<boolean>(true);

    // REMOVE
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

    const {
        config
    } = useMultiDocs()

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full bg-white p-3.5 border border-[#CFCFCF] justify-center rounded-xl gap-2.5">
                <input
                    type="text"
                    placeholder="URL of the document"
                    className="placeholder-gray-custom p-3 bg-[#F6F6F6] border-gray-400 rounded focus:outline-none"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
                <div className="flex flex-row justify-left gap-1.5">
                    <div onClick={() => setSummaryChecked(!summaryChecked)} className="relative cursor-pointer">
                        <input
                            id="summaryRadio"
                            className="opacity-0 absolute h-5 w-5  cursor-pointer"
                            type="radio"
                            name="summary"
                            checked={summaryChecked}
                        />
                        <div className=" cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded cursor-pointer">
                            {summaryChecked && (
                                <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M5 13l4 4L19 7"></path>
                                </svg>
                            )}
                        </div>
                    </div>
                    <label htmlFor="summaryRadio" className="text-sm cursor-pointer font-trendaSemiBold">{"Generate summary".toUpperCase()}</label>
                </div>
            </div>

            {
                link.length > 0 &&
                <div className="flex flex-row justify-end w-full h-full overflow-hidden pt-4">
                    <button
                        onClick={async () => { await config.uploadLink(link, summaryChecked); }}
                        className={`flex min-w-48 justify-center mb-4 px-4 py-2 text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none ${config.uploadDocumentLoader ? "bg-emerald-500" : "bg-white"}`}>
                        {
                            config.uploadDocumentLoader ?
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> : <div className="flex items-center gap-1"><span>Analyse link</span> {!uploadLoader && <SVGIcon size="20px" icon="ARROW_SMALL_ICON" />}</div>}
                    </button>
                </div>
            }
        </div>
    );
};