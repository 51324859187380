import { useEffect, useState } from "react";
import { CTRSlider } from "./CTRSlider";
import { CFRSelect } from "./CFRSelect";
import { CFRRadioButtons } from "./CFRRadioButtons";
import { CTRConfig, DateScope, SetupPanelState } from "../../hooks";
import { format, isBefore, parseISO } from 'date-fns';
import { News } from "../../hooks/customTrafficReport";


const formatDateArray = (dates: string[], dateScope: string): { [key: string]: string } => {
    // Définir le format de la date en fonction de dateScope
    const formattedDates: { [key: string]: string } = {};
    const dateFormat: string = dateScope === 'Weekly' ? 'MMMM dd, yyyy' : 'MMMM yyyy';

    // Remplir l'objet avec chaque dateString comme clé et la date formatée comme valeur
    dates.forEach((dateString: string) => {
        const date: Date = parseISO(dateString); // Convertir la chaîne ISO en objet Date
        formattedDates[dateString] = format(date, dateFormat); // Ajouter la paire clé-valeur dans l'objet
    });

    return formattedDates;
};

function filterPeriod0Options(period0_options: string[], selectedPeriod1: string | null): string[] {
    if (!selectedPeriod1)
        return []

    const selectedPeriod1Date = new Date(selectedPeriod1);
    const period0Options = period0_options.map((e) => parseISO(e));

    // Filtrez les options de période 0 pour ne garder que celles avant la période 1 sélectionnée
    const filteredPeriod0Options = period0Options.filter(period0Date =>
        isBefore(period0Date, selectedPeriod1Date)
    );
    // Convertissez les objets Date filtrés en chaînes ISO pour le retour
    return filteredPeriod0Options.map(date => date.toISOString());
}

function createNumberList(number: number) {
    const list = [];
    for (let i = 1; i <= number; i++) {
        list.push(i);
    }
    return list;
}

interface ILanguageObject {
    [key: string]: string; // Language code as key, full name as value
}

export const SetupPanel = ({ config, fetchDataForSubmit, data, loading,setLoader, setNews }: { config: CTRConfig | null, fetchDataForSubmit: (setupData: SetupPanelState, action: string) =>  Promise<void>, data: News | null, loading: boolean, setLoader : React.Dispatch<React.SetStateAction<boolean>>,setNews : React.Dispatch<React.SetStateAction<News | null>> }) => {

    const [bu, setBu] = useState<string | null>(null);
    const [dateScope, setDateScope] = useState<string>("Weekly");
    const [period0, setPeriod0] = useState<string | null>(null);
    const [period1, setPeriod1] = useState<string | null>(null);
    const [year, setYear] = useState<string>("2019");
    const [formatRecoding, setFormatRecoding] = useState<string>("Brief");
    
    const [language, setLanguage] = useState<string | null>("EN");

    const [p0List, setP0List] = useState<{ [key: string]: string }>({})
    const [p1List, setP1List] = useState<{ [key: string]: string }>({})


    const [level1, setLevel1] = useState<string | null>(null);
    const [level2, setLevel2] = useState<string | null>(null);
    const [nbLevel1, setNbLevel1] = useState<number | number[]>(1)
    const [nbLevel2, setNbLevel2] = useState<number | number[]>(1)

    const [section, setSection] = useState<string | null>("general");
    const [input, setInput] = useState("");

    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const [filteredLanguages, setFilteredLanguages] = useState<ILanguageObject>({});


    useEffect(() => {
        const languagesToKeep: ILanguageObject = {
            EN: "English",
            ES: "Spanish",
            FR: "French",
            PT: "Portuguese",
            JA: "Japanese",
        };

        const newFilteredLanguages = Object.entries(config?.languages || {}).reduce<ILanguageObject>((acc, [key, value]) => {
            if (typeof key === 'string' && typeof value === 'string') {
                // Check if the key is one of the languages we want to keep
                if (languagesToKeep.hasOwnProperty(key)) {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});

        setFilteredLanguages(newFilteredLanguages);
    }, [config]);

    useEffect(()=>{
        console.log(filteredLanguages, config?.languages,language)
    },[filteredLanguages,config,language])

    

    const init = (scope: string) => {
        if (config) {
            const p1 = formatDateArray(config[scope].period1_options, scope)
            setP1List(p1)
            setPeriod1(Object.keys(p1)[0])

            const p0 = formatDateArray(filterPeriod0Options(config[scope].period0_options, Object.keys(p1)[0]), scope)
            setP0List(p0)
            setPeriod0(Object.keys(p0)[0])
        }
    }

    useEffect(()=>{
        if(data) setNews(null)
    },[bu, dateScope, period0, period1, year])

    useEffect(() => {
        if (data && !level1 && data.level1_selected) {
            setLevel1(data.level1_selected)
        }
        if (data && !level2 && data.level2_selected) {
            setLevel2(data.level2_selected)
        }
    }, [data, level1, level2])

    useEffect(()=>{
        setNbLevel1(1)
        setNbLevel2(1)
    },[level1])

    useEffect(()=>{
        setNbLevel2(1)
    },[level2])

    useEffect(() => {
        init(dateScope)
    }, [config])


    async function fetchCTR(action: string) {
        if (period0 && period1) {
            await fetchDataForSubmit({
                "dateScope": dateScope,
                "period0": period0,
                "period1": period1,
                "bu": bu,
                "year": year,
                "level1": level1 || "",
                "level2": level2 || "",
                "level1_nb": typeof nbLevel1 == "number" ? nbLevel1 : 1,
                "level2_nb": typeof nbLevel2 == "number" ? nbLevel2 : 1,
                "section_id": section || "",
                "correction": input,
                "language": language,
                "format_recoding": formatRecoding
            }, action)
            if (action == "submit") {
                setLevel1(null)
                setLevel2(null)
                setNbLevel1(1)
                setNbLevel2(1)
            }
        }
    }

    useEffect(() => {
        if (level1) {
            if (period0 && period1 && dateScope) {
                fetchCTR("change")
            }
        } else {
            setIsSubmit(false)
        }
    }, [level1, level2, nbLevel1, nbLevel2])

    const changeDate = (scope: string) => {
        if (config) {
            setDateScope(scope)
            init(scope)
        }
    }

    const onChangeP1 = (value: string | null) => {
        if (config && value) {
            setPeriod1(value)
            const p0 = formatDateArray(filterPeriod0Options(config[dateScope].period0_options, value), dateScope)
            setP0List(p0)
            setPeriod0(Object.keys(p0)[0])
        }
    }

    function submit() {
        if (bu && period0 && period1 && dateScope) {
            setIsSubmit(true)
            fetchCTR("submit")
        }
    }

    function load() {
        if (period0 && period1 && dateScope) {
            fetchCTR("load_paragraph")
        }
    }

    return (
        <div className="lg:w-3/12 w-4/12 border-l p-4 shadow-lg flex h-full flex-col max-h-screen overflow-scroll">
            <h1 className=" text-text-primary-light font-medium text-center underline md:text-1xl text-lg">Traffic Report</h1>
            {config && (
                <>
                    <hr className="h-px mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
                    <CFRSelect entries={config.bu} defaultValue={"Business Unit"} firstIndex={1} secondIndex={1} value={bu} setValue={setBu} />
                    <h4 className="py-2">Date Scope</h4>
                    <CFRRadioButtons values={config.date_scopes_options} selectedValue={dateScope} setSelectedValue={changeDate} />

                    <h5 className="pt-2">Compared period</h5>
                    <CFRSelect entries={p0List} defaultValue={"Compared period"} firstIndex={0} secondIndex={1} value={period0} setValue={setPeriod0} />

                    <h5 className="">Reference period</h5>
                    <CFRSelect entries={p1List} defaultValue={"Reference period"} firstIndex={0} secondIndex={1} value={period1} setValue={onChangeP1} />

                    <h4 className="py-4">Year over Year comparison</h4>
                    <CFRRadioButtons values={config.year_over_year_comparisons_options.map((e) => e.toString())} selectedValue={year} setSelectedValue={setYear} />

                    <button onClick={submit} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                        Submit
                    </button>
                    {data && <>

                        <h5 className="pt-2">Level 1</h5>
                        <CFRSelect entries={data.level1} defaultValue={"Level 1"} firstIndex={1} secondIndex={1} value={level1} setValue={setLevel1} />
                        <CTRSlider max={data.max_level1} value={nbLevel1} setValue={setNbLevel1} />

                        <h5 className="pt-2">Level 2</h5>
                        <CFRSelect entries={data.level2} defaultValue={"Level 2"} firstIndex={1} secondIndex={1} value={level2} setValue={setLevel2} />
                        <CTRSlider max={data.max_level2} value={nbLevel2} setValue={setNbLevel2} />
                        <CFRRadioButtons values={["Brief", "Detailed"]} selectedValue={formatRecoding} setSelectedValue={setFormatRecoding} />
                        <CFRSelect entries={filteredLanguages} defaultValue={"Languages"} firstIndex={0} secondIndex={1} value={language} setValue={setLanguage} />

                        <button onClick={() => {load();setLoader(true)}} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                            Generate News - Cost: ~{Math.round(data.credit)} credit
                        </button>
                        <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                        {/*
                                                <CFRSelect entries={data.ids} defaultValue={"Sections"} firstIndex={1} secondIndex={1} value={section} setValue={setSection} />
                        <textarea
                            value={input}
                            onChange={(e) => setInput(e.target.value)}

                            className="form-control min-h-20 block w-full pl-3 pr-24 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none overflow-hidden resize-none"
                            placeholder="Correct..."
                            rows={3}
                            style={{ minHeight: "5rem", overflowY: "auto" }}
                        ></textarea>
                        <button onClick={() => {section && fetchCTR("correct_paragraph")}} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                            Correct
                        </button>
                        */}
                        <button onClick={()=>fetchCTR("download")} className="mt-4 w-full bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white py-2 px-4 rounded">
                            Download
                        </button>
                    </>}
                </>
            )}
        </div>
    )
}