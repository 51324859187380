import { ReactNode, useEffect, useState } from 'react';
import { useCFRConfig, useCFRTitle, useSessionId } from '../../hooks';
import { SetupPanel } from './SetupPanel';
import { SetupPanelState } from '../../types';
import { CFRNews } from './CFRNews';
import { useAuth } from '../../providers';
import { SVGIcon } from '../../assets';
import { LoaderComponent } from '../../components';

export const CustomFinanceReport = () => {
    const sessionId = useSessionId(`/api/custom_finance_report/clear_session`);
    const [setupData, setSetupData] = useState<SetupPanelState | null>(null)

    const { token } = useAuth()
    const { news, loading, setLoading, fetchDataForSubmit, fetchDataForUpdate, fetchDataForLoadParagraph, fetchCorrectParagraph, fetchDownload, setNews } = useCFRTitle({ sessionId });
    const { config, loadingConfig, setConfig } = useCFRConfig(sessionId, token);

    return (
        <div className="flex-1 flex flex-row h-full max-w-full items-center justify-center">
            <LoaderComponent loadingConfig={loadingConfig}>
                <CFRNews data={news} setupData={setupData} loading={loading} />
                <SetupPanel setLoader={setLoading} news={news} setupData={setupData} onSetupPanelChange={setSetupData} fetchDataForSubmit={fetchDataForSubmit} fetchDataForUpdate={fetchDataForUpdate} fetchDataForLoadParagraph={fetchDataForLoadParagraph} fetchCorrectParagraph={fetchCorrectParagraph} fetchDownload={fetchDownload} config={config} setConfig={setConfig} setNews={setNews} />
            </LoaderComponent>
        </div>
    );
}
