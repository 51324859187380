import React, { ReactElement } from 'react';
import { useState,useRef,useEffect } from 'react';
import { LoaderTextAnswer } from './Answer';
import { messageTMM } from '../../hooks';
import { TextAnswer } from './Answer';
import { HTMLAnswer } from './Answer';
import { Ask } from './Ask';
import { TextAnswerError } from './Answer';

interface ChatConversationProps {
    messages: messageTMM[];
    loadingTMM: boolean;
    pluginActive: string;
}

export const ChatConversation: React.FC<ChatConversationProps> = ({ messages,loadingTMM,pluginActive }) => {
    const [lastTextAnswerIndex, setLastTextAnswerIndex] = useState<number | null>(null);
    const prevMessagesCountRef = useRef<number>(messages.length);
    const endOfMessagesRef = useRef<HTMLDivElement>(null);
   
    useEffect(() => {
        const lastTextAnswer = messages.map((msg, index) => ({ type: msg.type, index })).reverse().find(msg => msg.type === 'TextAnswer');
        if (lastTextAnswer) {
            setLastTextAnswerIndex(lastTextAnswer.index);
        }

        return () => {
            prevMessagesCountRef.current = messages.length;
        };
    }, [messages]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [messages.length]);
    return (
        <div className='bg-white w-11/12 p-4  rounded-xl min-h-[300px]' style={{ height: `calc(100vh - 275px)` }}>
            <div className="overflow-auto p-1 max-h-full scrollbar-hide">
                {messages.map((messageObj, index) =>{
                    const isHTMLAnswer = messageObj.type === 'HTMLAnswer'
                    const isLastTextAnswer = messageObj.type === ('TextAnswer' || isHTMLAnswer) && index === lastTextAnswerIndex;
                    const isNewMessageAdded = messages.length > prevMessagesCountRef.current;

                    let commonProps = {
                        ...messageObj.props,
                        typed : isLastTextAnswer && isNewMessageAdded && messageObj.props.text.length <= 200 && !isHTMLAnswer
                    }

                    if(messageObj.type === 'TextAnswer'){
                        return (
                            <React.Fragment key={index}>
                                <TextAnswer {...commonProps} pluginActive={pluginActive} />
                            </React.Fragment>
                        );
                    }
                    else if(messageObj.type === 'HTMLAnswer'){
                        return(
                            <React.Fragment key={index}>
                                <HTMLAnswer {...commonProps} pluginActive={pluginActive} />
                            </React.Fragment>
                        )
                    }
                    else if(messageObj.type === 'TMMError') {
                        return(
                            <React.Fragment key={index}>
                                <TextAnswerError type='IA'/>
                            </React.Fragment>
                        )
                    }
                    else{
                        return(
                            <React.Fragment key={index}>
                                <Ask {...commonProps} />
                            </React.Fragment>
                        )
                    }
                })}
                <div ref={endOfMessagesRef} />
                {loadingTMM && <LoaderTextAnswer type="IA"/>}
            </div>
        </div>
    );
};