import { useEffect, useRef } from "react";
import { SVGIcon } from "../../assets";
import { useTMMConfig, useSessionId } from "../../hooks";
import { useAuth } from "../../providers";
import { LoaderSDH } from "../LoaderComponent";
interface DisplayPrompt {
    prompts: string[] | null;
    reloadPrompt: () => Promise<void>
    ask: (text: string) => Promise<void>
    refresh: boolean;
    plugin? : string;
    loading? : boolean;
}

export const DisplayPrompt: React.FC<DisplayPrompt> = ({ prompts, reloadPrompt, ask, refresh,plugin,loading }) => {

    return (
        <div className="flex w-full flex-col justify-center items-center">
            {
                refresh &&
                <div className="flex w-full justify-end my-1.5">
                    <div onClick={reloadPrompt}>
                        <SVGIcon icon="REFRESH_ICON" />
                    </div>
                </div>
            }
            {Array.isArray(prompts) && prompts?.map((e, i) => {
                return (
                    <div onClick={() => ask(e)} key={i} className="flex flex-1 justify-between items-center text-justify w-full p-2 my-1.5 cursor-pointer hover:shadow-xl rounded-xl text-token-text-primary border">
                        <p className="text-sm text-[#4A4A4A]">{e}</p>
                        <div><SVGIcon icon="PAPER_PLANE_ICON" /></div>

                    </div>
                )
            })}
            <div className="flex w-full justify-center my-1.5">
                {loading ? <LoaderSDH/> : <SVGIcon icon="MENU_DOTS_ICON" />}
            </div>
        </div>

    );
};
