import { useState, useEffect } from "react";
import FileDropzone from "./FileDropzone";
import {
  translateDocument,
  useDocumentUploader,
  useSessionId,
  useTranslationConfig,
} from "../../hooks";
import { SelectOptions } from "./SelectedOptions";
import { useAuth, useCredit } from "../../providers";
import { LoaderComponent } from "../../components";
import { forbiddenApps } from "../../constants/constant";

export const DocumentTranslator = () => {
  const [file, setFile] = useState<File | null>(null);
  const [method, setMethod] = useState<string>("");
  const [loaderTranslation, setLoaderTransaltion] = useState<boolean>(false)
  const sessionId = useSessionId(`/api/translation/clear_session`);
  const { token } = useAuth()

  const {
    loading: configLoading,
    languages,
    docTypes,
    mapOpt,
    setMapOpt,
    setDocTypes,
  } = useTranslationConfig({ sessionId });
  const {
    loading: documentUploaderLoading,
    usage,
    error,
  } = useDocumentUploader({ file, method, sessionId });

  const [fileName, setFileName] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [sourceLanguage, setSourceLanguage] = useState<string>("");
  const [availableOptions, setAvailableOptions] = useState<string[]>([]);
  const [price, setPrice] = useState<number | null>(null);
  const { remainingCredit, setRemainingCredit } = useCredit()
  const [errorTranslation, setErrorTranslation] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)

  useEffect(() => {
    if (mapOpt && Object.entries(mapOpt).length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
        const filteredMapOpt: { [key: string]: string[] } = Object.keys(mapOpt)
            .filter(key => key === 'pdf' || key === 'pptx' || key === 'docx')
            .reduce((obj, key) => {
                obj[key] = mapOpt[key]?.filter(value => value === 'GoogleTranslate') ?? [];
                return obj;
            }, {} as { [key: string]: string[] });

            if (JSON.stringify(filteredMapOpt) !== JSON.stringify(mapOpt)) {
              setMapOpt(filteredMapOpt);
            }
    }
  }, [mapOpt, remainingCredit]);

  useEffect(()=>{
    if (docTypes && docTypes.length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
      const filteredDocTypes = docTypes.filter(type => type !== 'pdf');
      if (JSON.stringify(filteredDocTypes) !== JSON.stringify(docTypes)) {
        setDocTypes(filteredDocTypes);
      }
    }
  },[docTypes])

  useEffect(() => {
    if (usage) {
      setPrice(usage['usage']);
      if (method != "ChatGPT") {
        setPageNumber(usage['pages'])
      } else {
        setPageNumber(0)
      }
    }
  }, [usage]);

  useEffect(() => {
    setMethod("");
    setPrice(null);
    setPageNumber(0)
  }, [file]);

  
  useEffect(() => {
    if ((method == "TranslationHub" && pageNumber > 20) || pageNumber > 40) setErrorTranslation(true)
    else setErrorTranslation(false)
  }, [pageNumber, method])

  const initiateTranslation = async () => {
    if(typeof remainingCredit === 'number' && remainingCredit <= 0 && method !== 'GoogleTranslate') return
    setLoaderTransaltion(true)
    try {
      await translateDocument({ file, sessionId, selectedLanguage, method, token, sourceLanguage });
      if (price && remainingCredit) {
        const newCredit = remainingCredit - price
        setRemainingCredit(newCredit)
      }
    } catch (error) {
      console.log(error)
    }
    setLoaderTransaltion(false)
  };
  return (
    <div className="flex flex-col justify-start items-center">
      <LoaderComponent loadingConfig={configLoading}>
        <div className="flex flex-col text-md text-[#0D3D62] m-0 p-7">
          <div className="w-full flex" >
            <div className="flex flex-col w-full gap-4 rounded-xl bg-white p-5">
              <div className="flex flex-row gap-4">
                <p className="text-xl text-[#0D3D62] font-trendaSemiBold">Welcome to our translation interface<span className="font-sans">!</span>{" "}</p>
              </div>
              <div className="bg-[#F6F6F6] p-4 w-full rounded-lg justify-center items-center">
                <p className="text-sm text-[#0D3D62]">
                  Simply upload your files, and our automated system will handle the translation process seamlessly.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  Please be aware that the current document size limit is set to 20 pages.
                </p>
                <br />
                <p className="text-sm text-[#0D3D62]">
                  We're pleased to offer <span className="font-bold">two translation models tailored to your needs:</span>
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Premium Translation Model (5/5 Rating) using "translation pro Hub" from Google :</span>  Ideal for critical and strategic presentations, ensuring accurate and high-quality translations.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  <span className="font-bold">• Standard Translation Model (2/5 Rating) using GPT 3.5 from VINCI Concessions Azure Open AI:</span> Recommended for non-strategic or less critical content, providing a balance between accuracy and efficiency.
                </p>
                <br />
                <p className="text-sm text-[#0D3D62]">
                  If your document exceeds the 20-page limit, please don't hesitate to contact the Smart Data Hub team. We'll be happy to assist you with your request.
                </p>
                <p className="text-sm text-[#0D3D62]">
                  Thank you for your continued support and understanding. Feel free to reach out if you have any questions or feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-300 p-4 rounded-lg shadow-lg">
          <FileDropzone
            setFile={setFile}
            setFileName={setFileName}
            setAvailableOptions={setAvailableOptions}
            mapOpt={mapOpt}
            fileName={fileName}
            docTypes={docTypes}
          />
          {fileName != "" && availableOptions.length > 0 && (
            <SelectOptions
              defaultValue="Select a method"
              options={availableOptions}
              renderOption={(method) => ({ value: method, label: method })}
              selectedValue={method}
              onChange={(e) => setMethod(e.target.value)}
            />

          )}
          {
            method == "GoogleTranslate" &&
            <SelectOptions
              defaultValue="Select original language"
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={sourceLanguage}
              onChange={(e) => setSourceLanguage(e.target.value)}
            />
          }
          {method != "" &&
            <SelectOptions
              defaultValue="Select translation language"
              options={Object.entries(languages)}
              renderOption={(language) => ({
                value: language[0],
                label: language[1],
              })}
              selectedValue={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            />
          }
          {method != "" && selectedLanguage != "" && fileName != "" &&
            <button
              className={`mt-4 w-full ${loaderTranslation ? 'bg-gray-300 hover:bg-gray-300 border-gray-200 text-gray-500' : 'bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white'} py-2 px-4 rounded`}
              onClick={initiateTranslation}
              disabled={loaderTranslation || errorTranslation}
            >
              {errorTranslation ? "Too much pages in file" : (loaderTranslation ? "Loading..." : `Translate ${price != null ? " | " + Math.round(price) + " credit" : ""}`)}
            </button>
          }
        </div>
      </LoaderComponent>
    </div>
  );
};