import React from 'react';

export interface SummaryBuProps {
  title: string;
  fileReceivedTotal: number;
  fileSucess: number;
  fileIngestionError: number;
  fileLateError: number;
  maxFilesError?: number;
  setApp?: React.Dispatch<React.SetStateAction<string>>;
}

export const SummaryBu: React.FC<SummaryBuProps> = ({
  title,
  fileReceivedTotal,
  fileIngestionError,
  fileLateError,
  fileSucess,
  maxFilesError,
  setApp,

}) => {

  const totalErrors = fileIngestionError + fileLateError;

  const hasErrors = totalErrors > 0;

  const errorRatio = hasErrors ? Math.min(totalErrors / maxFilesError!, 1) : 0; 

  const backgroundColor = hasErrors ? `rgba(255, 0, 0, ${errorRatio})` : 'white';

  return (
    <div
      className="h-[410px] w-[350px] flex items-center gap-2 border-1 border-[#F5F5F5] flex-col p-2 bg-white w-full text-black rounded-xl shadow-md cursor-pointer hover:shadow-lg transition-shadow"
    >
      <h2 className="text-xl font-trendaSemiBold text-black mb-2">
        {title}
      </h2>
      <div className='flex flex-row items-center w-full text-black justify-between rounded-md bg-[#F5F5F5] text-sm p-2 '>
        <p>
          <span className="font-bold">{fileReceivedTotal}</span>  <span className='font-trenda'>{fileReceivedTotal > 1 ? 'files': 'file'} received in the last 12 months.</span>
        </p>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="-rotate-90"
          width="18"
          height="18"
          fill={'#EEEEEE'}
          viewBox="0 0 24 24"
        >
          <path
            fill='black'
            fillRule="evenodd"
            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
          ></path>
        </svg>  */}
      </div>
      
      {fileIngestionError > 0 && (
          <div className='flex flex-row items-center w-full text-black justify-between rounded-md bg-[#F5F5F5] text-sm p-2' onClick={()=>{if(setApp) setApp('Quality')}}>
            <p className="text-red-500">
              <span className="font-bold">{fileIngestionError}</span> <span className='font-trenda'>{fileIngestionError > 1 ? 'files were': 'file was '} rejected due to an ingestion error.</span>
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="-rotate-90"
              width="18"
              height="18"
              fill={'#EEEEEE'}
              viewBox="0 0 24 24"
            >
              <path
                fill='black'
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg> 
          </div>
      )}
      {fileLateError > 0 && (
          <div className='flex flex-row items-center w-full text-black justify-between rounded-md bg-[#F5F5F5] text-sm p-2 ' onClick={()=>{if(setApp) setApp('Ingestion')}}>
            <p className="text-[#f27405]">
              <span className="font-bold">{fileLateError}</span> <span className='font-trenda'>{fileLateError > 1 ? 'files are': 'file is'} late to be sent.</span> 
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="-rotate-90"
              width="18"
              height="18"
              fill={'#EEEEEE'}
              viewBox="0 0 24 24"
            >
              <path
                fill='black'
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg> 
          </div>
      )}
    </div>
  );
};