import { useState,useEffect } from "react"
import { SelectOptions, SelectOptionsDataGouv } from "../DocumentTranslator/SelectedOptions"
import { SummaryBu,SummaryBuProps } from "./SummaryBU"
import { SVGIcon } from "../../assets"
import { useDataGouv } from "./providers/DataGouvProvider"
import { useActiveTabs } from "../../providers"
import { dataGouvernance } from "../../assets"
import { LateFileModal } from "./LateFileModal"
import { sum } from "pdf-lib"


// const buOptions = ['ITM', 'HND','LTW']

// const dateOptions = ['2021', '2022','2023','2024']

// //data bu squares
// const summaryData: SummaryBuProps[] = [
//     { title: 'Traffic', fileReceived: 150, fileIngestionError: 5, fileLateError: 2 },
//     { title: 'Finance', fileReceived: 120, fileIngestionError: 8, fileLateError: 4 },
//     { title: 'Retail', fileReceived: 200, fileIngestionError: 10, fileLateError: 3 },
//     { title: 'Com', fileReceived: 90, fileIngestionError: 2, fileLateError: 1 },
//     { title: 'Carpark', fileReceived: 75, fileIngestionError: 1, fileLateError: 0 },
//     { title: 'HR', fileReceived: 110, fileIngestionError: 6, fileLateError: 5 },
// ];

export const capitalizeFirstLetter = (str: string): string => {
    if(str === 'HR') return str
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export const DataGouvHome = () => {
    const {dataQuality,buSelected,setBuSelected,dateSelected,setDateSelected,buOptions,dateOptions,lateFilesNumber, setApp} = useDataGouv()
    const [summaryData,setSummaryData] = useState<SummaryBuProps[]>([])
    const {openTab} = useActiveTabs()

    const defaultTab = {
        id: 50,
        appId: "GenAI/SecureChatGPT",
        title: "Overview",
        icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
        description: [],
        app: () => <DataGouvHome/>,  
    }

    const titleToTabMap: Record<string, any> = dataGouvernance.tabs.reduce((map, tab) => {
        map[tab.title] = tab;
        return map;
    }, {} as Record<string, any>);

    useEffect(() => {
        if (buSelected && dateSelected && dataQuality?.data?.[dateSelected]?.[buSelected]) {
            const buData = dataQuality.data[dateSelected][buSelected];
            const newSummaryData: SummaryBuProps[] = Object.keys(buData).map((key) => ({
                title: key,
                fileReceivedTotal: buData[key].total,
                fileSucess: buData[key].success,
                fileIngestionError: buData[key].failed,
                fileLateError: 2
            }));

            setSummaryData(newSummaryData);
        }
    }, [buSelected, dateSelected, dataQuality]);

    const maxFileIngestionError = summaryData.length > 0 ? Math.max(...summaryData.map((bu) => bu.fileIngestionError + bu.fileLateError)) : 0; 


    return(
        <div className="flex flex-col h-full items-center relative">
            {/* { lateFilesNumber > 0 && 
                <div className="flex w-full">
                    <LateFileModal lateFiles={lateFilesNumber}/>
                </div>
            } */}
            <div className="flex flex-row justify-between items-center w-full p-8">
                <h1 className="w-full font-trendaSemiBold text-black text-2xl">Data Gouvernance - {buSelected || 'Please choose a BU'}</h1>
                <div className="flex flex-row gap-4 items-center w-full items-center justify-end">
                    <p className="text-black font-trenda">
                        BU Selection:
                    </p>
                    <SelectOptionsDataGouv
                    options={buOptions}
                    defaultValue="BU Selection"
                    renderOption={(method) => ({ value: method, label: method })}
                    selectedValue={buSelected!}
                    onChange={(e) =>{setBuSelected(e.target.value)} }
                    />
                    <h1 className="text-black font-trenda">Year to Date:</h1>
                    <SelectOptionsDataGouv
                        options={dateOptions}
                        defaultValue="Year to Date"
                        renderOption={(method) => ({ value: method, label: method })}
                        selectedValue={dateSelected!}
                        onChange={(e) => {setDateSelected(e.target.value)}}
                    />
                </div>
            </div>
            {buSelected && dateSelected && 
                <div className="flex flex-row grid grid-cols-3 gap-5 px-8 py-2 w-full items-center justify-center">
                    {summaryData.map((elem)=>(
                        <div onClick={()=> {openTab(titleToTabMap[capitalizeFirstLetter(elem.title)] ?? defaultTab)}}>
                            <SummaryBu 
                                title={elem.title}
                                fileReceivedTotal={elem.fileReceivedTotal}
                                fileIngestionError={elem.fileIngestionError}
                                fileLateError={elem.fileLateError}
                                fileSucess={elem.fileSucess}
                                maxFilesError={maxFileIngestionError}
                                setApp={setApp}
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}