import { useChatGPT } from "../providers";
import { GoogleTranslatorIcon, ManageFileIcon } from "./Icon";
import { ChatGPTTranslator } from "./ChatGPTTranslator";
import { useState } from "react";
import { Instructions } from "./SentimentAnalysis/Instructions";
import { DownloadTemplate } from "./SentimentAnalysis/DownloadTemplate";
import { ProcessFile } from "./SentimentAnalysis/ProcessFile";
import { SVGIcon } from "../../../assets/SVGIcon";

const paramConfig = [
    {
        id: 0,
        title: "How to Use",
        icon: <SVGIcon size="18px" icon="UPLOAD_ICON" />,
        paramComponent: <Instructions />
    },
    {
        id: 1,
        title: "Download Template",
        icon: <SVGIcon size="18px" icon="LINKS_ICON" />,
        paramComponent: <DownloadTemplate />
    },
    {
        id: 2,
        title: "Process File",
        icon: <ManageFileIcon />,
        paramComponent: <ProcessFile />
    }
]

export const ChatGPTModalSentimentAnalysis = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig
    } = useChatGPT();

    const [appSelected, setAppSelected] = useState<Number>(0)

    return (
        <div className="w-[1200px] max-h-[1000px] flex flex-col">
            <div className="px-2 pb-2 pt-2 sm:p-4 flex items-center justify-between rounded-t-md">
                <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-8 h-8 text-text-primary-light">
                        <SVGIcon size="24px" icon="SENTIMENT_ANALYSIS_ICON" />
                    </div>
                    <h2 className="text-lg font-medium leading-6 text-text-primary-light font-trendaSemiBold">Sentiment analysis</h2>
                </div>
                <button className="flex h-8 w-8 items-center justify-center" onClick={() => setModalStatus("CLOSE")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                        <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-3 flex-shrink-0  md:min-w-[180px] border border-secondary-light rounded-md shadow-md">
                    {
                        paramConfig.map((c, i) => {
                            return (
                                <button type="button" onClick={() => setAppSelected(c.id)} className={`${appSelected == c.id && "bg-secondary-light"} hover:bg-secondary-light group flex items-center justify-center gap-1.5 rounded-md px-2 py-1.5 text-sm text-text-primary-light`}>
                                    {c.icon}
                                    <div className="text-left">{c.title}</div>
                                </button>
                            )
                        })
                    }
                </div>
            <div className="flex flex-col">
                
                <div className="flex flex-col p-2 gap-2 content-center w-full overflow-auto max-h-[800px]">
                    {paramConfig.map((c) => (
                        appSelected === c.id && (
                            <div key={c.id}>
                                {c.paramComponent}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    )
}