import React from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileStepStatus {
  status: 'pending' | 'running' | 'finished' | 'error';
}

export interface ButtonIngestionProps {
  status: {
    loaded: string;
    checkfile: string;
    checkKpis: string;
    processed: string;
  };
  onClick?: () => void;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'running':
      return '#F78000';
    case 'finished':
      return '#00BE00';
    case 'error':
      return '#E61A23';
    default:
      return '#686868';
  }
};


interface ButtonProps {
  text: string;
  onClick: () => void;
  width?: string;
}

export const CustomButton: React.FC<ButtonProps> = ({ text, onClick, width = 'w-full' }) => {
  return (
    <button
      className={`flex flex-row text-sm items-center border border-[#F78000] h-fit text-[#F78000] border-dashed rounded-md px-3 py-1 focus-outline-none ${width} gap-3 justify-center`}
      onClick={onClick}
    >
      {text}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="-rotate-90"
        width="18"
        height="18"
        fill="#F78000"
        viewBox="0 0 24 24"
      >
        <path
          fill='#F78000'
          fillRule="evenodd"
          d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

interface ErrorButtonProps {
  onClick: () => void;
  isExpanded: boolean
}

export const ErrorButton: React.FC<ErrorButtonProps> = ({ onClick, isExpanded }) => {
  return(
  <button onClick={onClick} className=' font-bold flex flex-row text-sm items-center border border-[#E61A23] h-fit text-[#E61A23] border-dashed rounded-md px-3 py-1 w-[120px] gap-3 justify-center'>
    See Error
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${isExpanded ? '' : '-rotate-90'}`}
      width="18"
      height="18"
      fill="#E61A23"
      viewBox="0 0 24 24"
    >
      <path
        fill='#E61A23'
        fillRule="evenodd"
        d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
        clipRule="evenodd"
      ></path>
    </svg>
  </button>
  )
}

export const ButtonIngestion: React.FC<ButtonIngestionProps> = ({ status, onClick, setFile }) => {
  const { loaded, checkfile, checkKpis, processed } = status;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles && acceptedFiles.length > 0){
        setFile(acceptedFiles[0])
      }
    },
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
  });

  let text = 'Load File';
  let borderColor = getStatusColor('pending');
  let textColor = getStatusColor('pending');
  let svgColor = getStatusColor('pending');
  let isDashed = true;
  let borderThickness = 'border';
  let fontWeight = 'font-normal';

  if (loaded === 'finished' && checkfile === 'finished') {
    text = 'Check KPIs';
    borderColor = getStatusColor('running');
    textColor = getStatusColor('running');
    svgColor = getStatusColor('running');
  }
  if (
    loaded === 'finished' &&
    checkfile === 'finished' &&
    checkKpis === 'finished' &&
    processed === 'finished'
  ) {
    text = 'Process complete';
    borderColor = getStatusColor('finished');
    textColor = getStatusColor('finished');
    svgColor = getStatusColor('finished');
    isDashed = false;
    borderThickness = 'border-2';
    fontWeight = 'font-medium';
  }

  return (
    <>
      {text === 'Load File' ? (
        <div
          {...getRootProps()}
          className={`flex items-center cursor-pointer justify-center flex-row px-3 py-1 w-[160px] rounded-md text-sm ${borderThickness} ${fontWeight}`}
          style={{
            borderColor: borderColor,
            color: textColor,
            borderStyle: isDashed ? 'dashed' : 'solid',
          }}
        >
          <input {...getInputProps()} />
          <p>Load File</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-rotate-90"
            width="18"
            height="18"
            fill={svgColor}
            viewBox="0 0 24 24"
          >
            <path
              fill={svgColor}
              fillRule="evenodd"
              d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      ) : (
        <button
          disabled={loaded === 'running' || checkfile === 'running'}
          className={`flex items-center justify-center flex-row px-3 py-1 w-[160px] rounded-md text-sm ${borderThickness} ${fontWeight}`}
          style={{
            borderColor: borderColor,
            color: textColor,
            borderStyle: isDashed ? 'dashed' : 'solid',
          }}
          onClick={onClick}
        >
          {text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-rotate-90"
            width="18"
            height="18"
            fill={svgColor}
            viewBox="0 0 24 24"
          >
            <path
              fill={svgColor}
              fillRule="evenodd"
              d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </>
  );
};