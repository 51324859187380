import React, { useState } from "react";
import { FileRow } from "./FileChecksTable";
import { FileData } from "../hooks/useDataIngestion";
import { ButtonIngestion } from "./ButtonIngestion";
import { useDataGouv } from "../providers/DataGouvProvider";

interface LinkedFilesProps {
  linkedFiles: FileData[];
  mainFileName: string;
  version?: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
  rowId: string;
}

export const LinkedFileDropdown: React.FC<LinkedFilesProps> = ({ linkedFiles, mainFileName,openModalRowId, setOpenModalRowId,rowId }) => {
  const { buSelected, dateSelected, ingestionData , uploadFileFromDropzone} = useDataGouv();
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const currentFile = {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
  };
  const [file,setFile] = useState<File | null>(null)



  return (
    <>
      <tr>
        <td colSpan={6} onClick={() => setDropdownOpen(!dropdownOpen)} className="py-2 cursor-pointer border-b">
          <div className="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#686868"
              viewBox="0 0 24 24"
              className={`${!dropdownOpen && '-rotate-90'}`}
            >
              <path
                fill="black"
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="text-sm">{mainFileName}</p>
          </div>
        </td>
        <td colSpan={2} className="px-4 py-2 border-b">
          <ButtonIngestion onClick={() => setDropdownOpen(!dropdownOpen)} status={currentFile.status} setFile={setFile} />
        </td>
      </tr>
      {dropdownOpen &&
        linkedFiles.map((fileData, index) => (
          <FileRow 
              key={index} 
              fileType={fileData.file_type} 
              version={fileData.version} 
              isDropdown={true} 
              fileLinked={file}
              rowId={`unlinked-${fileData.file_type}-${fileData.version || ''}-${index}`}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
           />
        ))}
    </>
  );
};