import React from "react";
import { useState,useEffect } from "react";
import { useCredit } from "../providers";

interface UserInfo {
    userName: string;
    userEmail: string;
    language: string;
    newsletterFrequency: string;
    format: string;
    writtingStyle: string;
    isUserSubscribed: boolean;
    themes? : string[];
}
interface UserSettings {
    access: {
        userName: string;
        userEmail: string;
        language: string;
        newsletterFrequency: string;
        format: string;
        writtingStyle: string;
    };
    industry: {
        userName: string 
        userEmail: string ;
        language: string;
        themes: string[];
        newsletterFrequency: string;
        format: string;
        writtingStyle: string;
    };
}




export const useNewsletterConfig = (token : string | null) =>{
    // const {remainingCredit, setRemainingCredit} = useCredit()
    const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
    const [subscribeLoader, setSubscribeLoader] = useState<boolean>(false);
    const [loadingInfoError, setLoadingInfoError] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserSettings>()
    const [userDeleteLoader, setUserDeleteLoader] = useState<boolean>(false)
    const [userDeletedSuccess, setUserDeletedSuccess] = useState<boolean>(false)
    const [userSubscribeSuccess, setUserSubscribeSuccess] = useState<boolean>(false)


    const checkUserInfo = async(email: string) =>{
        setLoadingInfo(true)
        const response = await fetch('/api/newsletter/email',{
            method : "POST",
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify({
                userEmail : email,
            }),
        })
        if(response.ok) {
            const responseData = await response.json()
            // console.log('res check', responseData)
            setUserInfo(responseData)
        }
        else{
            setLoadingInfoError(true)
        }
        setLoadingInfo(false)
    }

    const subscribe =  async(userInfoPayload: UserInfo, type: string) =>{
        setSubscribeLoader(true)
        const payload = {
            userName: userInfoPayload.userName,
            userEmail: userInfoPayload.userEmail,
            language: userInfoPayload.language,
            newsletterFrequency: userInfoPayload.newsletterFrequency,
            format: userInfoPayload.format,
            writtingStyle: userInfoPayload.writtingStyle,
            isUserSubscribed: userInfoPayload.isUserSubscribed,
            newsletterType : type,
            themes: userInfoPayload.themes
        };
        const response = await fetch('/api/newsletter/subscribe',{
            method : "POST",
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify(payload),
        })
        if(response.ok) {
            const responseData = await response.json()
            console.log('response', responseData)
            setUserInfo(prevState => {
                // Initialize prevState to a default value if it's undefined
                const safePrevState = prevState ?? {
                    access: {
                        userName: '',
                        userEmail: '',
                        language: '',
                        newsletterFrequency: '',
                        format: '',
                        writtingStyle: '',
                    },
                    industry: {
                        userName: '',
                        userEmail: '',
                        language: '',
                        themes: [],
                        newsletterFrequency: '',
                        format: '',
                        writtingStyle: '',
                    },
                };
            
                const newState = { ...safePrevState };
            
                if (type === 'access' || type === 'industry') {
                    newState[type] = { ...safePrevState[type], ...responseData };
                }
            
                return newState;
            });
            console.log('change here 3')
        }
        else{
            setLoadingInfoError(true)
        }
        setSubscribeLoader(false)
    }
    const deleteSubscription =  async(email : string, type: string) =>{
        setUserDeleteLoader(true)
        const response = await fetch('/api/newsletter/delete',{
            method : "POST",
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: "include",
            body: JSON.stringify({
                userEmail : email,
                newsletterType: type
            }),
        })
        if(response.ok) {
            setUserDeletedSuccess(true)
        }
        setUserDeleteLoader(false)
    }

    useEffect(() => {
        if (token === null ) {
            return;
        }
    }, [token]);

    return {checkUserInfo, subscribe, deleteSubscription, userInfo, userDeletedSuccess,setUserDeletedSuccess, loadingInfo, subscribeLoader, userDeleteLoader, userSubscribeSuccess }

}